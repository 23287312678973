/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/
import dayjs from 'dayjs';
// @mui material components
import Card from '@mui/material/Card';
import styles from '../../Wizard.module.css';
// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HailIcon from '@mui/icons-material/Hail';
// Material Dashboard 2 React example components
import TimelineItem from 'tongoComponents/Timeline/TimelineItem';
import TimelineList from 'tongoComponents/Timeline/TimelineList';
import ActionMenu from 'tongoComponents/ActionMenu';
import { useState } from "react"
import RenderFields from 'tongoComponents/ActionMenu/renderFields';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Divider from '@mui/material/Divider';
// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

function Experience({ experience, index, arr, moveElementUp, moveElementDown, deleteElement, handleEditElement }) {
  const [isChecked, setIsChecked] = useState([false])
  const [descriptionHelp, setDescriptionHelp] = useState([false])
  const [employmentDatesHelp, setEmploymentDatesHelp] = useState([false])

  const newPosition = {

    title: "", startedMonthYear: "", endedMonthYear: "", currentJob: false, jobDescription: "", skills: [], employmentDurationMonths: null, employmentDurationMonthsAndYears: { months: "", years: "" }, noteworthy: ""

  }
  const module = 'experience';
  //handle object change

  function handleDatesHelp(index) {


    console.log("employmentDatesHelp: ", JSON.stringify(employmentDatesHelp))

    let temp = [...employmentDatesHelp]
    temp[index] = !employmentDatesHelp[index]
    setEmploymentDatesHelp(temp)
    console.log("just set employmentDatesHelp: ", JSON.stringify(employmentDatesHelp))
  }

  function handleDescriptionHelp(index) {


    //console.log("descriptionHelp: ", JSON.stringify(descriptionHelp))

    let temp = [...descriptionHelp]
    temp[index] = !descriptionHelp[index]
    setDescriptionHelp(temp)
    //console.log("just set descriptionHelp: ", JSON.stringify(descriptionHelp))
  }

  function checkHandler(index) {
    /*function checkHandler(index){
   
        let nextCounters = counters.map((isChecked, i) => {
          if (i === index) {
            
            return !isChecked;
          } else {
            // The rest haven't changed
            return c;
          }
        });*/
    //console.log("isChecked: ", JSON.stringify(isChecked))

    let temp = [...isChecked]
    temp[index] = !isChecked[index]
    setIsChecked(temp)
    //console.log("just set isChecked: ", JSON.stringify(isChecked))
  }
  const tasks = [
    {
      label: 'Edit',
      icon: 'edit_note',
      action: (module, id, updatedElement) => {
        editElement(module, id, updatedElement);
      },
      confirmation: false,
      confirmationMessage: '',
    },
    {
      label: 'Delete',
      icon: 'delete',
      action: (id) => {
        deleteElement(module, id);
      },
      confirmation: true,
      confirmationMessage: `Are you sure you want to delete this ${module}? This action cannot be undone.`,
    },
    {
      label: 'Move up',
      icon: 'move_up',
      action: (id) => {
        moveElementUp(module, id);
      },
      confirmation: false,
      confirmationMessage: '',
    },
    {
      label: 'Move down',
      icon: 'move_down',
      action: (id) => {
        moveElementDown(module, id);
      },
      confirmation: false,
      confirmationMessage: '',
    },
  ];

  const Addtask = [
    {
      label: 'Add new',
      icon: 'add',
      action: (module, id, newEducation) => {
        editElement(module, (id = 0), newEducation);
      },
      confirmation: false,
      confirmationMessage: '',
    },
  ];
  function addPosition() {
    const additionalPosition = [...experience["position"], newPosition]
    setIsChecked([...isChecked, false])
    setDescriptionHelp([...descriptionHelp, false])
    setEmploymentDatesHelp([...employmentDatesHelp, false])
    handleFieldChange("position", additionalPosition, false, false)

  }

  const handleFieldChange = (field, value, isObject, nestedIndex) => {
    //console.log("field, value, isObject, nestedIndex: ", field, " ", value, " ", isObject, " ", nestedIndex)
    let positionArr = [...experience["position"]]
    //console.log("positionArr", positionArr)
    if (nestedIndex || nestedIndex === 0) {
      //console.log("nestedIndex || nestedIndex === 0: ", nestedIndex || nestedIndex === 0)


      if (field.includes('.')) {

        console.log("field.includes('.'): ", field.includes('.'))
        let fieldArr = field.split('.')
        let superfield = fieldArr[0]
        let subfield = fieldArr[1]
        positionArr[nestedIndex][superfield][subfield] = value
        const updatedObject = { ...experience, ["position"]: [...positionArr] }
        handleEditElement(module, index, updatedObject);
      }
      else {
        console.log("not a nested field, no . field is: ", field)
        console.log("positionArr[nestedIndex][field]: ", positionArr[nestedIndex][field])
        console.log(" value is: ", value)
        positionArr[nestedIndex][field] = value
        console.log("positionArr[nestedIndex][field]: ", positionArr[nestedIndex][field])
        console.log("positionArr[nestedIndex]: ", positionArr[nestedIndex])
        console.log("positionArr: ", positionArr)
        const updatedObject = { ...experience, ["position"]: [...positionArr] }
        console.log(" updatedObject is: ", updatedObject)
        handleEditElement(module, index, updatedObject);
      }
    }
    else {
      console.log("nestedIndex || nestedIndex === 0: ", nestedIndex || nestedIndex === 0)
      if (isObject) {

        console.log("else if isObject:", isObject)
        const updatedObject = { ...experience, [field]: { ...experience[field], value } };
        console.log("isObjectupdatedObject:", updatedObject)


        console.log("experience:", experience)
        console.log("[field]: value ", [field], " ", value)
        console.log("module , index, value", module, " ", index, " ", value)

        handleEditElement(module, index, updatedObject);

      } else {
        console.log("else if else:", isObject)
        const updatedObject = { ...experience, [field]: value };

        console.log("updatedObject:", updatedObject)
        console.log("experience:", experience)
        console.log("[field]: value ", [field], " ", value)
        console.log("module , index, updatedObject", module, " ", index, " ", updatedObject)
        handleEditElement(module, index, updatedObject);
      }
      console.log("experience:", JSON.stringify(experience))
    }
  };



  return (
    <MDBox key={index}>
      <Grid container maxWidth={750} spacing={6} mb={4}>

        <Grid marginX={"auto"} item xs={10}>

          <MDBox mt={4} >
            <Typography variant="h5" sx={{ fontWeight: 500 }}> {arr.length > 1 ? String(index + 1) + "." : ""}</Typography>
            <h4 ></h4>
          </MDBox>
          <Stack px={3} mt={3} mb={5} direction="row" spacing={1}>
            <ApartmentIcon pt={4} fontSize={"large"} sx={{ transform: "translate(-35%,35%)" }}></ApartmentIcon>

            <MDInput
              type="text"
              label={"Company Name".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
              variant="filled"
              sx={{
                backgroundColor: "#F3F6F9"
              }}
              fullWidth
              name={"Company"}
              aria-label={"Company".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
              value={experience.company}
              multiline
              onChange={(e) => handleFieldChange("company", e.target.value, false, false)}
            />

          </Stack>
          <Stack px={3} ml={6} mt={3} mb={5} direction="row" spacing={1}>

            <MDInput
              type="text"
              label={"Location".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
              variant="filled"
              sx={{
                backgroundColor: "#F3F6F9"
              }}
              fullWidth
              name={"Location"}
              aria-label={"Location".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
              value={experience.location}
              multiline
              onChange={(e) => handleFieldChange("location", e.target.value, false, false)}
            />


          </Stack>

          {experience.position ? experience.position.map((pos, index, arr) => (
            <Stack ml={10} mt={0} >

              <Typography variant="h5" sx={{ fontWeight: 500 }}>{arr.length > 1 ? String(index + 1) + "." : ""}</Typography>

              {/*onChange={handleEditObjectChange} />*/}

              <Stack px={3} mt={3} mb={5} direction="row" spacing={1}>

                <HailIcon pt={4} fontSize={"large"} sx={{ transform: "translate(-35%,35%)" }}></HailIcon>
                <MDInput
                  type="text"
                  label={"Job Title".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                  variant="filled"
                  sx={{
                    backgroundColor: "#F3F6F9"
                  }}
                  InputProps={{ disableUnderline: true }}
                  fullWidth
                  name={"Job Title"}
                  aria-label={"Job Title".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                  value={pos.title}
                  multiline
                  onChange={(e) => handleFieldChange("title", e.target.value, false, index)}
                />



              </Stack>

              <Grid item xs={8}>

                <MDBox mt={2}>

                  {/*console.log("pos is ", pos)*/}

                    <MDBox ml={10}>

                      <Box sx={{ display: 'grid', width: "100%", gridAutoColumns: '3fr', gap: 0, }}>
                        <Stack spacing={2}>
                          <Box>
                            <Stack direction={"row"} spacing={2}>


                              <Box>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                  <DatePicker
                                    label='From'
                                    views={['month', 'year']}
                                    value={dayjs(pos["startedMonthYear"], "MM-YYYY")}
                                    onChange={(newValue) => handleFieldChange("startedMonthYear", dayjs(newValue).format("MM-YYYY"), false, index)}

                                    inputFormat="MM-YYYY"
                                    sx={{ minWidth: "170px" }}
                                  />

                                </LocalizationProvider>
                              </Box>

                              <Stack>

                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                  <DatePicker
                                    label='To'
                                    views={['month', 'year']}
                                    value={dayjs(pos["endedMonthYear"], "MM-YYYY")}
                                    onChange={(newValue) => handleFieldChange("endedMonthYear", dayjs(newValue).format("MM-YYYY"), false, index)}
                                    sx={{ minWidth: "170px" }}
                                    inputFormat="MM-YYYY"

                                    disabled={isChecked[index] ? true : false}
                                  />
                                </LocalizationProvider>

                                <Stack direction={"row"}>< Box mr={1}><input type="checkbox" id="currentJob" name="currentJob" checked={isChecked[index]} onChange={() => checkHandler(index)} /></Box> < Box sx={{ minWidth: "160px" }} mr={1}><label className={styles.smallLabels} for="currentJob" >  {"currently working this job".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())} </label></Box></Stack>

                              </Stack>

                            </Stack>
                          </Box>
                          <Box>

                          </Box>
                          <Box>
                            <Button onClick={() => handleDatesHelp(index)} color="info" variant="contained" size="large" mx={2} mt={3}>{employmentDatesHelp[index] ? "nevermind, I remember" : "help me figure out the dates"}</Button>
                          </Box>
                        </Stack>

                      </Box>




                      {employmentDatesHelp[index] ?
                        <Stack mt={4} sx={{ minWidth: "450px" }} >
                          <Stack spacing={2}>
                            <Box >Enter what you rememeber and we'll estimate the dates for you</Box>
                            <Box sx={{ paddingLeft: "25px" }}>{index === 0 && isChecked[index] ? <span className={styles.labels}>{"about how long have you worked here?".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}</span> :
                              <span className={styles.labels}>{"about how long did you work here?".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}</span>}
                              <Stack px={1} mt={2} direction="row" spacing={1}>

                                <MDInput
                                  type="number"
                                  label={"years".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                                  variant="filled"
                                  sx={{
                                    backgroundColor: "#F3F6F9",
                                    maxWidth: "100px"
                                  }}
                                  name={"years"}
                                  aria-label={"years".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                                  value={pos.employmentDurationMonthsAndYears.years}

                                  onChange={(e) => handleFieldChange("employmentDurationMonthsAndYears.years", e.target.value, false, index)}
                                />


                                <MDInput
                                  type="number"
                                  label={"months".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                                  variant="filled"

                                  name={"months"}
                                  aria-label={"months".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                                  value={pos.employmentDurationMonthsAndYears.months}
                                  sx={{
                                    backgroundColor: "#F3F6F9",
                                    maxWidth: "100px"
                                  }}
                                  onChange={(e) => handleFieldChange("employmentDurationMonthsAndYears.months", e.target.value, false, index)}
                                />

                              </Stack>
                            </Box>



                          </Stack>

                        </Stack> : ""}
                      <Stack mt={2} sx={{ minWidth: "450px" }} >
                        <Box>{descriptionHelp[index] ? "List any noteworthy responsibilities, achievements, etc." : "Enter the Job Description"}</Box>

                        <MDBox mt={1} ml={2}>
                          {descriptionHelp[index] ?
                            <MDButton onClick={() => handleDescriptionHelp(index)} color="info" variant="contained" size="large" mx={2} mt={3}>write my own job description</MDButton>
                            : <MDButton onClick={() => handleDescriptionHelp(index)} color="info" variant="contained" size="large" mx={2} mt={3}>help me write a job description</MDButton>}
                        </MDBox>
                        <MDBox mt={1} ml={2} mb={5} >
                          <MDInput
                            type="text"
                            label={String(descriptionHelp[index] ? "Noteworthy Achievements Or Responsibilities" : "Job Description").replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                            variant="filled"
                            fullWidth
                            multiline
                            rows={5}
                            name={descriptionHelp[index] ? "Noteworthy Achievements Or Responsibilities" : "Job Description"}
                            aria-label={"Noteworthy Achievements Or Responsibilities".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                            value={descriptionHelp[index] ? pos.noteworthy : pos.jobDescription}
                            onChange={(e) => handleFieldChange(descriptionHelp[index] ? "noteworthy" : "jobDescription", e.target.value, false, index)}
                            sx={{
                              backgroundColor: "#F3F6F9"
                            }}
                          />
                        </MDBox>
                      </Stack>
                    </MDBox>
                </MDBox>

              </Grid>
              <Divider variant="inset" component="div" sx={{ width: '100%', color: "theme.palette.text.secondary" }} ></Divider>
            </Stack>


          )) : ""
          }

          <MDBox ml={15} mt={1}><HailIcon pt={4} fontSize={"large"} sx={{ transform: "translate(-35%,35%)" }}></HailIcon> <IconButton onClick={addPosition} sx={{ borderRadius: "30px" }} color="info" variant="outlined" size="large" mx={2} mt={3}><AddCircleOutlineIcon pt={4} fontSize={"large"} ></AddCircleOutlineIcon></IconButton>  </MDBox>


        </Grid >
      </Grid >

    </MDBox >
  );
}


export default Experience;
/*
Experience.propTypes = {
  experience: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      company: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      startDate: PropTypes,
      endDate: PropTypes,
      duration: PropTypes.string.isRequired,
      noteworthy: PropTypes.string,
    })
  ).isRequired,
  moveElementUp: PropTypes.func,
  moveElementDown: PropTypes.func,
  deleteElement: PropTypes.func,
  editElement: PropTypes.func,
};

const emptyExperience = {
  title: '',
  company: '',
  location: '',
  startDate: '',
  endDate: '',
  duration: '',
  dutiesAccomplishment: {
    summary: 'Summarize this position',
    dutiesDescription: ['List duties and accomplishments'],
  },
};
*/