import {
    AlignmentType,
    Document,
    HeadingLevel,
    Paragraph,
    TabStopPosition,
    TabStopType,
    TextRun
  } from "docx";
  import dayjs from 'dayjs';

  const PHONE_NUMBER = "07534563401";
  const PROFILE_URL = "https://www.linkedin.com/in/dolan1";
  const EMAIL = "docx@docx.com";


    // tslint:disable-next-line: typedef
    
export class CLWordExport {
    constructor() {
        this.a=""
    }

    createContactInfo(phoneNumber, profileUrl, email) {
        let contactText=String(`${phoneNumber?`Mobile: ${phoneNumber} |`:""}`+`${profileUrl?`LinkedIn: ${profileUrl} |`:""}`+`${email?`Email: ${email} `:""}`)
        return new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun(
                contactText
            ),
            new TextRun({
              text: String("Address: 58 Elm Avenue, Kent ME4 6ER, UK"),
              break: 1
            })
          ]
        })
      }
    
      createHeading(text) {
        return new Paragraph({
          text: text,
          heading: HeadingLevel.HEADING_1,
          thematicBreak: true
        })
      }
    
      createSubHeading(text) {
        return new Paragraph({
          text: text,
          heading: HeadingLevel.HEADING_2
        })
      }
    
      createInstitutionHeader(institutionName,location) {
        console.log("institution name is: ",institutionName)
        let institutionLocation = ""
        location?institutionLocation=location:institutionLocation=""
        return new Paragraph({
          tabStops: [
            {
              type: TabStopType.RIGHT,
              position: TabStopPosition.MAX
            }
          ],
          children: [
            new TextRun({
              text: institutionName,
              bold: true
            }),
            new TextRun({
              text: `\t${institutionLocation}`,
              bold: true
            })
          ]
        })
      }

      createRoleText(roleText,roleDates) {
        return new Paragraph({
            tabStops: [
                {
                  type: TabStopType.RIGHT,
                  position: TabStopPosition.MAX
                }
              ],
            children: [
            new TextRun({
              text: roleText,
              italics: true
            }),
            new TextRun({
              text: `\t${roleDates}`,
              bold: true
            })
          ]
        })
      }
    
      createBullet(text) {
        return new Paragraph({
          text: text,
          bullet: {
            level: 0
          }
        })
      }
    
      // tslint:disable-next-line:no-any
      createSkillList(skills) {
        return new Paragraph({
          children: [new TextRun(skills.map(skill => skill.name).join(", ") + ".")]
        })
      }
    
      // tslint:disable-next-line:no-any
      createAchievementsList(achievements) {
        return achievements.map(
          achievement =>
            new Paragraph({
              text: achievement.name,
              bullet: {
                level: 0
              }
            })
        )
      }
    
      createInterests(interests) {
        return new Paragraph({
          children: [new TextRun(interests)]
        })
      }
    
      splitParagraphIntoBullets(text) {
        return text.split("\n\n")
      }
    
      // tslint:disable-next-line:no-any
      createPositionDateText(startedMonthYear, endedMonthYear, currentJob) {
        const startDateText = 
        dayjs(startedMonthYear,"MM-YYYY").format("MMM")+ ". " + dayjs(startedMonthYear,"MM-YYYY").format("YYYY")
        const endDateText = currentJob? "Present": `${dayjs(endedMonthYear,"MM-YYYY").format("MMM")}. ${dayjs(endedMonthYear,"MM-YYYY").format("YYYY")}`
    
        return `${startDateText} - ${endDateText}`
      }
    
      getMonthFromInt(value) {
        switch (value) {
          case 1:
            return "Jan"
          case 2:
            return "Feb"
          case 3:
            return "Mar"
          case 4:
            return "Apr"
          case 5:
            return "May"
          case 6:
            return "Jun"
          case 7:
            return "Jul"
          case 8:
            return "Aug"
          case 9:
            return "Sept"
          case 10:
            return "Oct"
          case 11:
            return "Nov"
          case 12:
            return "Dec"
          default:
            return "N/A"
        }
      }
      
     create(coverletter) {

      const paragraphArr=[]
      for(let i=0;i< coverletter?.length;i++){
        paragraphArr.push(new Paragraph(coverletter[i]))
      }
    

      console.log("paragraphArr?", paragraphArr)
      console.log(`coverletter? ${coverletter}`)
        //[experiences, education, skills, achievements]
        //console.log("skills WORD EXPORT", skills)

      const document = new Document({
        
        sections: [
          {
            children:paragraphArr
          
              
            
          }
        ]
      })
  
      return document
    }
    
  
}
  