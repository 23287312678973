/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// @mui material components
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import styles from '../../Wizard.module.css';
// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import SchoolIcon from '@mui/icons-material/School';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import MDTypography from 'components/MDTypography';
import Grid from '@mui/material/Grid';
// Material Dashboard 2 React example components
import TimelineItem from 'tongoComponents/Timeline/TimelineItem';
import TimelineList from 'tongoComponents/Timeline/TimelineList';
import ActionMenu from 'tongoComponents/ActionMenu';

import { useState, useEffect } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

function Education({ education, index, arr, deleteElement, handleEditElement }) {
  const module = 'education';


  const newDegree = { degreeName: "", fieldOfStudy: "", graduationMonth: null, graduationMonthYear: null, inProgress: false, bulletPoints: [""], misc: "", overallGpa: "" }
  function institutionHandler(institution) {
    //console.log("institution", institution)
    setInstitution(institution)
  }
  function inProgressHandler(inProgress) {
    //console.log("inProgress", inProgress)

    return !inProgress
  }
  function addDegree() {
    const additionalDegree = [...education["degree"], newDegree]
    //console.log("newDegree:", additionalDegree)
    handleFieldChange("degree", additionalDegree, false, false)

  }


  const handleFieldChange = (field, value, isObject, nestedIndex) => {
    //console.log("*******************************************field,", field, "value:", value, "isObject:", isObject, "nestedIndex", nestedIndex)
    if (nestedIndex || nestedIndex === 0) {
      //console.log("*******************************************nested index is: ", nestedIndex)
      let degreeArr = [...education["degree"]]
      //console.log("field is: ", field)
      if (field.includes('.')) {
        //console.log("nested field, includes . field is: ", field)

        let fieldArr = field.split('.')
        let superfield = fieldArr[0]
        let subfield = fieldArr[1]
        degreeArr[nestedIndex][superfield][subfield] = value
        const updatedObject = { ...education, ["degree"]: [...degreeArr] }
        handleEditElement(module, index, updatedObject);
      }
      else {
        //console.log("not a nested field, no . field is: ", field)
        degreeArr[nestedIndex][field] = value
        const updatedObject = { ...education, ["degree"]: [...degreeArr] }
        handleEditElement(module, index, updatedObject);
      }
    }
    else {
      if (isObject) {

        //console.log("else if isObject:", isObject)
        const updatedObject = { ...education, [field]: { ...education[field], value } };
        //console.log("isObjectupdatedObject:", updatedObject)


        //console.log("education:", education)
        //console.log("[field]: value ", [field], " ", value)
        //console.log("module , index, value", module, " ", index, " ", value)

        handleEditElement(module, index, updatedObject);

      } else {
        //console.log("else if else:", isObject)
        const updatedObject = { ...education, [field]: value };

        //console.log("updatedObject:", updatedObject)
        //console.log("education:", education)
        //console.log("[field]: value ", [field], " ", value)
        //console.log("module , index, updatedObject", module, " ", index, " ", updatedObject)
        handleEditElement(module, index, updatedObject);
      }
      //console.log("education:", JSON.stringify(education))
    }
  };



  const Addtask = [
    {
      label: 'Add new',
      icon: 'add',
      action: (module, id, newEducation) => {
        editElement(module, (id = 0), newEducation);
      },
      confirmation: false,
      confirmationMessage: '',
    },
  ];


  return (





    <MDBox key={index}>
      <Grid container maxWidth={750} spacing={6} mb={4}>
        {/*console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaindex: ', index)*/}
        {/*console.log('index: ', education)*/}
        <Grid marginX={"auto"} item xs={10}>

          {/*<RenderFields object={education} onChange={handleEditObjectChange} />*/}
          <MDBox mt={4} >
            <Typography variant="h5" sx={{ fontWeight: 500 }}> {arr.length > 1 ? String(index + 1) + "." : ""}</Typography>
          </MDBox>



          <Stack px={3} mt={3} mb={5} direction="row" spacing={1} sx={{ minWidth: "500px" }}>
            <AccountBalanceIcon pt={4} fontSize={"large"} sx={{ transform: "translate(-35%,35%)" }}></AccountBalanceIcon>



            <MDInput
              type="text"
              label={"institution".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
              variant="filled"
              sx={{
                backgroundColor: "#F3F6F9"
              }}
              name={"institution"}
              aria-label={"institution".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
              value={education.institution}
              multiline
              fullWidth
              onChange={(e) => handleFieldChange("institution", e.target.value, false, false)}
            />
          </Stack>


          {education.degree.map((deg, index, arr) => (
            <Stack ml={10} mt={0} >

              <Typography variant="h5" sx={{ fontWeight: 500 }}>{arr.length > 1 ? String(index + 1) + "." : ""}</Typography>

              <Stack px={3} mt={0} mb={5} spacing={1} sx={{ minWidth: "400px" }}>
                <Stack spacing={1} direction={"row"}>
                  <SchoolIcon pt={4} fontSize={"large"} sx={{ transform: "translate(-35%,35%)" }}></SchoolIcon>
                  <MDInput
                    type="text"
                    label={"degree/Diploma".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                    variant="filled"
                    fullWidth
                    name={"degree"}
                    aria-label={"degree".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                    value={deg.degreeName}
                    multiline
                    onChange={(e) => handleFieldChange("degreeName", e.target.value, false, index)}
                  />
                </Stack>
                <Stack spacing={1} direction={"row"}> <MDBox sx={{ paddingTop: "10px", paddingLeft: "50px" }} > <input type="checkbox" id={`inProgress${index}`} name={`inProgress${index}`} checked={deg.inProgress} onChange={() => inProgressHandler(deg.inProgress)} /></MDBox > <MDBox pt={1} sx={{ paddingLeft: "10px" }}><label className={styles.smallLabels} for="inProgress" >  {"in progress".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())} </label>
                </MDBox>
                </Stack>


                <Stack pl={5}  pt={1} direction={"row"} spacing={2} >
                  <MDBox mt={2}>
                    <MDInput
                      type="text"
                      label={"graduation year".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                      variant="filled"

                      name={"year"}
                      aria-label={"year".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                      value={deg.graduationYear}

                      onChange={(e) => handleFieldChange("graduationYear", e.target.value, false, index)}
                    />

                  </MDBox>
                  <MDBox ml={2} >
                    <MDInput
                      type="text"
                      label={"gpa".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()) + " (optional)"}
                      variant="filled"
                      fullWidth
                      name={"gpa"}
                      aria-label={"gpa".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                      value={deg.overallGpa}
                      multiline
                      onChange={(e) => handleFieldChange("overallGpa", e.target.value, false, index)}
                    />

                  </MDBox>

                </Stack>

                
                <MDBox pl={5} pt={3} ml={2} >
                  <MDInput
                    type="text"
                    label={"Additional Information (honors, minors, etc..)".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                    variant="outlined"
                    fullWidth
                    name={"Additional Information"}
                    aria-label={"Additional Information".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                    value={deg.misc}
                    multiline
                    rows={5}
                    onChange={(e) => handleFieldChange("misc", e.target.value, false, index)}
                  />
                </MDBox>
    

              </Stack>
            </Stack>

          ))
          }
            <MDBox  ml={15} mt={1}><SchoolIcon pt={4} fontSize={"large"} sx={{ transform: "translate(-35%,35%)" }}></SchoolIcon> <IconButton  onClick={addDegree} sx={{ borderRadius: "30px"}} color="info" variant="outlined" size="large" mx={2} mt={3}><AddCircleOutlineIcon pt={4}  fontSize={"large"} ></AddCircleOutlineIcon></IconButton>  </MDBox>
          
            
        </Grid >
      </Grid >
    </MDBox >


  );
}

export default Education;

Education.defaultProps = {
  graduationMonth: '',
  gpa: '',
};

Education.propTypes = {
  education: PropTypes.arrayOf(
    PropTypes.shape({
      institution: PropTypes.string.isRequired,
      degree: PropTypes.arrayOf(PropTypes.string).isRequired,
      graduationYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      graduationMonth: PropTypes.string,
      majors: PropTypes.arrayOf(PropTypes.string),
      gpa: PropTypes.string,
    })
  ).isRequired,
  moveElementUp: PropTypes.func,
  moveElementDown: PropTypes.func,
  deleteElement: PropTypes.func,
  editElement: PropTypes.func,
};

const emptyEducation = {
  institution: '',
  degree: ['My degree'],
  graduationYear: new Date().getFullYear(),
  graduationMonth: 'June',
  majors: ['My 1st major'],
  gpa: '',
};





