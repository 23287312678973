/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/
// react-router-dom components
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {verifyAccount} from '../../../profile';
import {useState, useEffect} from 'react';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDSnackbar from 'components/MDSnackbar';

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';
// Images
import bgImage from 'assets/images/bg-reset-cover.jpeg';

function VerifyAccount() {
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const closeError = () => setError(false);

  //retrieve verification key
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const verificationToken = queryParams.get('verificationToken');
  !verificationToken ? (window.location.href = '/sign-in') : null;

  useEffect(() => {
    async function verify() {
      verifyAccount({verificationToken: verificationToken})
        .then(() => {
          navigate('/dashboard');
        })
        .catch((error) => {
          setError(true);
          console.error(error);
        });
    }
    verify();
  }, []);
  // Submit the form data to the backend server

  const renderError = (
    <MDSnackbar
      color="error"
      icon="error"
      title="Error verifyinig your account..."
      content="Cannot find an account with this verification key "
      dateTime=""
      open={error}
      onClose={closeError}
      close={closeError}
      bgWhite
    />
  );

  return (
    <BasicLayout coverHeight="50vh" image={bgImage}>
      {error ? renderError : null}
      <Card>
        <MDBox variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="success" mx={2} mt={-3} py={2} mb={1} textAlign="center">
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Verifying Account
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox mb={4}></MDBox>
          <MDBox mt={6} mb={1}>
            <MDTypography variant="block" color="text">
              Verifying account against our server...
            </MDTypography>
            <br />
            <MDTypography variant="body2" color="text">
              Having trouble? Please try resetting your password
              <br />
              <MDTypography component={Link} to="/reset-password" variant="button" color="info" fontWeight="medium" textGradient>
                Reset password
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default VerifyAccount;
