/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDBadge from 'components/MDBadge';
import ActionMenu from 'tongoComponents/ActionMenu';

// Images
// import team2 from 'assets/images/small-logos/indeed.svg';
import team1 from 'assets/images/team-1.jpg';
import team2 from 'assets/images/team-2.jpg';
import team3 from 'assets/images/team-3.jpg';
import team4 from 'assets/images/team-4.jpg';

import {getSearch, deleteSearch, archiveSearch, refreshSearch} from '../../../searches';

function Data(callback) {
  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState(null);
  const [searchesCache, setSearchesCache] = useState([]);
  const [fetched, setFetched] = useState(false);
  const navigate = useNavigate();

  const tasks = [
    {
      label: 'Refresh search',
      icon: 'refresh',
      action: refreshSearch,
      confirmation: true,
      confirmationMessage: `Refreshing this search results will perform a new search on the job site.
            \n Previous results will be cleared.
            \n This will be queued on the server and may require a manual page refresh.`,
    },
    {
      label: 'Modify',
      icon: 'edit_note',
      action: (id) => {
        navigate(`/search/${id}/edit`);
      },
      confirmation: false,
      confirmationMessage: '',
    },
    {
      label: 'Archive',
      icon: 'archive',
      action: archiveSearch,
      confirmation: true,
      confirmationMessage: 'Are you sure you want to archive this search?',
    },
    {
      label: 'Delete',
      icon: 'delete',
      action: deleteSearch,
      confirmation: true,
      confirmationMessage: 'Are you sure you want to delete this search? This action cannot be undone.',
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!fetched) {
          const data = await getSearch(null, '{"where":{"archived":{"neq":true}, "deleted":{"neq":true}}}');
          setSearchesCache(data);
          setFetched(true);
        }
        const columns = [
          {
            Header: 'search name',
            accessor: 'name',
            width: '30%',
            align: 'left',
          },
          {Header: 'location', accessor: 'location', align: 'left'},
          {Header: 'matches', accessor: 'count', align: 'center'},
          {Header: 'status', accessor: 'status', align: 'center'},
          {Header: 'action', accessor: 'action', align: 'center'},
        ];
        const rows = searchesCache.map((search) => ({
          id: search.id,
          name: <Search image={renderIcon()} name={search?.name} engine={search?.engine} />,
          location: <Location city={search?.jobLocation?.city} state={search?.jobLocation?.state} distance={search?.jobLocation?.searchDistance} />,
          count: (
            <MDBox display="flex" alignItems="center" lineHeight={1}>
              <MDBox ml={2} lineHeight={1}>
                <MDBox ml={-1}>{search?.count || 0}</MDBox>
                <MDTypography display="block" variant="caption">
                  As of {renderDate(search?.lastSearch)}
                </MDTypography>
              </MDBox>
            </MDBox>
          ),
          status: <MDBox ml={-1}>{renderStatus(search?.status, search?.lastSearch, search?.count)}</MDBox>,
          innerRows: [search?.results],
          action: <ActionMenu id={search.id} onActionCompleted={() => setFetched(false)} tasks={tasks} />,
        }));
        setTable({columns, rows});
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [fetched]);
  useEffect(() => {
    if (callback) {
      callback(loading, table);
    }
  }, [callback, loading, table]);
  const Search = ({image, name, engine}) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/*<MDAvatar src={image} name={name} size="sm" />*/}
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name ?? ''}
        </MDTypography>
        <MDTypography display="block" variant="caption">
          {engine ?? ''}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Location = ({city, state, distance}) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {city ?? ''}
        {state ? `, ${state}` : ''}
      </MDTypography>
      {distance ? <MDTypography variant="caption">Search radius {distance} mi.</MDTypography> : null}
    </MDBox>
  );

  const renderIcon = () => {
    const r = Math.floor(Math.random() * 5);
    switch (r) {
      case 0:
      case 1:
        return team1;
      case 2:
        return team2;
      case 3:
        return team3;
      case 4:
        return team4;
      case 5:
        return team5;
    }
  };

  const renderDate = (date) => {
    const now = new Date();
    const dateObj = new Date(date);
    const timeDiff = now - dateObj;
    const minutesDiff = Math.floor(timeDiff / (1000 * 60));
    const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    if (!date) return 'No search performed yet';
    if (minutesDiff < 15) {
      return 'a few minutes ago';
    } else if (hoursDiff <= 1.5) {
      return 'an hour or so ago';
    } else if (hoursDiff <= 24) {
      return 'today';
    } else if (hoursDiff <= 48) {
      return 'yesterday';
    } else if (daysDiff <= 3) {
      return 'a couple of days ago';
    } else if (daysDiff <= 7) {
      return `last ${dateObj.toLocaleDateString('en-US', {weekday: 'long'})}`;
    } else if (now.getFullYear() === dateObj.getFullYear()) {
      return dateObj.toLocaleDateString('en-US', {month: 'short', day: 'numeric'});
    } else {
      return dateObj.toLocaleDateString('en-US');
    }
  };

  const renderStatus = (status, lastSearch, count) => {
    switch (status) {
      default:
      case 'pending':
        return <MDBadge badgeContent="pending" color="info" variant="gradient" size="sm" />;
      case 'inProgress':
        return <MDBadge badgeContent="in progress" color="info" variant="gradient" size="sm" />;
      case 'noResults':
        return <MDBadge badgeContent="pending" color="info" variant="gradient" size="sm" />;
      case 'failed':
        return <MDBadge badgeContent="failed" color="error" variant="gradient" size="sm" />;
      case 'ok':
        return <MDBadge badgeContent="jobs found" color="success" variant="gradient" size="sm" />;
    }
  };


  return {loading, table};
}
export default Data;