/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

import localforage from 'localforage';

// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDLoading from 'components/MDLoading';
import MDSnackbar from 'components/MDSnackbar';

// Material Dashboard 2 React examples
import DashboardLayout from 'tongoComponents/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'tongoComponents/Navbars/DashboardNavbar';
import Footer from 'tongoComponents/Footer';

// react-router-dom components
import {getProfile, updateProfile} from '../../profile';
import {useState, useEffect} from 'react';

// Resume page components

import Experience from 'layouts/resume/components/Experience';
import Education from 'layouts/resume/components/Education';
import CustomSection from 'layouts/resume/components/CustomSection';
import Biography from 'layouts/resume/components/Biography';
import ResumeTools from 'layouts/resume/components/ResumeTools';

function Resume() {
  const [resume, setResume] = useState({});
  const [fetched, setFetched] = useState(false);
  const [loading, setLoading] = useState(false);

  const [errorSB, setErrorSB] = useState([]);
  const closeError = () => setErrorSB([]);

  const sectionIndexMap = {
    basicInfo: 0,
    education:1,
    experience: 2,
    custom:3,

  };

  const handleMoveElementUp = async (sectionName, index) => {
    const sectionIndex = sectionIndexMap[sectionName];
    if (index > 0) {
      const updatedSection = [...resume.resumeParsed[sectionIndex][sectionName]];
      const [movedElement] = updatedSection.splice(index, 1);
      updatedSection.splice(index - 1, 0, movedElement);

      const updatedResume = {...resume};

      updatedResume.resumeParsed[sectionIndex][sectionName] = updatedSection;
      setResume(updatedResume);
      console.log('*****************updatedResume***************', updatedResume);
      await update(updatedResume);
    }
  };
  const handleDeleteElement = async (sectionName, index) => {
    const sectionIndex = sectionIndexMap[sectionName];

    const updatedResume = {...resume};

    // Get the section to modify
    const section = updatedResume.resumeParsed[sectionIndex][sectionName];

    // Check if the index is valid
    if (index >= 0 && index < section.length) {
      // Remove the element at the specified index
      section.splice(index, 1);

      // Update the specific section in the copy
      updatedResume.resumeParsed[sectionIndex][sectionName] = section;

      // Update the résumé state with the copy
      setResume(updatedResume);
      console.log('*****************updatedResume***************', updatedResume);
      await update(updatedResume);
    }
  };

  const handleMoveElementDown = async (sectionName, index) => {
    const sectionIndex = sectionIndexMap[sectionName];

    const updatedResume = {...resume};

    // Get the section to modify
    const section = updatedResume.resumeParsed[sectionIndex][sectionName];

    // Check if the index is valid and not at the last position
    if (index >= 0 && index < section.length - 1) {
      // Swap the element with the next one to move it down
      [section[index], section[index + 1]] = [section[index + 1], section[index]];

      // Update the specific section in the copy
      updatedResume.resumeParsed[sectionIndex][sectionName] = section;

      // Update the résumé state with the copy
      setResume(updatedResume);

      await update(updatedResume);
    }
  };

  const handleEditElement = async (sectionName, index, updatedElement) => {
    console.log('handleEditElement', sectionName, index, updatedElement);
    console.log('resume', resume);
    const sectionIndex = sectionIndexMap[sectionName];

    const updatedResume = {...resume};

    // Get the section to modify
    const section = updatedResume.resumeParsed[sectionIndex][sectionName];

    if (index <0) {
      // If index is less than 0, it means we want to add a new element at the top
      section.unshift(updatedElement);

    } else if (index >= 0 && index < section.length) {
      // Update the element at the specified index
      section[index] = updatedElement;
    }

    // Update the specific section in the copy
    updatedResume.resumeParsed[sectionIndex][sectionName] = section;

    // Update the résumé state with the copy
    setResume(updatedResume);

    await update(updatedResume);
  };

  function update(updatedResume) {
    updateProfile({resumeParsed: updatedResume.resumeParsed})
      .then((result) => {})
      .catch((err) => {
        setErrorSB([{title: 'Error saving your changes!', message: `Please review your info and try again! (${error})`}]);
      })
      .finally(() => {});
  }

  useEffect(() => {
    if (!fetched) {
      setLoading('Loading...');
      getProfile({
        fields: {
          resumeParsed: true,
          avatar: true,
          salutation: true,
          firstName: true,
          lastName: true,
          middleName: true,
          title: true,
          bio: true,
          telephone: true,
          email: true,
          city: true,
          state: true,
          country: true,
          social: true,
          resumeKeywords: true,
          resumeUpdated: true,
          emailVerified: true,
        },
      })
        .then((r) => {
          if (r?.resumeParsed) delete r.resumeParsed[0].basicInfo; //remove imported basicInfo that was already copied in user object and won't be used to drive the site
          const basicInfoCopy = {
            salutation: r.salutation,
            avatar: r.avatar,
            firstName: r.firstName,
            lastName: r.lastName,
            middleName: r.middleName,
            title: r.title,
            bio: r.bio,
            telephone: r.telephone,
            email: r.email,
            city: r.city,
            state: r.state,
            country: r.country,
            social: r.social,
            resumeKeywords: r.resumeKeywords,
            resumeUpdated: r.resumeUpdated,
          };
          // Assign the new basicInfo object to the appropriate location
          r.basicInfo = basicInfoCopy;

          //update local storage user info
          localforage.getItem('user').then((user) => {
            localforage.setItem('user', {...user, ...r});
          });

          setResume(r);
          setFetched(true);
        })
        .catch((error) => {
          if ([401, 404].includes(error.code)) {
            // Perform the redirect to the login screen
            window.location.href = '/sign-in';
          } else {
            setErrorSB([{title: 'Error retrieving your account!', message: `Please review your info and try again! (${error})`}]);
            // Handle other errors
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [fetched]);

  const renderError = (title, message) => (
    <MDSnackbar
      color="error"
      icon="error"
      title={title}
      content={message}
      dateTime=""
      open={Boolean(errorSB)}
      onClose={closeError}
      close={closeError}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDLoading text={loading === false ? '' : loading} open={Boolean(loading)} />
      {errorSB.length ? renderError(errorSB[0].title, errorSB[0].message) : null} <MDBox mb={2} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {/* <Card> */}
            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
              <MDTypography variant="h6" color="white">
                My Résumé
              </MDTypography>
            </MDBox>
            <MDBox mb={3} mt={3}>
              <Grid container>
                <Grid item pt={-4} xs={12}>
                  <ResumeTools
                    mainResume={JSON.stringify( resume)? {... resume}:{}}
                    keywords={resume?.basicInfo ? resume.basicInfo.resumeKeywords : []}
                    resumeUpdated={resume?.basicInfo ? resume.basicInfo.resumeUpdated : ''}
                    reload={setFetched}
                  />
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mb={3}>
              <Grid container>
                <Grid item xs={12}>
                  <Biography basicInfo={resume?.basicInfo ? resume.basicInfo : {}} />
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mb={3}>
              <Grid container>
                <Grid item xs={12}>

                {resume?.resumeParsed? console.log("resume.resumeParsed[2]",resume?.resumeParsed[2]):console.log("resume.resumeParsed[2]","no resume.resumeParsed[2]" )}
                  <Experience
                 
                    experience={resume?.resumeParsed && resume.resumeParsed[2] ? resume.resumeParsed[2].experience : []}
                    moveElementUp={handleMoveElementUp}
                    moveElementDown={handleMoveElementDown}
                    deleteElement={handleDeleteElement}
                    editElement={handleEditElement}
                  />
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mb={3}>
              <Grid container>
                <Grid item xs={12}>

                {resume?.resumeParsed? console.log("resume.resumeParsed[1]",resume?.resumeParsed[1]):console.log("resume.resumeParsed[1]","no resume.resumeParsed[1]" )}

          
                  <Education
                    education={resume?.resumeParsed && resume.resumeParsed[1] ? resume.resumeParsed[1].education : []}
                    moveElementUp={handleMoveElementUp}
                    moveElementDown={handleMoveElementDown}
                    deleteElement={handleDeleteElement}
                    editElement={handleEditElement}
                  />
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mb={3}>
              <Grid container>
                <Grid item xs={12}>
                               {resume?.resumeParsed? console.log("resume.resumeParsed[3]",resume?.resumeParsed[3]):console.log("resume.resumeParsed[3]","no resume.resumeParsed[1]" )}

          
                  <CustomSection
                    customSection={resume?.resumeParsed && resume.resumeParsed[3] ? resume.resumeParsed[3].custom : []}
                    moveElementUp={handleMoveElementUp}
                    moveElementDown={handleMoveElementDown}
                    deleteElement={handleDeleteElement}
                    editElement={handleEditElement}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
          {/* </Card> */}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Resume;
