/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/
// react-router-dom components
import {useParams, useNavigate} from 'react-router-dom';
import {getJob} from '../../../jobs';
import {useState, useRef,useEffect} from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// @mui material components
import Grid from '@mui/material/Grid';
// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';
// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDLoading from 'components/MDLoading';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

// Material Dashboard 2 React examples
import DashboardLayout from 'tongoComponents/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'tongoComponents/Navbars/DashboardNavbar';
import Footer from 'tongoComponents/Footer';

//  page components
import JobInfo from 'layouts/shortList/view/components/JobInfo';
import CoverLetter from 'layouts/shortList/view/components/CoverLetter';
import JobHistory from 'layouts/shortList/view/components/JobHistory';

import { createTheme } from '@mui/material/styles';
import { blue } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
      darker: blue[900],
    },
    textField:{
      light: "#FFFFFF",
    }
  },
});


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function CopyExample() {

  const [copySuccess, setCopySuccess] = useState('');
  const [textAreaText, setTextAreaText] = useState('');
  const textAreaRef = useRef("");

  function copyToClipboard() {
    navigator.clipboard.writeText(textAreaText).then(

      function(){
          alert(`yeah!: ${textAreaText} `); // success 
      })
    .catch(
       function() {
          alert("err"); // error
    });
   
  };

  return (
    <div>
      {
       /* Logical shortcut for only displaying the 
          button if the copy command exists */
        <div>
          <Button onClick={copyToClipboard}>Copy</Button> 
          {copySuccess}
        </div>
      }
      <form>
      <TextField
          id="outlined-multiline-static"
          label="Answer Text"
          multiline
          rows={4}
          value={textAreaText}
          onChange={e => setTextAreaText(e.target.value)} 
          defaultValue=""
          ref={textAreaRef}
          placeholder="copy generated text from here"
         
         
        />

      </form>
    </div>
  );
}

function ViewApplication({jobId,resume}) {
  {/*const queryParams = useParams();*/}
  const [job, setJob] = useState({});
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const style = {
    py: 0,
    width: '100%',
    minWidth: 500,
    borderRadius: 2,
    border: 'none',//'1px solid',
    borderColor: 'divider',
    backgroundColor: 'background.paper',
    listStyleType: 'none',
    pt:3,
    pb:0
  };

  const [loading, setLoading] = useState(true);
  const [fetched, setFetched] = useState(false);

  const navigate = useNavigate();

  {/*useEffect(() => {
      
        {jobId && !fetched ? setJobId(jobId) (setFetched(true)) (setJob(job)):           (window.location.href = '/sign-in')(console.error(error)) }
  }, [jobId, fetched]);*/}
  useEffect(() => {
    if (jobId && !fetched) {
      getJob(jobId)
        .then((job) => {

          setJob(job);
          setFetched(true);
        })
        .catch((error) => {
          // if (error.code === 401) {
          // Perform the redirect to the login screen
          window.location.href = '/sign-in';
          // } else {
          console.error(error);
          // Handle other errors
          // }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    else{
      getJob(jobId)
      .then((job) => {
        setJob(job);
      })
      .catch((error) => {
        // if (error.code === 401) {
        // Perform the redirect to the login screen
        window.location.href = '/sign-in';
        // } else {
        console.error(error);
        // Handle other errors
        // }
      })
      .finally(() => {
        setLoading(false);
      });

    }
  }, [fetched,jobId]);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (

    <MDBox  py={3} px={0} sx={{ MarginY:"auto", paddingX:"20px", minHeight:"700px", maxWidth:"1000px", justifyContent:"center" }}>
        <MDBox mb={3}>
          <Grid container spacing={1} sx={{ MarginY:"auto", paddingX:"20px", maxWidth:"1000px", justifyContent:"center" }}>
            <Grid item xs={12} md={12}>
              <JobInfo
              
                jobId={job?.id  ?? ''}
                jobTitle={job?.jobTitle ?? ''}
                description={job?.description ?? ''}
                jobPostingUrl={job?.jobPostingUrl ?? ''}
                useMainResume={job?.useMainResume ?? false}
                customResume={job?.customResume ?? ''}
                companyName={job?.companyName ?? ''}
                city={job?.jobLocation?.city ?? ''}
                state={job?.jobLocation?.state ?? ''}
                coverLetterStatus={job?.coverLetterStatus ?? ''}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>







    <Box sx={{ MarginY:"auto", paddingX:"50px", maxWidth:"1000px", justifyContent:"center" }}>
      <Box mx={2} sx={{  width: '100%' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Create Cover Letter" {...a11yProps(0)} />
          <Tab label="Fill out Job Application" {...a11yProps(1)} />
          {/*<Tab label="Item Three" {...a11yProps(2)} />*/}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0} sx={{  width: '80%' }}>
       
      <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
            
              <CoverLetter
                jobId={job?.id  ?? ''}
                coverLetterStatus={job?.coverLetterStatus ?? ''}
                coverLetter={job?.coverLetter ?? ''}
                requestedAccomodation={job?.requestedAccomodation ?? ''}
                requestingAccommodation={job?.requestedAccomodation ?? false}
                resume={JSON.parse(JSON.stringify(resume))}
                anchorEl={anchorEl}
              />
            </Grid>

          </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <MDBox pt={3} px={2}>
        <MDTypography variant="h3" fontWeight="medium">
          Job Application Assistant
        </MDTypography>
      </MDBox>
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Paste job application question text in the box below to have Tongo write an answer using your resume.
        </MDTypography>
      </MDBox>
      <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
      <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '115ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
          id="outlined-multiline-static"
          label="Question Text"
          multiline
          rows={4}
          defaultValue=""
          placeholder="paste application question text here"

         
        />
        <Button variant="contained">Write My Response</Button>
         {/*sx={{ bgcolor: '#FFFFFF', color: '#FFFFFF'}}*/}
    </Box>
    <MDTypography variant="h6" fontWeight="medium">
         Copy Your Answer From The Box Below.
        </MDTypography>
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '115ch' },
      }}
      noValidate
      autoComplete="off"
    >

    <CopyExample/>
         {/*sx={{ bgcolor: '#FFFFFF', color: '#FFFFFF'}}*/}
    </Box>
    </Grid>
    </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        Item Three
      </CustomTabPanel>
    </Box>
  



        </MDBox>
        {/*}
        <MDBox mx={0} mt={3} py={3} px={0} pb={5} pt={0}>
          <MDButton onClick={() => navigate(-1)} variant="gradient" color="info" size="small">
            Back
          </MDButton>
        </MDBox>
        */}
      </MDBox>

  
  );
}

export default ViewApplication;
