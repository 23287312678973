/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// react-router-dom components
import { useParams, useNavigate } from 'react-router-dom';
import { createSearch, updateSearch, getSearch, getJobDetails } from '../../../searches';
import { getProfile } from '../../../profile';

import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import localforage from 'localforage';
import dayjs from 'dayjs';
import SearchIcon from '@mui/icons-material/Search';
// @mui material components
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetSearchQuery } from "../../../services/search";

import { setResults,setTerms/*,setLastPromiseInfo*/ } from '../../../state/search/searchSlice'; // Adjust the import path as necessary
import { searchApi } from '../../../services/search';


import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { fetcher, getUserId } from 'api';

// Common fetch function for job tasks

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDSnackbar from 'components/MDSnackbar';
import MDLoading from 'components/MDLoading';

// Material Dashboard 2 React example components
import DashboardLayout from 'tongoComponents/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'tongoComponents/Navbars/DashboardNavbar';
import stateOptions from 'tongoComponents/Misc/states';
import Footer from 'tongoComponents/Footer';
import { func } from 'prop-types';


import PropTypes from 'prop-types';
import { stringify } from 'uuid';




const usStates = [
  { code: "AL", label: "Alabama" },
  { code: "AK", label: "Alaska" },
  { code: "AS", label: "American Samoa" },
  { code: "AZ", label: "Arizona" },
  { code: "AR", label: "Arkansas" },
  { code: "CA", label: "California" },
  { code: "CO", label: "Colorado" },
  { code: "CT", label: "Connecticut" },
  { code: "DE", label: "Delaware" },
  { code: "DC", label: "District Of Columbia" },
  { code: "FM", label: "Federated States Of Micronesia" },
  { code: "FL", label: "Florida" },
  { code: "GA", label: "Georgia" },
  { code: "GU", label: "Guam" },
  { code: "HI", label: "Hawaii" },
  { code: "ID", label: "Idaho" },
  { code: "IL", label: "Illinois" },
  { code: "IN", label: "Indiana" },
  { code: "IA", label: "Iowa" },
  { code: "KS", label: "Kansas" },
  { code: "KY", label: "Kentucky" },
  { code: "LA", label: "Louisiana" },
  { code: "ME", label: "Maine" },
  { code: "MH", label: "Marshall Islands" },
  { code: "MD", label: "Maryland" },
  { code: "MA", label: "Massachusetts" },
  { code: "MI", label: "Michigan" },
  { code: "MN", label: "Minnesota" },
  { code: "MS", label: "Mississippi" },
  { code: "MO", label: "Missouri" },
  { code: "MT", label: "Montana" },
  { code: "NE", label: "Nebraska" },
  { code: "NV", label: "Nevada" },
  { code: "NH", label: "New Hampshire" },
  { code: "NJ", label: "New Jersey" },
  { code: "NM", label: "New Mexico" },
  { code: "NY", label: "New York" },
  { code: "NC", label: "North Carolina" },
  { code: "ND", label: "North Dakota" },
  { code: "MP", label: "Northern Mariana Islands" },
  { code: "OH", label: "Ohio" },
  { code: "OK", label: "Oklahoma" },
  { code: "OR", label: "Oregon" },
  { code: "PW", label: "Palau" },
  { code: "PA", label: "Pennsylvania" },
  { code: "PR", label: "Puerto Rico" },
  { code: "RI", label: "Rhode Island" },
  { code: "SC", label: "South Carolina" },
  { code: "SD", label: "South Dakota" },
  { code: "TN", label: "Tennessee" },
  { code: "TX", label: "Texas" },
  { code: "UT", label: "Utah" },
  { code: "VT", label: "Vermont" },
  { code: "VI", label: "Virgin Islands" },
  { code: "VA", label: "Virginia" },
  { code: "WA", label: "Washington" },
  { code: "WV", label: "West Virginia" },
  { code: "WI", label: "Wisconsin" },
  { code: "WY", label: "Wyoming" },
  { code: "", label: "" }

]

function ControllableStates({ handleChange }) {

  const [value, setValue] = useState(usStates[0]);
  const handleInputChange = (newValue) => {
    console.log("got this far at least")
    console.log(newValue)
    if (newValue === null) {

      newValue = " "

    } else {
      newValue = newValue.code
    }
    handleChange(newValue);
    ("should have been sent: ", newValue)
    setValue(newValue)
  }
  return (

    <Autocomplete
      id="country-select-demo"
      sx={{ width: 300 }}
      options={usStates}
      autoHighlight
      getOptionLabel={(option) => option.label}
      onChange={(event, option) => { handleInputChange(option) }}
      //
      renderOption={(props, option) => (

        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

          {option.label} ({option.code})
        </Box>
      )}

      renderInput={(params) => (
        <TextField
          variant="standard"
          {...params}
          label="State"
          inputProps={{
            ...params.inputProps,

            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}








function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        border: '1px solid',
        borderColor: (theme) =>
          theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
        p: 1,
        borderRadius: 2,
        textAlign: 'center',
        fontSize: '0.875rem',
        fontWeight: '700',
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};


function GetDate() {
  now: new Date().toISOString();
}




function MiniSearch({ searching, handleSearch, currentPage, reloadTrigger, handleSetReloadTrigger, searchParams }) {
  const queryParams = useParams();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(true)

  const [loading, setLoading] = useState(false);

  const [messageSB, setMessageSB] = useState([]);
  const closeMessage = () => setMessageSB([]);

  const [generated, setGenerated] = useState(false);
  const [retrieve, setRetrieve] = useState(false);
  const [userId, setUserId] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [jobSearch, setJobSearch] = useState({
    id: null,
    name: '',
    title: '',
    level: 0,
    keywords: null,
    jobLocation: {
      city: '',
      state: '',
      searchDistance: null,
    },
    jobType: 'full-time',
    status: 'pending',
    results: [],
    count: "",
    created: "",
    updated: "",
    lastSearch: "",
    engine: "",
    deleted: false,
    archived: false,
    searchHistory: null,
    startDate: dayjs(),
    endDate: dayjs().add(2, 'month'),
    notificationPeriod: 24,
  });
  const style = {
    py: 0,
    width: '100%',
    minWidth: 500,
    borderRadius: 2,
    border: 'none',//'1px solid',
    borderColor: 'divider',
    backgroundColor: 'background.paper',
    listStyleType: 'none',
    pt: 3,
    pb: 0
  };

  const dispatch = useDispatch();
  const [trigger, { data, error,isUninitialized, isLoading, isSuccess, isError, isFetching }] = useLazyGetSearchQuery()
  const results = useSelector((state) => state.searchApi.results);
  const subscriptions = useSelector((state) => state.searchApi.subscriptions);
  const thestate = useSelector((state) => state.searchApi);
  const getData = async () => {
    //right now axios calls over and over and over in perpetuity
    let params = null
    if (location?.state?.searchParams) {
      params = location.state.searchParams

    }
    console.log("params", params)
    /*const response = await axios.get('https://search.tongo.ai/search', {
      params: params
    })*/

    console.log("params", params)
    console.log("response.data ", response.data)
    console.log("messages ", [...messages, response.data])
    setMessages([...messages, response.data][0]);
    setRows([...rows, response.data])


  };
  /*
    const query= useQuery({
      queryFn:()=>getData(),
      queryKey:()=>["searchResults"]
    });
    if (query.isLoading){
      return <div>LOADING</div>
    }*/
  useEffect(() => {

    localforage.getItem('user').then((user) => {

      if (user?.id) {
        setUserId(user?.id)
        console.log("USER ID IS: ", JSON.stringify(userId))
      }

    });

    async function fetchData() {
      try {
        if (!fetched) {
          if (!userId) {
            const uid = await getUserId();



            console.log("USER ID IS: ", JSON.stringify(uid))
            setUserId(uid)
            console.log("USER ID IS: ", JSON.stringify(userId))

            setFetched(true);
            return uid
          }
        }


      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);

      }

    };
    const uId = fetchData()
    if (!uId) {
      setUserId(uId)
    }
    console.log("USER ID IS: ", JSON.stringify(userId))
    if (location?.state?.searchParams) {
      getData(location.state.searchParams)
      handleSearch(true)
      console.log("searching for ", location.state.searchParams)

    }
  }, [fetched]);











  const handleFormSubmit = async (event) => {
    if (loading) return;

    event.preventDefault();

    if (jobSearch.keywords == '') {
      setMessageSB([
        {
          color: 'error',
          icon: 'error',
          title: 'Keyword is a required field',
          message: 'Please add a few keywords and try again!',
        },
      ]);
      return;
    }

    // const keywordsString = jobSearch.keywords.join(', ');

    try {
      handleSearch(true)
      let successNavigate;

      // const updatedJobSearch = {
      //   ...jobSearch,
      //   keywords: keywordsString,
      // };

      if (jobSearch.id) {
        await updateSearch(jobSearch);
        successNavigate = `/search`;
      } else {
        let newJobSearch = await createSearch(jobSearch);
        successNavigate = `/search`;
      }

      setMessageSB([
        {
          color: 'success',
          icon: 'check',
          title: `Job search was ${jobSearch.id ? 'updated' : 'created'}!`,
          message: 'Redirecting to job search...',
        },
      ]);

      setTimeout(() => {
        navigate(successNavigate);
      }, 2000);
    } catch (error) {
      if (error.status === 401) {
        await profile.signoffProfile();
        navigate('/sign-in');
      }
      setMessageSB([
        {
          color: 'error',
          icon: 'error',
          title: `Error ${jobSearch.id ? 'updating' : 'creating'} job search!`,
          message: 'Please review your info and try again!',
        },
      ]);
    } finally {
      handleSearch(false)

    }
  };
  function handleAutoCompleteChange(value) {
    console.log("value", value)
    jobSearch.jobLocation.state = value

    console.log("value", JSON.stringify(jobSearch.jobLocation.state))
  }
  //handle form editing for controlled inputs
  const handleChange = (event) => {
    console.log("event: ", event)
    const { name, value } = event.target;
    console.log("name ", value, "value ", value)
    if (name.includes('jobLocation.')) {
      const nestedPropertyName = name.split('.')[1];
      setJobSearch((jobSearch) => ({
        ...jobSearch,
        jobLocation: {
          ...jobSearch.jobLocation,
          [nestedPropertyName]: value,
        },
      }));
    } else {
      setJobSearch((jobSearch) => ({
        ...jobSearch,
        [name]: value,
      }));
    }
  };

  const handleKeywordsChange = (event, newValue) => {
    // setResumeKeywords(newValue);
    setJobSearch((prevJobSearch) => ({
      ...prevJobSearch,
      keywords: newValue,
    }));
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    const isoValue = value.toISOString();

    if (name === 'startDate' || name === 'endDate') {
      setJobSearch((prevJobSearch) => ({
        ...prevJobSearch,
        startDate: name === 'startDate' ? isoValue : prevJobSearch.startDate,
        endDate: name === 'endDate' ? isoValue : prevJobSearch.endDate,
      }));
    }
  };

  const retrieveKeywords = () => {
    setLoading(true);
    getProfile({ fields: { resumeKeywords: true } })
      .then((result) => {
        if (result?.length !== 0) {
          setGenerated(true);
          setJobSearch((jobSearch) => ({
            ...jobSearch,
            keywords: Array.isArray(jobSearch.keywords)
              ? [...jobSearch.keywords, ...Object.values(result.resumeKeywords)]
              : Object.values(result.resumeKeywords), // Initialize as an array if not already
          }));
        }
      })
      .catch((error) => {
        setMessageSB([
          {
            color: 'error',
            icon: 'error',
            title: 'Error retrieving résumé keywords',
            message: error.message,
          },
        ]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderMsg = (color, icon, title, message) => (
    <MDSnackbar
      color={color}
      icon={icon}
      title={title}
      content={message}
      dateTime=""
      open={Boolean(messageSB)}
      onClose={closeMessage}
      close={closeMessage}
      bgWhite
    />
  );


  const sendSearchQuery = () => {
    console.log("############current page")
    const params={
      what: jobSearch?.title,
      city: jobSearch?.jobLocation.city,
      state: jobSearch?.jobLocation.state,
      userId: userId
    }
    //GET200458 B41.1 sChrome 128 https://search.tongo.ai/search?what=secretary&city=fresno&state=CA&userId=94cd00ac-f88b-4006-bcd3-49b89cf20862
    //GET404410 B2 msChrome 128   https://search.tongo.ai/search/?what=secretary&city=fresno&state=CA&userId=94cd00ac-f88b-4006-bcd3-49b89cf20862
    /*const response = axios.get('https://search.tongo.ai/search', {
      params: params
    })

    console.log("params", params)
    console.log("response.data ", response.data)

    axios.get('https://search.tongo.ai/search', {
      params: {
        what: jobSearch?.title,
        city: jobSearch?.jobLocation.city,
        state: jobSearch?.jobLocation.state,
        userId: userId
      }
    }).then((response) => {

      console.log("searching state ", searching)
      console.log("response.data ", response.data)
      //console.log("messages ", [...messages, response.data])
      //setMessages([...messages, response.data][0]);
      setRows([...rows, response.data])

    }).catch(console.log).finally(() => {
      handleSearch(false)
      console.log('Experiment completed');
    })
    */
    trigger(params);

            
  //dispatch(setResults(data));
  /*dispatch(setLastPromiseInfo(lastPromiseInfo));*/
  dispatch(setTerms(params));

    console.log("############searchParams:::", searchParams)
    console.log("data,error, isLoading:", data,error, isLoading)
    console.log("############params:::", params)
    console.log("############params:::", params)

    if (currentPage == "dashboard") {

      navigate("/search/");


          //dispatch(userSlice.actions.setTerms({userId: userId}));
      
      /*navigate("/search/", {
        state: {
          searchParams: {
            what: jobSearch?.title,
            city: jobSearch?.jobLocation.city,
            state: jobSearch?.jobLocation.state,
           
          }
        }
      })*/
        

    } else {

      handleSearch(true)
      console.log("searching for ", jobSearch?.title, ", ", jobSearch?.jobLocation.city, ", ", jobSearch?.jobLocation.state, ", ", userId)
      


    }
  }
  useEffect(() => {
    console.log("############useEffect::: thestate", thestate)
    if (data) {
        console.log("############useEffect:::", searchParams);
        console.log("data,error, isLoading:", data, error, isLoading);
        dispatch(setResults(data));
    }
    if (error) {
        console.log("############useEffect:::", searchParams);
        console.log("data,error, isLoading:", data, error, isLoading);
        dispatch(setResults(error));
    }
    if (isLoading) {
        console.log("############useEffect:::", searchParams);
        console.log("############useEffect:::", searchParams);
        console.log("data,error, isLoading:", data, error, isLoading);
        dispatch(setResults(error));
      }
}, [data,subscriptions]);
  const onSearch = () => {
    console.log("############current page")
    if (currentPage == "dashboard") {
      let params = null
      if (location?.state?.searchParams) {
        params = location.state.searchParams
  
      }
      navigate("/search/", {
        state: {
          searchParams: {
            what: jobSearch?.title,
            city: jobSearch?.jobLocation.city,
            state: jobSearch?.jobLocation.state,
            userId: userId
          }
        }
      })

    } else {

      handleSearch(true)
      console.log("searching for ", jobSearch?.title, ", ", jobSearch?.jobLocation.city, ", ", jobSearch?.jobLocation.state, ", ", userId)
      sendSearchQuery()
      //axios calls repeatedly and doesnt stop so we need to comment this out and swap it for something else
      console.log("params", params)
      const response = axios.get('https://search.tongo.ai/search', {
        params: params
      })
  
      console.log("params", params)
      console.log("response.data ", response.data)
      console.log("messages ", [...messages, response.data])
      axios.get('https://search.tongo.ai/search', {
        params: {
          what: jobSearch?.title,
          city: jobSearch?.jobLocation.city,
          state: jobSearch?.jobLocation.state,
          userId: userId
        }
      }).then((response) => {

        console.log("searching state ", searching)
        console.log("response.data ", response.data)
        //console.log("messages ", [...messages, response.data])
        //setMessages([...messages, response.data][0]);
        setRows([...rows, response.data])

      }).catch(console.log).finally(() => {
        handleSearch(false)
        console.log('Experiment completed');
      })
      

    }





  }

    return (
      <MDBox pt={0} pb={3}>
        {messageSB.length ? renderMsg(messageSB[0].color, messageSB[0].icon, messageSB[0].title, messageSB[0].message) : null}
        <MDLoading text="Retrieving job search data..." open={loading} />

        <MDBox sx={style}>


          <Typography sx={{ ml: 3, mt: 1, mb: 0 }} variant="h6" component="div">

            {!jobSearch?.id ? "" : <>Edit this job search</>}
          </Typography>

          <FormGroup>
            <MDBox pt={0} pb={3} px={3}>
              <MDBox component="form" role="form" onSubmit={handleFormSubmit}>
                {toggle && (
                  <MDBox mb={3} width="100%" display="flex" alignItems="center">
                    <Stack>

                      <Stack
                        mb={5}
                        direction="row"
                        spacing={2}
                      >
                        <Item sx={{ border: 'none', gridColumn: '1', gridRow: '1' }}>
                          <Typography sx={{ ml: 3, mt: 1, mb: 0 }} variant="h4" component="div">

                            {jobSearch?.id ? 'Modify Job Search' : <SearchIcon fontSize="large" />}
                          </Typography></Item>
                        <Item sx={{ border: "none", minWidth: "250px" }}>
                          <MDInput
                            type="text"
                            label="Job position title"
                            variant="standard"
                            fullWidth
                            name="title"
                            required={false}
                            placeholder="Executive"
                            aria-label="Job search name"
                            value={jobSearch?.title || ''}
                            onChange={handleChange}
                            autoComplete="off"
                            shrink={'true'}
                          /></Item>
                        <Item sx={{ border: 'none', minWidth: "150px" }}>
                          <MDInput
                            type="select"
                            label="Job location city"
                            variant="standard"
                            name="jobLocation.city"
                            required={false}
                            placeholder="Seattle"
                            aria-label="Job location"
                            value={jobSearch?.jobLocation.city || ''}
                            onChange={handleChange}
                            shrink={'true'}
                            autoComplete="off"
                          />
                        </Item>
                        <Item sx={{ border: 'none', minWidth: "150px" }}>
                          <ControllableStates multiple handleChange={handleAutoCompleteChange} />
                        </Item>
                        <Item sx={{ border: 'none' }}>
                          <MDButton onClick={sendSearchQuery} variant="gradient" color="info" disabled={loading}>
                            {jobSearch?.id ? 'Update job search' : 'Search'}
                          </MDButton>
                        </Item>
                      </Stack>
                      <Item sx={{ border: 'none', }}>

                        <Button onClick={() => setToggle(!toggle)} variant="text" color="info" disabled={loading}>
                          {'Advanced Options'}
                        </Button>

                      </Item>
                      <Item>
                      

 
                <div>
                  {console.log("subscriptions:",subscriptions)}
                  {console.log("thestate :",thestate)}
                  {console.log("results:",results)}
                {!isLoading && !isFetching && !isSuccess && !isError && <h2>placeholder</h2>}
                { <p>subscriptions: {JSON.stringify(subscriptions, null, 3)}</p>}
                {isFetching && <p>Fetching...</p>}
                {!isUninitialized && <p>Result: {result}</p>} 
                {isUninitialized && <p>Uninitialized...</p>} 
                {isLoading && <h2>Searching...</h2>}
                {isSuccess && <p>Search completed successfully.</p>}
                {isError && <p>Error: {error.message}</p>}
                {data && (
                    <div>
                        <h3>Search Results:</h3>
                        <pre>{JSON.stringify(data, null, 2)}</pre>
                    </div>
                )}
            </div>
                {error && <h2>Error: {error.message}</h2>}
                {results && (
                    <div>
                        <h3>Search Results:</h3>
                        <pre>{JSON.stringify(results, null, 2)}</pre>
                    </div>
                )}
            </Item>
                    </Stack>

                  </MDBox>

                )}

                {!toggle && (

                  <MDBox component="form" role="form" onSubmit={handleFormSubmit}>

                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        label="Job search name"
                        variant="standard"
                        fullWidth
                        name="name"
                        required={true}
                        placeholder="My new job search"
                        aria-label="Job search name"
                        value={jobSearch?.name || ''}
                        onChange={handleChange}
                        autoComplete="off"
                        shrink={'true'}
                      />
                    </MDBox>
                    <MDBox mb={3}>
                      <MDInput
                        type="text"
                        label="Job position title"
                        variant="standard"
                        fullWidth
                        name="title"
                        required={false}
                        placeholder="Executive"
                        aria-label="Job search name"
                        value={jobSearch?.title || ''}
                        onChange={handleChange}
                        autoComplete="off"
                        shrink={'true'}
                      />
                    </MDBox>
                    <MDBox mb={3}>
                      <InputLabel id="position-label">Job position level</InputLabel>
                      <Select
                        variant="standard"
                        fullWidth
                        labelId="position-label"
                        label="Job position level"
                        aria-label="Job position level"
                        value={jobSearch?.level}
                        name="level"
                        onChange={handleChange}
                      >
                        <MenuItem key={0} value={0}>
                          Any
                        </MenuItem>
                        <MenuItem key={1} value={1}>
                          Entry level
                        </MenuItem>
                        <MenuItem key={2} value={2}>
                          Mid level
                        </MenuItem>
                        <MenuItem key={3} value={3}>
                          Senior level
                        </MenuItem>
                      </Select>
                    </MDBox>
                    <MDBox mb={4}>
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <Autocomplete
                          multiple
                          required
                          id="tags-standard"
                          size="normal"
                          options
                          value={jobSearch?.keywords || []}
                          renderInput={(params) => (
                            <MDInput {...params} variant="standard" label="Search keywords" placeholder="Keywords, press enter for each" fullWidth />
                          )}
                          limitTags={30}
                          freeSolo
                          onChange={handleKeywordsChange}
                        />

                      </MDBox>
                      <MDBox mt={2} mb={1} hidden={!jobSearch?.id || jobSearch?.keywords?.length === 0 ? false : true}>
                        {/* {!jobSearch?.keywords?.length === 0 ? ( */}
                        <MDButton
                          variant="gradient"
                          color="success"
                          size="small"
                          onClick={() => {
                            retrieveKeywords();
                          }}
                        >
                          Use keywords from my résumé
                        </MDButton>
                        {/* ) : null} */}
                      </MDBox>
                    </MDBox>
                    <MDBox mb={2} width="100%" display="flex" alignItems="center">
                      <MDInput
                        type="select"
                        label="Job location city"
                        variant="standard"
                        name="jobLocation.city"
                        required={true}
                        placeholder="Seattle"
                        aria-label="Job location"
                        value={jobSearch?.jobLocation.city || ''}
                        onChange={handleChange}
                        shrink={'true'}
                        autoComplete="off"
                      />
                      &nbsp;&nbsp;&nbsp;
                      <MDInput
                        select
                        variant="standard"
                        type="select"
                        label="State"
                        aria-label="State"
                        required={true}
                        value={jobSearch?.jobLocation.state || ''}
                        name="jobLocation.state"
                        onChange={handleChange}
                        shrink={'true'}
                        autoComplete="off"
                        style={{ marginTop: 'auto', alignItems: 'left' }} // Align to the bottom
                      >
                        {stateOptions.map((stateOption) => (
                          <MenuItem key={stateOption.code} value={stateOption.code}>
                            {stateOption.name}
                          </MenuItem>
                        ))}
                      </MDInput>
                      &nbsp;&nbsp;&nbsp;
                      <MDInput
                        type="number"
                        label="Search radius (miles)"
                        variant="standard"
                        name="jobLocation.searchDistance"
                        required={true}
                        placeholder="20"
                        aria-label="Search radius"
                        value={jobSearch?.jobLocation.searchDistance || ''}
                        onChange={handleChange}
                        shrink={'true'}
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                        Job position type
                      </MDTypography>
                      <MDBox ml={3}>
                        <RadioGroup row aria-labelledby="job postion type" value={jobSearch?.jobType} name="jobType" onChange={handleChange}>
                          <FormControlLabel
                            value="full-time"
                            control={<Radio />}
                            fontWeight="regular"
                            color="text"
                            label={
                              <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                                Full-time
                              </MDTypography>
                            }
                          />
                          <FormControlLabel
                            value="part-time"
                            control={<Radio />}
                            fontWeight="regular"
                            color="text"
                            label={
                              <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                                part-time
                              </MDTypography>
                            }
                          />
                          <FormControlLabel
                            value="contract"
                            control={<Radio />}
                            fontWeight="regular"
                            color="text"
                            label={
                              <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                                Contract
                              </MDTypography>
                            }
                          />
                          <FormControlLabel
                            value="internship"
                            control={<Radio />}
                            fontWeight="regular"
                            color="text"
                            label={
                              <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                                Internship
                              </MDTypography>
                            }
                          />
                          <FormControlLabel
                            value="volunteer"
                            control={<Radio />}
                            fontWeight="regular"
                            color="text"
                            label={
                              <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                                Volunteer
                              </MDTypography>
                            }
                          />
                          <FormControlLabel
                            value="other"
                            control={<Radio />}
                            fontWeight="regular"
                            color="text"
                            label={
                              <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                                Other
                              </MDTypography>
                            }
                          />
                        </RadioGroup>
                      </MDBox>
                    </MDBox>
                    {/* <MDBox mb={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateRangePicker localeText={{ start: 'Start search on', end: 'End search on' }} />
                        </LocalizationProvider>
                      </MDBox> */}
                    <MDBox mb={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={dayjs(jobSearch?.startDate)}
                          label="Start search"
                          name="startDate"
                          required={false}
                          aria-label="Start search on"
                          onChange={handleDateChange}
                          slotProps={{ textField: { variant: 'standard' } }}
                          input={(params) => <MDInput {...params} />}
                        />
                      </LocalizationProvider>
                      &nbsp;&nbsp;&nbsp;
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={dayjs(jobSearch?.endDate)}
                          label="End search on"
                          name="endDate"
                          required={false}
                          aria-label="End search on"
                          onChange={handleDateChange}
                          slotProps={{ textField: { variant: 'standard' } }}
                          input={(params) => <MDInput {...params} />}
                        />
                      </LocalizationProvider>
                    </MDBox>
                    <MDBox mb={2}>
                      <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                        Notification frequency
                        <br />
                        Searches are performed daily. You can be notified whenever matches are found:
                      </MDTypography>
                      <MDInput
                        select
                        variant="standard"
                        fullWidth
                        type="select"
                        // label="Notify me"
                        // aria-label="Notify me"
                        value={jobSearch?.notificationPeriod}
                        name="notificationPeriod"
                        onChange={handleChange}
                        shrink={'false'}
                        autoComplete="off"
                      >
                        <MenuItem key={24} value={24}>
                          Daily
                        </MenuItem>
                        <MenuItem key={48} value={48}>
                          Every 2 days
                        </MenuItem>
                        <MenuItem key={168} value={168}>
                          Weekly
                        </MenuItem>
                        <MenuItem key={336} value={336}>
                          Every 2 weeks
                        </MenuItem>
                      </MDInput>
                    </MDBox>
                    <MDBox mb={2} width="100%" display="flex" alignItems="center">

                      &nbsp;&nbsp;&nbsp;
                      <MDBox display="flex" alignItems="left" mt={5}>
                        <MDBox mr={5}>

                        </MDBox>

                      </MDBox>
                    </MDBox>

                  </MDBox>

                )}

                <MDBox display="flex" alignItems="left" mt={5}>

                  <MDBox>
                    {jobSearch?.id && (
                      <MDButton onClick={() => navigate(-1)} variant="gradient" color="info" ml={8}>
                        Cancel
                      </MDButton>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>

          </FormGroup>

        </MDBox >




      </MDBox >





    );
  }

  export default MiniSearch;
