/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';
 
// @mui material components
import Icon from '@mui/material/Icon';
import List from '@mui/material/List'; 
import ListItem from '@mui/material/ListItem';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Timeline context
import {useTimeline} from 'tongoComponents/TongoTimeline/context';

// Custom styles for the CustomSectionTimelineItem
import customSectionTimelineItem from 'tongoComponents/TongoTimeline/CustomSectionTimelineItem/styles';

function CustomSectionTimelineItem({color, icon, customSection, title, description, url, lastItem}) {
  const isDark = useTimeline();

  return (
    <MDBox position="relative" mb={3} sx={(theme) => customSectionTimelineItem(theme, {lastItem, isDark})}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor={color}
        color="white"
        width="2rem"
        height="2rem"
        borderRadius="50%"
        position="absolute"
        top="8%"
        left="2px"
        zIndex={2}
        sx={{fontSize: ({typography: {size}}) => size.sm}}
      >
        <Icon fontSize="inherit">{icon}</Icon>
      </MDBox>
      <MDBox ml={5.75} pt={description ? 0.7 : 0.5} lineHeight={0}>
        <MDTypography variant="button" fontWeight="medium" color={isDark ? 'white' : 'dark'}>
          {title}
        </MDTypography>
        <MDBox mt={0.5}>
          <MDTypography variant="caption" color={isDark ? 'secondary' : 'text'}>
            {/*dateTime*/}
          </MDTypography>
        </MDBox>
        <MDBox mt={1} mb={1.5}>
              {console.log("customSection wutttttttttt",customSection)}
              {console.log("customSection.content",customSection.content)}
              
         
              
                
                  <MDBox key={customSection.title}>
                    <MDTypography variant="button" color={isDark ? 'white' : 'dark'}>
                   

                    </MDTypography>


                    
                    
                    {/*customSection.content?<MDBox ml={2}>{customSection.content.map((item,index,arr) => (item))}</MDBox>:""*/
                    }
                  </MDBox>
             

              <List>
 
              {Array(customSection.content).map((item, index) => (
                <MDBox key={index}>
                  <MDTypography variant="button" color={isDark ? 'white' : 'dark'}>
                  <MDBox ml={2}>{item? `${item} `:""}</MDBox>
               
                        {item.misc? `${item.misc}`:""}
                    
                    

                  </MDTypography>
                </MDBox>
              ))}
              </List>

                        
        </MDBox>
        <MDBox mt={1} mb={1.5}>
          {url ? (
            <MDTypography component="a" href="{url}" variant="button" fontWeight="bold" color="info" textGradient>
              {description}
            </MDTypography>
          ) : null}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of CustomSectionTimelineItem
CustomSectionTimelineItem.defaultProps = {
  color: 'info',
  lastItem: false,
  description: '',
};

// Typechecking props for the CustomSectionTimelineItem
CustomSectionTimelineItem.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark', 'light']),
  icon: PropTypes.node,
  customSection: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,

  description: PropTypes.oneOfType([
    PropTypes.string, // Description as a single string
    PropTypes.arrayOf(PropTypes.string), // Description as an array of strings
  ]),
  url: PropTypes.string,
  lastItem: PropTypes.bool,
};

export default CustomSectionTimelineItem;
