/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

// @mui material components

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDBadge from 'components/MDBadge';
import ActionMenu from 'tongoComponents/ActionMenu';

// Images
// import team2 from 'assets/images/small-logos/indeed.svg';
import team1 from 'assets/images/team-1.jpg';
import team2 from 'assets/images/team-2.jpg';
import team3 from 'assets/images/team-3.jpg';
import team4 from 'assets/images/team-4.jpg';

import {getJobApplications, deleteApplication, archiveApplication} from '../../../jobs';

export default function data(callback) {
  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState(null);
  const [jobsCache, setJobsCache] = useState([]);
  const [fetched, setFetched] = useState(false);

  const navigate = useNavigate();

  const tasks = [
    {
      label: 'Manage',
      icon: 'edit_note',
      action: (id) => {
        navigate(`/short-list/${id}`);
      },
      confirmation: false,
      confirmationMessage: '',
    },
    {
      label: 'Archive',
      icon: 'archive',
      action: archiveApplication,
      confirmation: true,
      confirmationMessage: 'Are you sure you want to archive this job application?',
    },
    {
      label: 'Delete',
      icon: 'delete',
      action: deleteApplication,
      confirmation: true,
      confirmationMessage: 'Are you sure you want to delete this job application? This action cannot be undone.',
    },
  ];

  const Job = ({image, name, company, url}) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name ?? ''}
        </MDTypography>
        <MDTypography display="block" variant="caption">
          {company ?? ''}
        </MDTypography>
        {/* <MDTypography component="a" href={url} variant="caption" color="info" target="_blank">
          Original posting
        </MDTypography> */}
      </MDBox>
    </MDBox>
  );

  const Location = ({city, state, distance}) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {city ?? ''}, {state ?? ''}
      </MDTypography>
      {distance ? <MDTypography variant="caption">{distance} mi.</MDTypography> : null}
    </MDBox>
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!fetched) {
          const data = await getJobApplications();
          setJobsCache(data);
          setFetched(true);
        }
        const columns = [
          {Header: 'job name', accessor: 'job', width: '45%', align: 'left'},
          {Header: 'location', accessor: 'location', align: 'left'},
          {Header: 'status', accessor: 'status', align: 'center'},
          {Header: 'applied on', accessor: 'date', align: 'center'},
          {/*Header: 'action', accessor: 'action', align: 'center'*/},
        ];
        const rows = jobsCache.map((job) => ({
          job: <Job image={renderIcon()} name={job.jobTitle} company={job.companyName} url={job.jobPostingUrl} />,
          location: <Location city={job?.jobLocation?.city} state={job?.jobLocation?.state} distance={job?.jobLocation?.distance} />,
          status: <MDBox ml={-1}>{renderStatus(job.coverLetterStatus)}</MDBox>,
          date: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {renderDate(job.created)}
            </MDTypography>
          ),
          action:<ActionMenu id={job.id} onActionCompleted={() => setFetched(false)} tasks={tasks} />,
        }));
        setTable({columns, rows});
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [fetched]);

  const renderDate = (date) => {
    return new Date(date).toLocaleDateString('en-US');
  };
  const renderIcon = () => {
    const r = Math.floor(Math.random() * 5);
    switch (r) {
      case 0:
      case 1:
        return team1;
      case 2:
        return team2;
      case 3:
        return team3;
      case 4:
        return team4;
      case 5:
        return team5;
    }
  };

  const renderStatus = (status) => {
    switch (status) {
      case 'pending':
        return <MDBadge badgeContent="cover letter pending" color="warning" variant="gradient" size="sm" />;
      case 'generated':
        return <MDBadge badgeContent="cover letter generated" color="info" variant="gradient" size="sm" />;
      case 'edited':
        return <MDBadge badgeContent="cover letter edited" color="success" variant="gradient" size="sm" />;
    }
  };

  useEffect(() => {
    if (callback) {
      callback(loading, table);
    }
  }, [callback, loading, table]);
  return {loading, table};
}
