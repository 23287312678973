/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

import {useState} from 'react';

// react-router-dom components
import {Link, useNavigate} from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import MuiLink from '@mui/material/Link';

// @mui icons
import FacebookIcon from '@mui/icons-material/Facebook';
import GitHubIcon from '@mui/icons-material/GitHub';
import GoogleIcon from '@mui/icons-material/Google';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import MDSnackbar from 'components/MDSnackbar';
import MDLoading from 'components/MDLoading';

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';

// Images
import bgImage from 'assets/images/tongo-logo.png';

import {loginProfile} from '../../../profile';

function SignIn() {
  const navigate = useNavigate();

  const [errorSB, setErrorSB] = useState([]);
  const closeError = () => setErrorSB([]);
  const [disabled, setDisabled] = useState(true);
  const [rememberMe, setRememberMe] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const email = event.target.elements.email.value.trim().toLowerCase();
    const password = event.target.elements.password.value;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const formData = {email, password, rememberMe};

    if (!emailRegex.test(email) || !password) {
      setError([{title: 'Invalid credentials', message: 'Make sure your email and password arecorrrect'}]);
      return;
    } else {
      setLoading(true);
      loginProfile(formData)
        .then((result) => {
          navigate('/dashboard');
        })
        .catch((error) => {
          setErrorSB([{title: 'Invalid credentials', message: 'Make sure your email and password are corrrect'}]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const renderError = (title, message) => (
    <MDSnackbar color="error" icon="error" title={title} content={message} dateTime="" open={true} onClose={closeError} close={closeError} bgWhite />
  );

  return (
    <BasicLayout /*image={bgImage}*/>
      {errorSB.length ? renderError(errorSB[0].title, errorSB[0].message) : null}
      <MDLoading open={loading} text="Signing in..." />

      <Card>
      <Grid container spacing={1} justifyContent="center" sx={{mt: 1, mb: 2}}>
            <Grid item xs={12}  alignItems="center" mx={10} >
              
              <img src={bgImage} alt="Italian Trulli"></img>
              
            </Grid>
          </Grid>
        <MDBox variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" mx={2} mt={-1} p={2} mb={1} textAlign="center">

          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{mt: 1, mb: 2}}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            {/* <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid> */}
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleFormSubmit}>
            <MDBox mb={2}>
              <MDInput variant="standard" name="email" type="email" label="Email" required fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput variant="standard" name="password" type="password" label="Password" inputProps={{minLength: 8}} required fullWidth />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch name="rememberMe" checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{cursor: 'pointer', userSelect: 'none', ml: -1}}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{' '}
                <MDTypography component={Link} to="/sign-up" variant="button" color="info" fontWeight="medium" textGradient>
                  Sign up
                </MDTypography>
              </MDTypography>
              <br />
              <MDTypography variant="button" color="text">
                Forgot sign in info?{' '}
                <MDTypography component={Link} to="/reset-password" variant="button" color="info" fontWeight="medium" textGradient>
                  Reset password
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default SignIn;
