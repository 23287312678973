import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,

  useNavigate
} from "react-router-dom";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

//const navigate = useNavigate();
function BasicModal() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        Tongo needs your resume to help you find and apply to jobs. Choose one:
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">

                          
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                p: 1,
                                m: 1,

                                borderRadius: 1,
                              }}
                            >
                              <Box ml={{ xs: 2, sm: 5 }}>
                                <Button
                                  component="label"
                                  color="primary"
                                  size="large"
                                  variant="contained"
                                  textColor="white"
                                  tabIndex={-1}
                                  startIcon={<AutoFixHighIcon />}
                                  onClick={() => { navigate("/resume/wizard") }}>

                                  Create or Update a Resume in 5 min
                                </Button>
                              </Box>
                            </Box>
              
          </Typography>

        </Box>
      </Modal>
    </div>
  );
}
export default BasicModal;


