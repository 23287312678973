import {
    AlignmentType,
    Document,
    HeadingLevel,
    Paragraph,
    TabStopPosition,
    TabStopType,
    TextRun
  } from "docx";
  import dayjs from 'dayjs';
  import { skills, achievements } from "../WordExport/cv-data";
  const PHONE_NUMBER = "07534563401";
  const PROFILE_URL = "https://www.linkedin.com/in/dolan1";
  const EMAIL = "docx@docx.com";


    // tslint:disable-next-line: typedef
    
export class WordExport {
    constructor() {
        this.a=""
    }

    createContactInfo(phoneNumber, profileUrl, email) {
        let contactText=String(`${phoneNumber?`Mobile: ${phoneNumber} |`:""}`+`${profileUrl?`LinkedIn: ${profileUrl} |`:""}`+`${email?`Email: ${email} `:""}`)
        return new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new TextRun(
                contactText
            ),
            new TextRun({
              text: String("Address: 58 Elm Avenue, Kent ME4 6ER, UK"),
              break: 1
            })
          ]
        })
      }
    
      createHeading(text) {
        return new Paragraph({
          text: text,
          heading: HeadingLevel.HEADING_1,
          thematicBreak: true
        })
      }
    
      createSubHeading(text) {
        return new Paragraph({
          text: text,
          heading: HeadingLevel.HEADING_2
        })
      }
    
      createInstitutionHeader(institutionName,location) {

        let institutionLocation = ""
        location?institutionLocation=location:institutionLocation=""
        return new Paragraph({
          tabStops: [
            {
              type: TabStopType.RIGHT,
              position: TabStopPosition.MAX
            }
          ],
          children: [
            new TextRun({
              text: institutionName,
              bold: true
            }),
            new TextRun({
              text: `\t${institutionLocation}`,
              bold: true
            })
          ]
        })
      }

      createRoleText(roleText,roleDates) {
        return new Paragraph({
            tabStops: [
                {
                  type: TabStopType.RIGHT,
                  position: TabStopPosition.MAX
                }
              ],
            children: [
            new TextRun({
              text: roleText,
              italics: true
            }),
            new TextRun({
              text: `\t${roleDates}`,
              bold: true
            })
          ]
        })
      }
    
      createBullet(text) {
        return new Paragraph({
          text: text,
          bullet: {
            level: 0
          }
        })
      }
    
      // tslint:disable-next-line:no-any
      createSkillList(skills) {
        return new Paragraph({
          children: [new TextRun(skills.map(skill => skill.name).join(", ") + ".")]
        })
      }
    
      // tslint:disable-next-line:no-any
      createAchievementsList(achievements) {
        return achievements.map(
          achievement =>
            new Paragraph({
              text: achievement.name,
              bullet: {
                level: 0
              }
            })
        )
      }
    
      createInterests(interests) {
        return new Paragraph({
          children: [new TextRun(interests)]
        })
      }
    
      splitParagraphIntoBullets(text) {
        return text.split("\n\n")
      }
    
      // tslint:disable-next-line:no-any
      createPositionDateText(startedMonthYear, endedMonthYear, currentJob) {
        const startDateText = 
        dayjs(startedMonthYear,"MM-YYYY").format("MMM")+ ". " + dayjs(startedMonthYear,"MM-YYYY").format("YYYY")
        const endDateText = currentJob? "Present": `${dayjs(endedMonthYear,"MM-YYYY").format("MMM")}. ${dayjs(endedMonthYear,"MM-YYYY").format("YYYY")}`
    
        return `${startDateText} - ${endDateText}`
      }
    
      getMonthFromInt(value) {
        switch (value) {
          case 1:
            return "Jan"
          case 2:
            return "Feb"
          case 3:
            return "Mar"
          case 4:
            return "Apr"
          case 5:
            return "May"
          case 6:
            return "Jun"
          case 7:
            return "Jul"
          case 8:
            return "Aug"
          case 9:
            return "Sept"
          case 10:
            return "Oct"
          case 11:
            return "Nov"
          case 12:
            return "Dec"
          default:
            return "N/A"
        }
      }
      
     create(resume) {

        //[experiences, education, skills, achievements]
        let basicInfo = resume?.basicInfo
        let education = resume?.resumeParsed[1].education
        let experiences = resume?.resumeParsed[2].experience
        

 
  
        //console.log("skills WORD EXPORT", skills)
      const document = new Document({
        sections: [
          {
            children: [
              new Paragraph({
                text: `${basicInfo.firstName} ${basicInfo.lastName}`,
                heading: HeadingLevel.TITLE
              }),
              this.createContactInfo(basicInfo.phoneNumber, resume.resumeParsed[3].linkedInUrl, basicInfo.email),
              this.createHeading("Education"),


              ...education.map(educationEntry => {
                  const arr = []
                  arr.push(
                    this.createInstitutionHeader(
                        educationEntry.institution
                    
                    )
                  )
   
                  educationEntry.degree.forEach(deg=>{
       
                  arr.push(
                    this.createRoleText(//check if the date is stored as just a year (look for commas and dashes which is how we store it, if yes do MMM YYYY else do YYYY otherwise dayjs will add Jan to the year)
                      `${deg.degreeName} ${deg.fieldOfStudy?deg.fieldOfStudy:""}`,dayjs(deg.graduationMonthYear,["MM-YYYY","YYYY"]).format(/[,\-]/.test(deg.graduationMonthYear)?"MMM YYYY":"YYYY")
                    )
                  )
                    deg.bulletPoints.forEach(bulletPoint => {
                    arr.push(this.createBullet(bulletPoint))
                  })
                })
       
                  return arr
                  
                })
                .reduce((prev, curr) => prev.concat(curr), []),
                this.createHeading("Experience"),
              ...experiences.map(exp => {
                const arr = []
                arr.push(
                  this.createInstitutionHeader(
                      exp.company
                  
                  )
                )


                  exp.position.forEach(position=> {
                    let dates = this.createPositionDateText(
                      position.startedMonthYear,
                      position.endedMonthYear,
                      position.currentJob
                    )
                  arr.push(this.createRoleText(position.title,dates))
                  const bulletPoints = [...position.bulletPoints]
                  bulletPoints.forEach(bulletPoint => {
                    arr.push(this.createBullet(bulletPoint))
                  })
                })
                  return arr
                })
                .reduce((prev, curr) => prev.concat(curr), []),
              this.createHeading("Skills, Achievements and Interests"),
              this.createSubHeading("Skills"),
              this.createSkillList(skills),
              this.createSubHeading("Achievements"),
              ...this.createAchievementsList(achievements),
              this.createSubHeading("Interests"),
              this.createInterests(
                "Programming, Technology, Music Production, Web Design, 3D Modelling, Dancing."
              ),
              this.createHeading("References"),
              new Paragraph(
                "Dr. Dean Mohamedally Director of Postgraduate Studies Department of Computer Science, University College London Malet Place, Bloomsbury, London WC1E d.mohamedally@ucl.ac.uk"
              ),
              new Paragraph("More references upon request"),
              new Paragraph({
                text:
                  "This CV was generated in real-time based on my Linked-In profile from my personal website www.dolan.bio.",
                alignment: AlignmentType.CENTER
              })
            ]
          }
        ]
      })
  
      return document
    }
    
  
}
  