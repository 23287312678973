/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDButton from 'components/MDButton';
import MDBadge from 'components/MDBadge';
import ActionMenu from 'tongoComponents/ActionMenu';

// Images
import team1 from 'assets/images/team-1.jpg';
import team2 from 'assets/images/team-2.jpg';
import team3 from 'assets/images/team-3.jpg';
import team4 from 'assets/images/team-4.jpg';

import {getJob, deleteJob, archiveJob, generateCoverLetter} from '../../../jobs';
import { Link } from 'react-router-dom';
import {  } from 'react-router-dom';

export default function data(callback) {
  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState(null);
  /*const [selected, setSelected] = useState(null);*/
  const [jobsCache, setJobsCache] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [jobIndexIdMap, setJobIndexIdMap] = useState(false);

  const navigate = useNavigate();

  /*
  function selectThisJobFunc(index,id) {
    console.log("checking selected function")
    console.log(id)
    console.log(index)
    setSelected({jobId:id,jobIndex:index})
  };
  
  const selectThisJob = (index,id,selectedBool,selected=false) => {
    console.log("checking selected")
    {selected?( selected.jobId = id, selected.jobIndex = index):selected={jobId:id,jobIndex:index}}
    console.log(selected.jobId)
    console.log(selected.jobId)
    console.log(index)
    console.log(selected.jobIndex)
    setSelected(selected);
    return selectedBool;
  };
  */
  const createIndexIdMap = (map) => {
    console.log("map",map)
    setJobIndexIdMap(map)
  }
  const tasks = [
    {
      label: 'Manage',
      icon: 'edit_note',
      action: (id) => {
        navigate(`/short-list/${id}`);
      },
      confirmation: false,
      confirmationMessage: '',
    },
    {
      label: 'Create cover letter',
      icon: 'post_add',
      action: generateCoverLetter,
      confirmation: false,
      confirmationMessage: '',
    },
    {
      label: 'Archive',
      icon: 'archive',
      action: archiveJob,
      confirmation: true,
      confirmationMessage: 'Are you sure you want to archive this job?',
    },
    {
      label: 'Delete',
      icon: 'delete',
      action: deleteJob,
      confirmation: true,
      confirmationMessage: 'Are you sure you want to delete this job? This action cannot be undone.',
    },
  ];

  useEffect(() => {

  const fetchData = async () => {
      try {
        if (!fetched) {
          const data = await getJob(
            null,
            '{"where":{"archived":{"neq":true}},"fields": {"id":true,"jobTitle":true,"companyName":true,"jobLocation":true,"coverLetterStatus":true,"created":true,"jobPostingUrl":true}}'
          );
          setJobsCache(data);
          setFetched(true);
          console.log("************************************data",data)
          console.log("************************************jobsCache",jobsCache)
        }
        const columns = [
          {Header: 'job name', accessor: 'job', width: '45%', align: 'left'},
          {Header: 'location', accessor: 'location', align: 'left'},
          {Header: 'status', accessor: 'status', align: 'center'},
          {Header: 'date', accessor: 'date', align: 'center'},

        ];

        const rows = jobsCache.map((job,index) => ({
          job: <Job id={job.id} image={renderIcon()}  jobTitle={job.jobTitle} company={job.companyName} city={job?.jobLocation?.city} state={job?.jobLocation?.state}  url={job.jobPostingUrl} />,
          location: <ButtonLink id={job.id}  />,
          status: <MDBox ml={-1}>{renderStatus(job.coverLetterStatus)}</MDBox>,
          date: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {renderDate(job.created)}
            </MDTypography>
          ),
          index:index
         
        }));

        console.log("rows[0]");
        console.log(rows)
        setTable({columns, rows});
        let indexIdMap = []
        console.log("stringify ", JSON.stringify(rows))
        for (let i = 0; i < rows.length; i++) {
          
          console.log("rows[",i,"]",rows[i]);
          console.log("rows[",i,"] index: ",rows[i].index)
          console.log("rows[",i,"] job: ",rows[i].job)
          console.log("rows[",i,"] job id: ",rows[i].job.props.id)
          indexIdMap[rows[i].index]=rows[i].job.props.id
        }
        createIndexIdMap(indexIdMap)

    
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [fetched]);

  const renderDate = (date) => {
    return new Date(date).toLocaleDateString('en-US');
  };
  const renderIcon = () => {
    const r = Math.floor(Math.random() * 5);
    switch (r) {
      case 0:
        return team1;
      case 1:
        return team1;
      case 2:
        return team2;
      case 3:
        return team3;
      case 4:
        return team4;
    }
  };

  const Job = ({image,city,state, rowNumber, jobTitle, company, url,id}) => (
    
    <MDBox display="flex" alignItems="center" lineHeight={1} a="#"  >
      {console.log("wut",rowNumber)}
      {console.log("wut",id)}
      <MDBox ml={2} lineHeight={1} a="#" jobid={id} rownumber={rowNumber}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {/*rowNumber===0 ? "top row" : ''*/}
          {/*selected ? "selected":"not selected" */}
          {console.log('row number', rowNumber)}
          {jobTitle ?? ''} 
        </MDTypography>
        <MDTypography display="block" variant="caption">
          {company ?? ''}
        </MDTypography>
        <MDTypography display="block" variant="caption">
        {city ?? ''}
        {state ? `, ${state}` : ''}
        </MDTypography>
        <MDTypography component="a" href={url} variant="caption" color="info" target="_blank">
          Original posting
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const ButtonLink = ({ id }) => {
      return <Link to={{
      pathname: `/short-list/${id}`
       // your data array of objects}}><button>Apply Now!</button></Link>;
    }}><MDButton size="small" color="info"   >Apply now!</MDButton></Link>
  }
  /*const Location = ({data, distance}) => (

              <MDButton size="medium" color="info"   >Apply Now!
            </MDButton>
   <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {city ?? ''}
        {state ? `, ${state}` : ''}
      </MDTypography>
      {distance ? <MDTypography variant="caption">{distance} mi.</MDTypography> : null}
    </MDBox>
  );*/

  const renderStatus = (status) => {
    switch (status) {
      case 'pending':
        return <MDBadge badgeContent="cover letter pending" color="warning" variant="gradient" size="sm" />;
      case 'generated':
        return <MDBadge badgeContent="cover letter generated" color="info" variant="gradient" size="sm" />;
      case 'edited':
        return <MDBadge badgeContent="cover letter edited" color="success" variant="gradient" size="sm" />;
    }
  };

  useEffect(() => {
    if (callback) {
      callback(loading, table,jobIndexIdMap);
    }
  }, [callback, loading, table]);
  return {loading, table,jobIndexIdMap};
}
