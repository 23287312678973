/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// Material Dashboard 2 React helper functions
import pxToRem from 'assets/theme-dark/functions/pxToRem';

// Material Dashboard 2 React base styles
import colors from 'assets/theme-dark/base/colors';

const {dark} = colors;

const dialogActions = {
  styleOverrides: {
    root: {
      padding: pxToRem(16),
      backgroundColor: dark.main,
    },
  },
};

export default dialogActions;
