/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// react-router-dom components
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {resetPasswordFinish} from '../../../profile';
import {useState} from 'react';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import MDSnackbar from 'components/MDSnackbar';
import MDLoading from 'components/MDLoading';

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';

// Images
import bgImage from 'assets/images/bg-sign-up-cover.jpeg';

function ResetPasswordFinish() {
  const navigate = useNavigate();
  const [errorSB, setErrorSB] = useState([]);
  const closeError = () => setErrorSB([]);
  const [loading, setLoading] = useState(false);

  //retrieve reset key
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const resetKey = queryParams.get('resetKey');
  !resetKey ? (window.location.href = '/sign-in') : null;

  const handleFormSubmit = (event) => {
    event.preventDefault();

    // Retrieve the form data
    const password = event.target.elements.password.value;
    const confirmPassword = event.target.elements.confirmPassword.value;

    if (password !== confirmPassword) {
      setErrorSB([{title: 'Error resetting your password!', message: 'Password mismatched, please review and try again!'}]);
    } else {
      // Create an object with the form data
      const formData = {
        password: password,
        confirmPassword: confirmPassword,
        resetKey: resetKey,
      };
      setLoading(true);
      // Submit the form data to the backend server
      resetPasswordFinish(formData)
        .then(() => {
          navigate('/dashboard');
        })
        .catch((error) => {
          setErrorSB([{title: 'Error resetting your password!', message: error.message}]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleChange = (event) => {
    if (event.target.value && event.target.value.length > 7) {
      setErrorSB([]);
    } else {
      setErrorSB([{title: 'Password error', message: 'Please make sure your password has 8 characters!'}]);
    }
  };

  const renderError = (title, message) => (
    <MDSnackbar color="error" icon="error" title={title} content={message} dateTime="" open={errorSB} close={closeError} bgWhite />
  );

  return (
    <BasicLayout image={bgImage}>
      {errorSB.length ? renderError(errorSB[0].title, errorSB[0].message) : null}
      <MDLoading open={loading} />
      <Card>
        <MDBox variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="success" mx={2} mt={-3} p={3} mb={1} textAlign="center">
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Password reset
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Complete resetting your password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleFormSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                name="password"
                pattern=".{8,}"
                title="8 characters minimum"
                required={true}
                onBlur={handleChange}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Confirm password"
                variant="standard"
                fullWidth
                name="confirmPassword"
                pattern=".{8,}"
                title="8 characters minimum"
                required={true}
                onBlur={handleChange}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                reset password
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{' '}
                <MDTypography component={Link} to="/sign-in" variant="button" color="info" fontWeight="medium" textGradient>
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ResetPasswordFinish;
