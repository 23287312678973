/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

import localforage from 'localforage'; 

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDLoading from 'components/MDLoading';
import MDSnackbar from 'components/MDSnackbar';

// Material Dashboard 2 React examples
import DashboardLayout from 'tongoComponents/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'tongoComponents/Navbars/DashboardNavbar';
import Footer from 'tongoComponents/Footer';

// react-router-dom components
import {getProfile, updateProfile} from '../../profile';
import {useState, useEffect} from 'react';

// Resume page components

import Experience from 'layouts/resume/components/Experience';
import Education from 'layouts/resume/components/Education';
import Biography from 'layouts/resume/components/Biography';
import ResumeTools from 'layouts/resume/components/ResumeTools';
import ResumeWizard from './ResumeWizard';

function Wizard() {
  const [resume, setResume] = useState({});
  const [user, setUser] = useState({});
  const [fetched, setFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({});
  const [errorSB, setErrorSB] = useState([]);
  const closeError = () => setErrorSB([]);


  const sectionIndexMap = {
    basicInfo: 0,
    education:1,
    experience: 2,
    custom:3
  };

  const handleMoveElementUp = async (sectionName, index) => {
    const sectionIndex = sectionIndexMap[sectionName];
    if (index > 0) {
      const updatedSection = [...resume.resumeParsed[sectionIndex][sectionName]];
      const [movedElement] = updatedSection.splice(index, 1);
      updatedSection.splice(index - 1, 0, movedElement);

      const updatedResume = {...resume};

      updatedResume.resumeParsed[sectionIndex][sectionName] = updatedSection;
      setResume(updatedResume);

      await update(updatedResume);
    }
  };
  const handleDeleteElement = async (sectionName, index) => {
    const sectionIndex = sectionIndexMap[sectionName];

    const updatedResume = {...resume};

    // Get the section to modify
    const section = updatedResume.resumeParsed[sectionIndex][sectionName];

    // Check if the index is valid
    if (index >= 0 && index < section.length) {
      // Remove the element at the specified index
      section.splice(index, 1);

      // Update the specific section in the copy
      updatedResume.resumeParsed[sectionIndex][sectionName] = section;

      // Update the résumé state with the copy
      setResume(updatedResume);

      await update(updatedResume);
    }
  };

  const handleMoveElementDown = async (sectionName, index) => {
    const sectionIndex = sectionIndexMap[sectionName];

    const updatedResume = {...resume};

    // Get the section to modify
    const section = updatedResume.resumeParsed[sectionIndex][sectionName];

    // Check if the index is valid and not at the last position
    if (index >= 0 && index < section.length - 1) {
      // Swap the element with the next one to move it down
      [section[index], section[index + 1]] = [section[index + 1], section[index]];

      // Update the specific section in the copy
      updatedResume.resumeParsed[sectionIndex][sectionName] = section;

      // Update the résumé state with the copy
      setResume(updatedResume);

      await update(updatedResume);
    }
  };

  const handleEditElement = async (sectionName, index, updatedElement) => {
    const sectionIndex = sectionIndexMap[sectionName];

    const updatedResume = {...resume};

    // Get the section to modify
    const section = updatedResume.resumeParsed[sectionIndex][sectionName];

    if (index === 0) {
      // If index is 0, it means we want to add a new element at the top
      section.unshift(updatedElement);
    } else if (index > 0 && index < section.length) {
      // Update the element at the specified index
      section[index] = updatedElement;
    }

    // Update the specific section in the copy
    updatedResume.resumeParsed[sectionIndex][sectionName] = section;

    // Update the résumé state with the copy
    setResume(updatedResume);

    await update(updatedResume);
  };

  function update(updatedResume) {
    updateProfile({resumeParsed: updatedResume.resumeParsed})
      .then((result) => {})
      .catch((err) => {
        setErrorSB([{title: 'Error saving your changes!', message: `Please review your info and try again! (${error})`}]);
      })
      .finally(() => {});
  }

  useEffect(() => {
    if (!fetched) {
      setLoading('Loading...');
      console.log("not fetched!!!!!!!!!!!!")
      getProfile({
        fields: {
          resumeParsed: true,
          avatar: true,
          salutation: true,
          firstName: true,
          lastName: true,
          middleName: true,
          title: true,
          bio: true,
          telephone: true,
          email: true,
          city: true,
          state: true,
          country: true,
          social: true,
          resumeKeywords: true,
          resumeUpdated: true,
          emailVerified: true,
        },
      })
        .then((r) => {
 
          console.log("fetched then r ",r)
          if (r?.resumeParsed) delete r.basicInfo; //remove imported basicInfo that was already copied in user object and won't be used to drive the site
          const basicInfoCopy = {
            salutation: r.salutation,
            avatar: r.avatar,
            firstName: r.firstName,
            lastName: r.lastName,
            middleName: r.middleName,
            title: r.title,
            bio: r.bio,
            telephone: r.telephone,
            email: r.email,
            city: r.city,
            state: r.state,
            country: r.country,
            social: r.social,
            resumeKeywords: r.resumeKeywords,
            resumeUpdated: r.resumeUpdated,
          };
          // Assign the new basicInfo object to the appropriate location
          r.basicInfo = basicInfoCopy;

          //update local storage user info
          /*localforage.getItem('user').then((user) => {
            localforage.setItem('user', {...user, ...r});
            setProfile(user.basicInfo)
            {user.resumeParsed? setResume(user.resumeParsed):setResume(res)}
            console.log("profile is:*********************************** ", profile )
          });*/
          setResume(r);

          setFetched(true);
        })
        .catch((error) => {
          if ([401, 404].includes(error.code)) {
            // Perform the redirect to the login screen
            window.location.href = '/sign-in';
          } else {
            setErrorSB([{title: 'Error retrieving your account!', message: `Please review your info and try again! (${error})`}]);
            // Handle other errors
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [fetched]);

  const renderError = (title, message) => (
    <MDSnackbar
      color="error"
      icon="error"
      title={title}
      content={message}
      dateTime=""
      open={Boolean(errorSB)}
      onClose={closeError}
      close={closeError}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDLoading text={loading === false ? '' : loading} open={Boolean(loading)} />
      {errorSB.length ? renderError(errorSB[0].title, errorSB[0].message) : null} <MDBox mb={2} />
      {console.log("resume",JSON.stringify(resume))} 
           {fetched&&resume?<ResumeWizard loadedResume={resume} />:"Loading..."}
      
      <Footer />
    </DashboardLayout>
  );
}

export default Wizard;
