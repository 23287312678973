/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/
// @mui material components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDLoading from 'components/MDLoading';
import MDSnackbar from 'components/MDSnackbar';
import axios from 'axios';
import { getProfile, updateProfile } from '../../profile'
// Material Dashboard 2 React example components
import DashboardLayout from 'tongoComponents/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'tongoComponents/Navbars/DashboardNavbar';
import Footer from 'tongoComponents/Footer';
import DataTableCollapsible from 'tongoComponents/Tables/DataTableCollapsible';
import InteractiveList from 'components/JobList';
import Searching from './searching';
// Data
import applicationsTableData from 'layouts/searches/data/searchListData';
import { getSearch, deleteSearchResult, shortListSearchResult } from '../../searches';
import MiniSearch from './minisearch';
import Swipe from './swipe';
import jobListData from 'layouts/shortList/data/jobListData';
import { getJob, deleteJob, archiveJob, generateCoverLetter } from '../../jobs';
import { getUserId } from 'api';
import { useLocation } from 'react-router-dom';

import { useEffect, useCallback,useState } from "react";


import PropTypes from 'prop-types';



function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        border: '1px solid',
        borderColor: (theme) =>
          theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
        p: 1,
        borderRadius: 2,
        textAlign: 'center',
        fontSize: '0.875rem',
        fontWeight: '700',
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};


function JobSearch() {
  const [appLoading, setAppLoading] = useState(true);
  const [jobQueueAppLoading, setJobQueueAppLoading] = useState(true);
  const [jobQueueAppTable, setJobQueueAppTable] = useState(null);
  const [appTable, setAppTable] = useState(null);
  const [messageSB, setMessageSB] = useState([]);

  const [jobIndexIdMap, setJobIndexIdMap] = useState({});
  const [jobsCache, setJobsCache] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [loading, setLoading] = useState(true);

  const [rows, setRows] = useState([]);
  const [searching, setSearching] = useState(false);
  const [carouselItems, setCarouselItems] = useState([{}]);
  const closeMessage = () => setMessageSB([]);
  const [searchesCache, setSearchesCache] = useState([]);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [jobIdIndexMap, setJobIdIndexMap] = useState({});
  const [reloadTrigger, setReloadTrigger] = useState({})
  const [profile, setProfile] = useState({})
  const [reloadToggle, setReloadToggle] = useState(false)
  const [errorSB, setErrorSB] = useState();
  let location = useLocation();

  const handleSearch = (search) => {

    setSearching(search)
  };
  const handleDataLoaded = (isLoading, tableData) => {
    setAppLoading(isLoading);
    setAppTable(tableData);
  };

  const handleShortlistDataLoaded = (isLoading, tableData, indexIdMap) => {
    setJobQueueAppLoading(isLoading);
    setJobQueueAppTable(tableData);
    setJobIndexIdMap(indexIdMap);

  };

  const toggleReload = () => {
    setReloadToggle(!reloadToggle)

  };
  applicationsTableData(handleDataLoaded);
  jobListData(handleShortlistDataLoaded);
  //handleSearch
  /*
  const getData = async () => {
    //right now axios calls over and over and over in perpetuity
    let params=null
    if (location?.state?.searchParams) {
     params=location.state.searchParams

    }
    const response = await axios.get('https://search.tongo.ai/search', {
      params: params
    })
  
    console.log("params", params)
    console.log("response.data ", response.data)
    console.log("messages ", [...messages, response.data])
    setMessages([...messages, response.data][0]);
    setRows([...rows, response.data])


};*/
//destructure the variable we were goiung to call "query" and only grab the parts we need (data and isloading)

  const fetchData = async () => {
    console.log("********************************************************** in fetch fetchData ")
    try {

        if (!fetched) {
          const jobsData = await getJob(
            null,
            '{"where":{"archived":{"neq":true}},"fields": {"id":true,"jobTitle":true,"companyName":true,"jobLocation":true,"coverLetterStatus":true,"created":true,"jobPostingUrl":true}}'
          );
       
          const data = await getSearch(null, '{"where":{"archived":{"neq":true}, "deleted":{"neq":true}}}');

          { jobsData ? setJobsCache([...jobsData]) : console.log("fuck") }
          { jobsData ? setRows([...jobsData]) : console.log("fuck") }

          { data ? setSearchesCache([...data]) : console.log("fuck") }
          setFetched(true);
          console.log(rows);
          console.log("rows^");

          let a = []
          for (let i = 0; i < data?.length; i++) {
            let newData = data[i]
            if (Array.isArray(newData.results)) {
              a=[...a, ...newData.results]
              setCarouselItems(a)
            }
          }

          let indexIdMap = []
          let idIndexMap = {}
          for (let i = 0; i < rows.length; i++) {
            indexIdMap[i] = rows[i].id
            idIndexMap[rows[i].id] = i

          }

          setJobIdIndexMap({ ...idIndexMap })

        setLoading(false);
      }
    console.log("fetch data ")

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


 
  useEffect(() => {
      console.log("############################## in fetch data ")
        try {
          getProfile()
            .then((profile) => {
              localforage.getItem('user').then((user) => {
                localforage.setItem('user', { ...user, ...r });
              });
              setProfile(profile);
            }).catch((error) => {
              if ([401, 404].includes(error.code)) {
                // Perform the redirect to the login screen
                window.location.href = '/sign-in';
              } else {
                setErrorSB([{ title: 'Error retrieving your account!', message: `Please review your info and try again! (${error})` }]);
                // Handle other errors
              }
            }).finally(() => {

              console.log("completed fetching user")
            });
        }catch (error) {


          console.log("there eas an error: ", error)
        }
          },[])

  useEffect(() => {
      console.log("############################## in fetch data ")
      const jobsData = getJob(
        null,
        '{"where":{"archived":{"neq":true}},"fields": {"id":true,"jobTitle":true,"companyName":true,"jobLocation":true,"coverLetterStatus":true,"created":true,"jobPostingUrl":true}}'
      ).then(
       (data)=> { data? setRows([...data]) : console.log("didnt update rows yet") }

      )
      console.log(JSON.stringify(jobsData))
      console.log("finished loading rows hopefully")
  }, [reloadToggle]);





  useEffect(()=>{},[reloadTrigger])




  useEffect(() => {


    //if (location?.state?.searchParams){
      
      //let params= location.state.searchParams
    //  getData(params)
  //}

    fetchData();


    handleSetReloadTrigger(reloadTrigger)

  }, []);

  const createIndexIdMap = (map) => {
    //console.log("map", map)
    setJobIndexIdMap(map)
  }

  const handleSelectJobByIndex = (jobIndex) => {
  }
  function handleUpdateCarousel(newValue) {
    setCarouselItems(newValue)


  }

  
  const handler = async (action, searchId, index,valueToMove=false) => {

    console.log("handler action: ", action, "searchId: ", searchId, "index: ", index, "valueToMove: ",valueToMove)
    try {
      switch (action) {

        case 'remove':
          deleteSearchResult(searchId, index);
          setMessageSB([{ title: 'Confirmation', message: 'Job removed from search result', color: 'success', icon: 'check' }]);
          console.log("removed from queue")
          break;
        case 'list':
          console.log("shortListSearchResult: "+valueToMove)
          shortListSearchResult(searchId, index);
          console.log("confirmation?")
          setMessageSB([{ title: 'Confirmation', message: 'Job moved to short-list', color: 'success', icon: 'check' }]);
          //setJobQueueAppLoading(true);
          console.log("valueToMove?")
          if(valueToMove){
            console.log("valueToMove: "+valueToMove)
            console.log("setting Rows...")
            //setRows([...rows, valueToMove])
            toggleReload()
          }
          console.log("handleShortlistDataLoaded?")
          //jobListData(handleShortlistDataLoaded);
          
          
          break;
        default:
          setMessageSB([{ title: 'Error', message: 'Job search action unknown', color: 'error', icon: 'error' }]);
      }
    } catch (error) {
      setMessageSB([{ title: 'Error', message: 'Job search action error, please try again', color: 'error', icon: 'error' }]);
      console.error('Error:', error.message);
    }
  };
  const style = {
    py: 0,
    width: '100%',
    minWidth: 500,
    borderRadius: 2,
    border: '1px solid',
    borderColor: 'divider',
    backgroundColor: 'background.paper',
    listStyleType: 'none',
    pt: 3,
    pb: 0
  };

  const renderMsg = (color, icon, title, message) => (
    <MDSnackbar
      color={color}
      icon={icon}
      title={title}
      content={message}
      dateTime=""
      autoHide={1000}
      open={Boolean(messageSB)}
      onClose={closeMessage}
      close={closeMessage}
      bgWhite
    />
  );

  function handleSetReloadTrigger(reloadTrigger) {
    setReloadTrigger(!reloadTrigger)
  }

  const handleText = (e) => {
    const inputMessage = e.target.value;
    setMessage(inputMessage);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/*searchResults?.map((searchResult,index)=>{
        return <div key={"itemkey_"+index}>searchResult </div>
      })*/}
      {//searching ? <Searching /> :
        <MDBox pt={0} ml={5} pb={3}>
          <Stack>
            <Stack direction="row" spacing={2}>
              <MiniSearch searching={searching} reloadTrigger={reloadTrigger} handleSetReloadTrigger={handleSetReloadTrigger} handleSearch={handleSearch} currentPage={"searches"} />
            </Stack>
            <Stack mb={5} direction="row" spacing={2}>

              <Swipe results={messages} reloadTrigger={reloadTrigger} actionHandler={handler} handleUpdateCarousel={handleUpdateCarousel} carouselItems={carouselItems} />

              <Box>
                {jobQueueAppLoading ? (
                  <MDLoading open={true} />
                ) : rows && rows.length !== 0 ? ( // Check if rows is not null and its length is not 0
                  <MDBox mx={0} px={2} pb={0} pt={0}>
                    <InteractiveList reloadTrigger={reloadTrigger} list={rows} accessor={"job"} onSwipePage={true} primaryText={"jobTitle"} secondaryText={"company"} handleSelectJobByIndex={handleSelectJobByIndex} />
                    {/*<DataTable table={jobQueueAppTable} isSorted={true} entriesPerPage={true} showTotalEntries={true} canSearch={true} onSwipePage={true} handleSelectJobByIndex={handleSelectJobByIndex} noEndBorder />*/}
                  </MDBox>
                ) : (
                  <MDBox mx={2} mt={3} py={3} px={2} pb={0} pt={0}>
                    <MDTypography variant="body2" color="warning">
                      No job list is available
                    </MDTypography>
                  </MDBox>
                )}

              </Box>

            </Stack>



            <Stack direction="row" spacing={2} >
              <Card>
                <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                  <MDTypography variant="h6" color="white">
                    Job Search History
                  </MDTypography>
                </MDBox>

                <MDBox mx={2} mt={3} py={3} px={2} pb={0} pt={0}>
                  <MuiLink href="search/new" rel="noreferrer">
                    <MDButton size="medium" color="info">
                      Add a Job Search
                    </MDButton>
                  </MuiLink>
                </MDBox>
                <MDBox pt={3}>
                  {appLoading ? (
                    <MDLoading open={true} />
                  ) : appTable?.rows && appTable.rows.length !== 0 ? ( // Check if rows is not null and its length is not 0
                    <DataTableCollapsible table={appTable} isSorted={true} entriesPerPage={true} showTotalEntries={true} noEndBorder actionHandler={handler} />
                  ) : (
                    <MDBox mx={2} mt={3} py={3} px={2} pb={0} pt={0}>
                      <MDTypography variant="body2" color="warning">
                        No job search is available
                      </MDTypography>
                    </MDBox>
                  )}
                </MDBox>
                {messageSB.length ? renderMsg(messageSB[0].color, messageSB[0].icon, messageSB[0].title, messageSB[0].message) : null}
              </Card>
            </Stack>
          </Stack>
        </MDBox >
      }
      <Footer />
    </DashboardLayout >
  );
}

export default JobSearch;
