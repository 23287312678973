import {fetcher, getUserId} from 'api';
import localforage from "localforage";
// Common fetch function for job tasks
async function jobTask(task, data, filter = null) {
  try {
    const userId = await getUserId();
    //await localforage.setItem(user.id,userId)
    console.log("job task: ",task, "data: ", data)
    switch (task) { 
      case 'create':
        return await fetcher(`jobs/`, 'POST', data);
      case 'get':
        const getUrl = data?.id ? `users/${userId}/job/${data.id}/` : `users/${userId}/jobs`;
        return await fetcher(`${getUrl}${filter ? `?filter=${filter}` : ''}`, 'GET');
      case 'update':
        if (typeof data === 'object') return await fetcher(`jobs/${data.id}/`, 'PATCH', data);
        else throw new Error('update object is invalid');
      case 'delete':
        console.log("job task: deleting calling fetcher")
        return await fetcher(`jobs/${data.id}/`, 'DELETE');
      case 'archive':
        return await fetcher(`jobs/${data.id}/`, 'PATCH', {archived: true});
      case 'retrieve':
        if (!data.url) throw new Error('Invalid url provided');
        return await fetcher(`scrape-job?jobUrl=${encodeURIComponent(data.url)}`, 'GET');
      case 'generateDescription':
        console.log("######################generate Description###################userId ",userId)
        return await fetcher(`users/${userId}/jobDescriptions/`, 'GET');
      case 'generate':
        return await fetcher(`jobs/${data.id}/generateCoverLetter/`, 'GET');
      default:
        throw new Error('Invalid task');
    }
  } catch (error) {
    if (error.code === 401) window.location.href = '/sign-in';
    return Promise.reject(error);
  }
}

// Exported functions for job tasks
export async function createJob(data) {
  if (!data) return;
  return await jobTask('create', data);
}

export async function getJob(id, filter) {
  try {
    const result = await jobTask('get', id ? {id: id} : null, filter);
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getJobDetails(url, filter) {
  if (!url) throw new Error('Invalid url provided');
  try {
    const result = await jobTask('retrieve', {url: url}, filter);
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateJob(data) {
  if (!data?.id) return;
  return await jobTask('update', data);
}

export async function deleteJob(id) {
  console.log("in jobs delete job")
  if (!id) throw new Error('id is required');
  return await jobTask('delete', {id: id});
}

export async function archiveJob(id) {
  if (!id) throw new Error('id is required');
  return await jobTask('archive', {id: id});
}

export async function generateCoverLetter(id) {
  if (!id) throw new Error('id is required');
  return await jobTask('generate', {id: id});
}

export async function generateJobDescription(data) {
  if (!data) throw new Error('id is required');
  return await jobTask('generateDescription', {data: data});
}

export async function getJobApplications(id) {
  return await getJob(
    null,
    '{"where":{"archived":{"neq":true},"applicationStatus":{"neq":null,"neq":""},"applicationStatus": {"exists": true}},"fields":{"id":true,"name":true,"companyName":true,"jobLocation":true,"applicationStatus":true,"created":true}}'
  );
}

export async function archiveApplication(id) {
  if (!id) throw new Error('id is required');
  return await jobTask('generate', {id: id});
}

export async function deleteApplication(id) {
  if (!id) throw new Error('id is required');
  return await jobTask('generate', {id: id});
}
