import { createApi, fetchBaseQuery } from  '@reduxjs/toolkit/query/react'
import { getSearch } from 'searches';
import localforage from 'localforage';
 /*search api */
const accessToken = localforage.getItem("token");
export const searchApi = createApi({
    reducerPath: 'searchApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://search.tongo.ai/search' }),
    endpoints: (builder) => ({
        getSearch: builder.query(
            
            ({
                query: (searchTerms) => {
                  const {what, city, state, userId} = searchTerms;
                  console.log('searchTerms: ', searchTerms);
                  
                  return {
                    url: '',
                    method: 'GET',
                    credentials: "same-origin", 
                    prepareHeaders: (headers) => {
                        
                        if (accessToken) {
                            headers.set("Authorization", `Bearer ${accessToken}`);
                            headers.set("Content-Type", "application/json");
                            headers.set( 'Accept-Encoding','gzip, deflate, br');
                        }
                        return headers;},



                    params: {
                        what,
                        city,
                        state,
                        userId
                      },
                  };
                },
               
           
        })),
    }),
});

export const { useLazyGetSearchQuery } = searchApi;