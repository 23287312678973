/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';
import Box, { BoxProps } from '@mui/material/Box';
// @mui material components
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Timeline context
import {useTimeline} from 'tongoComponents/Timeline/context';

// Custom styles for the TimelineItem
import timelineItem from 'tongoComponents/Timeline/TimelineItem/styles';

function TimelineItem({color, icon, title, subtitleList, dateTime, description, url, lastItem}) {
  const isDark = useTimeline();

  return (
    <MDBox position="relative" mb={3} sx={(theme) => timelineItem(theme, {lastItem, isDark})}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor={color}
        color="white"
        width="2rem"
        height="2rem"
        borderRadius="50%"
        position="absolute"
        top="8%"
        left="2px"
        zIndex={2}
        sx={{fontSize: ({typography: {size}}) => size.sm}}
      >
        <Icon fontSize="inherit">{icon}</Icon>
      </MDBox>
      <MDBox ml={5.75} pt={description ? 0.7 : 0.5} lineHeight={0}>
        <MDTypography variant="button" fontWeight="medium" color={isDark ? 'white' : 'dark'}>
          {title}
        </MDTypography>
        <MDBox mt={0.5}>
          <MDTypography variant="caption" color={isDark ? 'secondary' : 'text'}>
          {subtitleList?subtitleList.map((item,index)=>{
            return(
              <Box key={index} sx={{ display: 'grid', gridTemplateRows: 'repeat(2, 1fr)' }}>
              {console.log("item ",item)}
              {item}
            </Box>)}):""}
          </MDTypography>
        </MDBox>
        <MDBox mt={1} mb={.5}>
          {description && !url ? (
            typeof description === 'string' ? (
              <MDTypography variant="button" color={isDark ? 'white' : 'dark'}>
                {description}
              </MDTypography>
            ) : (
              <List
              // sx={{
              //   listStyleType: 'square',
              //   pl: 1,
              //   '& .MuiListItem-root': {
              //     display: 'list-item',
              //   },
              // }}
              >
                {description.map((item, index) => (
                  <ListItem key={index}>
                    <MDTypography variant="button" color={isDark ? 'white' : 'dark'}>
                      {item}
                    </MDTypography>
                  </ListItem>
                ))}
              </List>
            )
          ) : null}
        </MDBox>
        <MDBox mt={1} mb={1.5}>
          {url ? (
            <MDTypography component="a" href="{url}" variant="button" fontWeight="bold" color="info" textGradient>
              {description}
            </MDTypography>
          ) : null}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of TimelineItem
TimelineItem.defaultProps = {
  color: 'info',
  lastItem: false,
  description: '',
};

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark', 'light']),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitleList: PropTypes.oneOfType([
    PropTypes.string, // Description as a single string
    PropTypes.arrayOf(PropTypes.string), // Description as an array of strings
  ]),
  dateTime: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string, // Description as a single string
    PropTypes.arrayOf(PropTypes.string), // Description as an array of strings
  ]),
  url: PropTypes.string,
  lastItem: PropTypes.bool,
};

export default TimelineItem;
