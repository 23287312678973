/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

const buttonBase = {
  defaultProps: {
    disableRipple: false,
  },
};

export default buttonBase;
