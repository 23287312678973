/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// Material Dashboard 2 React Base Styles
import colors from 'assets/theme-dark/base/colors';

const {white} = colors;
const list = {
  styleOverrides: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
      listStyleType: 'square',
      pl: 3,
      '& .MuiListItem-root': {
        display: 'list-item',
        color: white.main,
      },
    },
  },
};

export default list;
