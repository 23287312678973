/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 React example components
import TimelineItem from 'tongoComponents/Timeline/TimelineItem';
import TimelineList from 'tongoComponents/Timeline/TimelineList';
import ActionMenu from 'tongoComponents/ActionMenu';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

function Education({ education, moveElementUp, moveElementDown, deleteElement, editElement }) {
  const module = 'education';
  const emptyEducation={
    "institution": "", 
    "degree": [
        { "degreeName": "", 
        "fieldOfStudy": "", 
        "graduationMonthYear": null, 
        "inProgress": false, 
        "bulletPoints": "" }
      ]
  }
  const tasks = [
    {
      label: 'Edit',
      icon: 'edit_note',
      action: (module, id, updatedElement) => {
        editElement(module, id, updatedElement);
      },
      confirmation: false,
      confirmationMessage: '',
    },
    {
      label: 'Delete',
      icon: 'delete',
      action: (id) => {
        deleteElement(module, id);
      },
      confirmation: true,
      confirmationMessage: `Are you sure you want to delete this ${module}? This action cannot be undone.`,
    },
    {
      label: 'Move up',
      icon: 'move_up',
      action: (id) => {
        moveElementUp(module, id);
      },
      confirmation: false,
      confirmationMessage: '',
    },
    {
      label: 'Move down',
      icon: 'move_down',
      action: (id) => {
        moveElementDown(module, id);
      },
      confirmation: false,
      confirmationMessage: '',
    },
  ];

  const Addtask = [
    {
      label: 'Add new',
      icon: 'add',
      action: (module, id, newEducation) => {
        editElement(module, (id = -1), newEducation);
      },
      confirmation: false,
      confirmationMessage: '',
    },
  ];
  const renderEducation = (education, index, totalEducation) => {
    if (!education) {
      return null; // Return null if education is not defined
    }

    const isLastItem = index === totalEducation - 1;



    let tempArray = []
    if (education.degree) {
      for (let i = 0; i < education.degree.length; i++) {
        let str=""
        if (education.degree[i].inProgress) {
          let strsuffix=  education.degree[i].graduationMonthYear?"Expected Graduation: "+String(education.degree[i].graduationMonthYear):"";
          str = "In Progress "+strsuffix

          //first check to see if it says the degree is in progress, then if there is a graduation year assume it is the expected graduation date
        } else {
          str = education.degree[i].graduationMonthYear?String(education.degree[i].graduationMonthYear):" ";
        }
        tempArray.push(String(education.degree[i].degreeName) +" "+ str)
          

        }
    }
    const positionArr = [...tempArray]
    console.log("positionArr: ", positionArr)
    return (
      <MDBox key={index} display="flex" justifyContent="space-between" alignItems="center">
        <TimelineItem
          key={index}
          color="success"
          icon="school"
          title={`${education.institution}`}
          subtitleList={tempArray}
          description={""}
          lastItem={isLastItem} // Pass lastItem prop if it's the last item
        />

        <ActionMenu id={index} onActionCompleted={() => { }} tasks={tasks} formObject={{ title: module, obj: education }} />
      </MDBox>
    );
  };

  return (
    <Card sx={{ height: '100%' }}>
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Education
        </MDTypography>
        
        <ActionMenu id={0} onActionCompleted={() => {}} tasks={Addtask} formObject={{title: module, obj: emptyEducation }} />
      </MDBox>
      <TimelineList title="">
        {education && education.length > 0
          ? education.map((e, index) => {
            return renderEducation(e, index, education.length);
          })
          : ''}
      </TimelineList>
    </Card>
  );
}

export default Education;

Education.defaultProps = {
  graduationMonth: '',
  gpa: '',
};

Education.propTypes = {
  education: PropTypes.arrayOf(
    PropTypes.shape({
      institution: PropTypes.string.isRequired,
      degree: PropTypes.arrayOf(PropTypes.string).isRequired,
      graduationYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      graduationMonth: PropTypes.string,
      majors: PropTypes.arrayOf(PropTypes.string),
      gpa: PropTypes.string,
    })
  ).isRequired,
  moveElementUp: PropTypes.func,
  moveElementDown: PropTypes.func,
  deleteElement: PropTypes.func,
  editElement: PropTypes.func,
};

const emptyEducation = {
  institution: '',
  degree: ['My degree'],
  graduationYear: new Date().getFullYear(),
  graduationMonth: 'June',
  majors: ['My 1st major'],
  gpa: '',
};

