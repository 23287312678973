/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// react-router-dom components
import {useParams, useNavigate} from 'react-router-dom';
import {useState, useRef, useEffect} from 'react';

import {Editor} from '@tinymce/tinymce-react';
import dayjs from 'dayjs';

import {createJob, updateJob, getJob, getJobDetails} from '../../../jobs';
import {getProfile} from '../../../profile';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Icon from '@mui/material/Icon';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import MenuItem from '@mui/material/MenuItem';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDSnackbar from 'components/MDSnackbar';
import MDLoading from 'components/MDLoading';

// Material Dashboard 2 React base styles
import breakpoints from 'assets/theme/base/breakpoints';

// Material Dashboard 2 React example components
import DashboardLayout from 'tongoComponents/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'tongoComponents/Navbars/DashboardNavbar';
import Footer from 'tongoComponents/Footer';
import stateOptions from 'tongoComponents/Misc/states';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
// Material Dashboard 2 React context
import {useMaterialUIController} from 'context';

function EditJobApplication() {
  const queryParams = useParams();
  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const {darkMode} = controller;

  const [job, setJob] = useState({
    jobType: 'full-time',
    coverLetterStatus: 'pending',
    name: null,
    jobPostingUrl: null,
    jobLocation: {
      city: null,
      state: null,
      zipCode: null,
    },
    companyName: null,
    companyWebsite: null,
    jobSalary: null,
    jobCategory: null,
    jobType: 'full-time',
    tags: null,
    coverLetterStatus: 'pending',
  });

  const [loading, setLoading] = useState(false);
  const handleToggle = () => {
    setLoading(!loading);
  };

  const editorRef = useRef(null);

  const [master, setMaster] = useState(job?.useMainResume ?? true);
  const [resume, setResume] = useState(job?.customResume ?? '');

  const [requestingAccommodation, setrequestingAccommodation] = useState(job?.requestingAccommodation ?? false);
  const [requestedAccommodation, setrequestedAccomodation] = useState(job?.requestedAccommodation ?? '');

  const [messageSB, setMessageSB] = useState([]);
  const closeMessage = () => setMessageSB([]);

  const [tabsOrientation, setTabsOrientation] = useState('vertical');
  const [tabValue, setTabValue] = useState(0);

  const [deadline, setDeadline] = useState(dayjs());
  const [jobUrl, setJobURL] = useState();
  const [retrieve, setRetrieve] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    job.useMainResume = master;
    job.customResume = resume;
    job.requestingAccommodation = requestingAccommodation;
    job.requestedAccommodation = requestedAccommodation;

    if (job.description == '') {
      setMessageSB([
        {
          color: 'error',
          icon: 'error',
          title: 'Job description is a required field',
          message: 'Please add a job description and try again!',
        },
      ]);
      return;
    }

    try {
      setLoading(true);
      let successNavigate;
      if (job.id) {
        await updateJob(job);
        successNavigate = `/short-list/${job.id}`;
      } else {
        let newJob = await createJob(job);
        successNavigate = `/short-list/${newJob.id}`;
      }

      setMessageSB([
        {
          color: 'success',
          icon: 'check',
          title: `Job was ${job.id ? 'updated' : 'created'}!`,
          message: 'Redirecting to job...',
        },
      ]);

      setTimeout(() => {
        navigate(successNavigate);
      }, 2000);
    } catch (error) {
      if (error.status === 401) {
        await profile.signoffProfile();
        navigate('/sign-in');
      }
      setMessageSB([
        {
          color: 'error',
          icon: 'error',
          title: `Error ${job.id ? 'updating' : 'creating'} job!`,
          message: 'Please review your info and try again!',
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm ? setTabsOrientation('vertical') : setTabsOrientation('horizontal');
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener('resize', handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  //handle form editing for controlled inputs
  const handleChange = (event) => {
    const {name, value} = event.target;

    if (name.includes('jobLocation.')) {
      const nestedPropertyName = name.split('.')[1];
      setJob((job) => ({
        ...job,
        jobLocation: {
          ...job.jobLocation,
          [nestedPropertyName]: value,
        },
      }));
    } else {
      setJob((job) => ({
        ...job,
        [name]: value,
      }));
    }
  };

  //handle form editing for controlled inputs
  const handleEditorChange = (content) => {
    setJob({...job, description: content});
  };

  //handle date change for controlled inputs
  const handleDateChange = (event) => {
    setDeadline(event.$d.toISOString());
    setJob({...job, applicationDeadline: event.$d.toISOString()});
  };

  useEffect(() => {
    if (queryParams?.jobId && !job?.id)
      getJob(queryParams.jobId)
        .then((job) => {
          setJob(job);
          setDeadline(dayjs(job.applicationDeadline));
        })
        // .catch((error) => {
        //   // if (error.code === 401) {
        //   // Perform the redirect to the login screen
        //   // window.location.href = '/sign-in';
        //   // } else {
        //   console.error(error);
        //   // Handle other errors
        //   // }
        // })
        .finally(() => {
          setLoading(false);
        });
  }, []);

  useEffect(() => {
    if (queryParams?.jobId && !job?.id)
      getJob(queryParams.jobId)
        .then((job) => {
          setJob(job);
          setDeadline(dayjs(job.applicationDeadline));
        })
        // .catch((error) => {
        //   // if (error.code === 401) {
        //   // Perform the redirect to the login screen
        //   // window.location.href = '/sign-in';
        //   // } else {
        //   console.error(error);
        //   // Handle other errors
        //   // }
        // })
        .finally(() => {
          setLoading(false);
        });
  }, []);
  const handleURLChange = (event) => {
    const {name, value} = event.target;

    if (value.includes('indeed.com')) {
      setJobURL(value);
      setRetrieve(true);
    }
    handleChange(event);
  };

  useEffect(() => {
    if (retrieve && !(queryParams?.jobId && job?.id)) {
      setLoading(true);
      getJobDetails(jobUrl)
        .then((j) => {
          setJob({...job, ...j});
        })
        .catch((error) => {
          // console.error(error);
          setMessageSB([
            {color: 'error', icon: 'error', title: 'Could not retrieve job info', message: 'Please copy and paste information from the web page.'},
          ]);
        })
        .finally(() => {
          setLoading(false);
          setRetrieve(false);
        });
    } else {
      //retrieve defaults
      getProfile({fields: {city: true, state: true}})
        .then((results) => {
          setJob((prevJob) => ({
            ...prevJob,
            jobLocation: {
              ...prevJob.jobLocation,
              city: results.city || prevJob.city,
              state: results.state || prevJob.state,
            },
          }));
        })
        .catch(() => {})
        .finally(() => {});
    }
  }, [jobUrl]);

  const renderMsg = (color, icon, title, message) => (
    <MDSnackbar
      color={color}
      icon={icon}
      title={title}
      content={message}
      dateTime=""
      open={Boolean(messageSB)}
      onClose={closeMessage}
      close={closeMessage}
      bgWhite
    />
  );

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        {messageSB.length ? renderMsg(messageSB[0].color, messageSB[0].icon, messageSB[0].title, messageSB[0].message) : null}
        <MDLoading text="Trying to retrieve job data..." open={loading} onClose={() => {}} />
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {/* <MDBox variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="success" mx={2} mt={-3} p={3} mb={1} textAlign="center"> */}
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  {job?.id ? 'Edit Short Listed Job' : 'Add Job to Short List'}
                </MDTypography>
                <MDTypography display="block" variant="button" color="white" my={1}>
                  {!job?.id ? (
                    <>
                      Add a new job either by manually entering information or copying and pasting details in the form.
                      <br />
                      You can also simplify the process by importing jobs through a customized job search and selecting the ones you want to track using Tongo.
                    </>
                  ) : (
                    <>
                      Edit your current job here.
                      <br /> Please note that changing the information contained here may require a new cover letter.
                    </>
                  )}
                </MDTypography>
              </MDBox>
              <Grid item mt={5} mr={3} xs={12} md={6} lg={4} sx={{ml: 'auto'}}>
                <AppBar position="static">
                  <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                    <Tab
                      value={0}
                      label="Basic Information"
                      icon={
                        <Icon fontSize="small" sx={{mt: -0.25}}>
                          description
                        </Icon>
                      }
                    />
                    <Tab
                      value={1}
                      label="Job Details"
                      icon={
                        <Icon fontSize="small" sx={{mt: -0.25}}>
                          business_center
                        </Icon>
                      }
                    />
                  </Tabs>
                </AppBar>
              </Grid>
              <FormGroup>
                <MDBox pt={0} pb={3} px={3}>
                  <MDBox component="form" role="form" onSubmit={handleFormSubmit}>
                  
                    {tabValue === 0 && (
                      <>
                        <MDBox mb={2}>
                          <MDInput
                            type={'url'}
                            label="Job posting URL"
                            variant="standard"
                            fullWidth
                            name="jobPostingUrl"
                            required={true}
                            placeholder="https://www.indeed.com/"
                            aria-label="Job posting URL"
                            value={job?.jobPostingUrl ?? ''}
                            onChange={handleURLChange}
                            autoComplete="off"
                            shrink={'true'}
                          />
                        </MDBox>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Job name"
                            variant="standard"
                            fullWidth
                            name="name"
                            required={true}
                            placeholder="Deputy State Public Defender"
                            aria-label="Job name"
                            value={job?.jobTitle ?? ''}
                            onChange={handleChange}
                            autoComplete="off"
                            shrink={'true'}
                          />
                        </MDBox>
                        <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                          Job description *
                        </MDTypography>
                        <MDBox mb={2} lineHeight={0}>
 
                      
                          <Editor
                            onInit={(evt, editor) => {
                              editorRef.current = editor;
                              // editor.setContent(job?.description ?? '');
                            }}
                            value={job?.description ?? ''}
                            onEditorChange={handleEditorChange}
                            init={{
                              skin: darkMode ? 'oxide-dark' : 'oxide-dark',
                              content_css: darkMode ? 'default' : 'dark',
                              height: 400,
                              menubar: false,
                              selector: '#tinymce',
                              branding: false,
                              selector: 'textarea',
                              placeholder: 'Full job description',
                              plugins: ['advlist', 'lists', 'charmap', 'preview', 'fullscreen', 'wordcount'],
                              toolbar: 'preview undo redo',
                              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                            }}
                          />
                        </MDBox>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Job location city"
                            variant="standard"
                            name="jobLocation.city"
                            required={true}
                            placeholder="Seattle, WA"
                            aria-label="Job location"
                            value={job?.jobLocation?.city ?? ''}
                            onChange={handleChange}
                            shrink={'true'}
                            autoComplete="off"
                          />
                          &nbsp;&nbsp;&nbsp;
                          <MDInput
                            select
                            variant="standard"
                            label="State"
                            aria-label="State"
                            value={job?.jobLocation?.state || ''}
                            name="jobLocation.state"
                            onChange={handleChange}
                            shrink={'true'}
                            autoComplete="off"
                            verticalalign="text-bottom"
                            style={{verticalAlign: 'text-bottom', marginTop: 'auto', alignItems: 'left'}} // Align to the bottom
                          >
                            {stateOptions.map((stateOption) => (
                              <MenuItem key={stateOption.abbreviation} value={stateOption.abbreviation}>
                                {stateOption.name}
                              </MenuItem>
                            ))}
                          </MDInput>
                        </MDBox>
                        <MDBox display="flex" alignItems="center" ml={-1}>
                          <Checkbox
                            name="useMainResume"
                            onChange={(e) => {
                              setMaster(!master);
                              handleChange(e);
                            }}
                            checked={master}
                          />
                          <MDTypography variant="button" fontWeight="regular" color="text" sx={{cursor: 'pointer', userSelect: 'none', ml: -1}}>
                            Use my normal résumé to generate the cover letter
                          </MDTypography>
                        </MDBox>
                        <div hidden={master}>
                          <MDBox mb={2}>
                            <MDInput
                              label="Custom résumé to use for cover letter"
                              variant="standard"
                              fullWidth
                              name="customResume"
                              multiline
                              rows={10}
                              hidden={master}
                              value={job?.customResume ?? ' '}
                              placeholder="Custom résumé to use for cover letter generation"
                              aria-label="Custom résumé to use for cover letter"
                              onChange={handleChange}
                            />
                          </MDBox>
                        </div>
                        <MDBox display="flex" alignItems="center" ml={-1}>
                          <Checkbox
                            name="requestingAccommodation"
                            onChange={(e) => {
                              setrequestingAccommodation(!requestingAccommodation);
                              handleChange(e);
                            }}
                            checked={requestingAccommodation}
                          />
                          <MDTypography variant="button" fontWeight="regular" color="text" sx={{cursor: 'pointer', userSelect: 'none', ml: -1}}>
                            When applying to this job, I will require special accomodations
                          </MDTypography>
                        </MDBox>
                        <div hidden={!requestingAccommodation}>
                          <MDBox mb={2}>
                            <MDInput
                              label="Requested accomodation"
                              variant="standard"
                              fullWidth
                              name="requestedAccommodation"
                              multiline
                              rows={10}
                              hidden={requestingAccommodation}
                              value={requestedAccommodation}
                              placeholder="List your accomodations, if left blank we will use those provided in your account if any."
                              aria-label="Requested accomodation"
                              onChange={(e) => {
                                setrequestedAccomodation(e.target.value);
                                handleChange(e);
                              }}
                            />
                          </MDBox>
                        </div>
                      </>
                    )}
                    {tabValue === 1 && (
                      <>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Employer name"
                            variant="standard"
                            fullWidth
                            name="companyName"
                            required={false}
                            placeholder="ACME, Inc."
                            aria-label="Employer name"
                            value={job?.companyName ?? ''}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </MDBox>
                        <MDBox mb={2}>
                          <MDInput
                            type={'url'}
                            label="Employer's website"
                            variant="standard"
                            fullWidth
                            name="companyWebsite"
                            required={false}
                            placeholder="https://www.acme.com"
                            aria-label="Employer's website"
                            value={job?.companyWebsite ?? ''}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </MDBox>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Salary or salary range/Compensation"
                            variant="standard"
                            fullWidth
                            name="jobSalary"
                            required={false}
                            placeholder="$75,000 to $80,000"
                            aria-label="Salary or salary range/Compensation"
                            value={job?.jobSalary ?? ''}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </MDBox>
                        <MDBox mb={2}>
                          <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                            Job position type
                          </MDTypography>
                          <MDBox ml={3}>
                            <RadioGroup row aria-labelledby="job postion type" value={job?.jobType ?? 'full-time'} name="jobType" onChange={handleChange}>
                              <FormControlLabel
                                value="full-time"
                                control={<Radio />}
                                fontWeight="regular"
                                color="text"
                                label={
                                  <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                                    Full-time
                                  </MDTypography>
                                }
                              />
                              <FormControlLabel
                                value="part-time"
                                control={<Radio />}
                                fontWeight="regular"
                                color="text"
                                label={
                                  <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                                    part-time
                                  </MDTypography>
                                }
                              />
                              <FormControlLabel
                                value="contract"
                                control={<Radio />}
                                fontWeight="regular"
                                color="text"
                                label={
                                  <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                                    Contract
                                  </MDTypography>
                                }
                              />
                              <FormControlLabel
                                value="internship"
                                control={<Radio />}
                                fontWeight="regular"
                                color="text"
                                label={
                                  <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                                    Internship
                                  </MDTypography>
                                }
                              />
                              <FormControlLabel
                                value="volunteer"
                                control={<Radio />}
                                fontWeight="regular"
                                color="text"
                                label={
                                  <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                                    Volunteer
                                  </MDTypography>
                                }
                              />
                              <FormControlLabel
                                value="other"
                                control={<Radio />}
                                fontWeight="regular"
                                color="text"
                                label={
                                  <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                                    Other
                                  </MDTypography>
                                }
                              />
                            </RadioGroup>
                          </MDBox>
                        </MDBox>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Job category/Industry"
                            variant="standard"
                            fullWidth
                            name="jobCategory"
                            required={false}
                            placeholder="Legal"
                            aria-label="Job category/Industry"
                            value={job?.jobCategory ?? ''}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </MDBox>
                        <MDBox mb={2}>
                          <MDInput
                            type="text"
                            label="Job Tags/Keywords"
                            variant="standard"
                            fullWidth
                            name="tags"
                            required={false}
                            placeholder="Legal"
                            aria-label="Job Tags/Keywords"
                            value={job?.tags ?? ''}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </MDBox>
                        <MDBox mb={2}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={deadline}
                              label="Application deadline"
                              variant="standard"
                              name="applicationDeadline"
                              required={false}
                              aria-label="Application deadline"
                              onChange={handleDateChange}
                            />
                          </LocalizationProvider>
                        </MDBox>
                      </>
                    )}
                    <MDBox display="flex" alignItems="left" mt={5}>
                      <MDBox mr={5}>
                        <MDButton type="submit" variant="gradient" color="info" mr={2}>
                          {job?.id ? 'Update this job' : 'Add this job'}
                        </MDButton>
                      </MDBox>
                      <MDBox>
                        {job?.id && (
                          <MDButton onClick={() => navigate(-1)} variant="gradient" color="info" mr={2}>
                            Cancel
                          </MDButton>
                        )}
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </FormGroup>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditJobApplication;
