/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

import {useState} from 'react';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDLoading from 'components/MDLoading';

// Material Dashboard 2 React example components
import DashboardLayout from 'tongoComponents/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'tongoComponents/Navbars/DashboardNavbar';
import Footer from 'tongoComponents/Footer';
import DataTable from 'tongoComponents/Tables/DataTable';

// Data
import appliedListData from 'layouts/applications/data/appliedListData';

function ApplicationsList() {
  const [appLoading, setAppLoading] = useState(true);
  const [appTable, setAppTable] = useState(null);
  const handleDataLoaded = (isLoading, tableData) => {
    setAppLoading(isLoading);
    setAppTable(tableData);
  };

  appliedListData(handleDataLoaded);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                <MDTypography variant="h6" color="white">
                  Job Application List
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {appLoading ? (
                  <MDLoading open={true} />
                ) : appTable?.rows && appTable.rows.length !== 0 ? ( // Check if rows is not null and its length is not 0
                  <DataTable table={appTable} isSorted={true} entriesPerPage={true} showTotalEntries={true} noEndBorder />
                ) : (
                  <MDBox mx={2} mt={3} py={3} px={2} pb={0} pt={0}>
                    <MDTypography variant="body2" color="warning">
                      No job application is available
                    </MDTypography>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ApplicationsList;
