/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import {createContext, useEffect, useContext, useReducer, useMemo} from 'react';
import localforage from 'localforage';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Material Dashboard 2 React main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = 'MaterialUIContext';

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({children}) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: 'info',
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: 'ltr',
    layout: 'dashboard',
    darkMode: false,
  };

  // Material Dashboard 2 React reducer
  function reducer(state, action) {
    switch (action.type) {
      case 'initialize':
        return {
          ...state,
          miniSidenav: action.data.miniSidenav,
          transparentSidenav: action.data.transparentSidenav,
          whiteSidenav: action.data.whiteSidenav,
          sidenavColor: action.data.sidenavColor,
          transparentNavbar: action.data.transparentNavbar,
          fixedNavbar: action.data.fixedNavbar,
          openConfigurator: action.data.openConfigurator,
          direction: action.data.direction,
          layout: action.data.layout,
          darkMode: action.data.darkMode,
        };
      case 'MINI_SIDENAV': {
        setValueInLocalStorage('miniSidenav', action.value);
        return {...state, miniSidenav: action.value};
      }
      case 'TRANSPARENT_SIDENAV': {
        setValueInLocalStorage('transparentSidenav', action.value);
        return {...state, transparentSidenav: action.value};
      }
      case 'WHITE_SIDENAV': {
        setValueInLocalStorage('whiteSidenav', action.value);
        return {...state, whiteSidenav: action.value};
      }
      case 'SIDENAV_COLOR': {
        setValueInLocalStorage('sidenavColor', action.value);
        return {...state, sidenavColor: action.value};
      }
      case 'TRANSPARENT_NAVBAR': {
        setValueInLocalStorage('transparentNavbar', action.value);
        return {...state, transparentNavbar: action.value};
      }
      case 'FIXED_NAVBAR': {
        setValueInLocalStorage('fixedNavbar', action.value);
        return {...state, fixedNavbar: action.value};
      }
      case 'OPEN_CONFIGURATOR': {
        setValueInLocalStorage('openConfigurator', action.value);
        return {...state, openConfigurator: action.value};
      }
      case 'DIRECTION': {
        setValueInLocalStorage('direction', action.value);
        return {...state, direction: action.value};
      }
      case 'LAYOUT': {
        setValueInLocalStorage('layout', action.value);
        return {...state, layout: action.value};
      }
      case 'DARKMODE': {
        setValueInLocalStorage('darkMode', action.value);
        return {...state, darkMode: action.value};
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
  }

  // Use useReducer to manage state and initialize it
  const [controller, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    // Fetch data from local storage and initialize state
    const fetchDataAndInitializeState = async () => {
      const miniSidenavValue = await getValueFromLocalStorage('miniSidenav', initialState.miniSidenav);
      const transparentSidenavValue = await getValueFromLocalStorage('transparentSidenav', initialState.transparentSidenav);
      const whiteSidenavValue = await getValueFromLocalStorage('whiteSidenav', initialState.whiteSidenav);
      const sidenavColorValue = await getValueFromLocalStorage('sidenavColor', initialState.sidenavColor);
      const transparentNavbarValue = await getValueFromLocalStorage('transparentNavbar', initialState.transparentNavbar);
      const fixedNavbarValue = await getValueFromLocalStorage('fixedNavbar', initialState.fixedNavbar);
      const openConfiguratorValue = await getValueFromLocalStorage('openConfigurator', initialState.openConfigurator);
      const directionValue = await getValueFromLocalStorage('direction', initialState.direction);
      const layoutValue = await getValueFromLocalStorage('layout', initialState.layout);
      const darkModeValue = await getValueFromLocalStorage('darkMode', initialState.darkMode);

      dispatch({
        type: 'initialize',
        data: {
          miniSidenav: miniSidenavValue,
          transparentSidenav: transparentSidenavValue,
          whiteSidenav: whiteSidenavValue,
          sidenavColor: sidenavColorValue,
          transparentNavbar: transparentNavbarValue,
          fixedNavbar: fixedNavbarValue,
          openConfigurator: openConfiguratorValue,
          direction: directionValue,
          layout: layoutValue,
          darkMode: darkModeValue,
        },
      });
    };
    // Call the function to fetch data and initialize state
    fetchDataAndInitializeState();
  }, []);

  // Use useReducer with the state from local storage
  // const [controller, dispatch] = useReducer(reducer, stateFromLocalStorage);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error('useMaterialUIController should be used inside the MaterialUIControllerProvider.');
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({type: 'MINI_SIDENAV', value});
const setTransparentSidenav = (dispatch, value) => dispatch({type: 'TRANSPARENT_SIDENAV', value});
const setWhiteSidenav = (dispatch, value) => dispatch({type: 'WHITE_SIDENAV', value});
const setSidenavColor = (dispatch, value) => dispatch({type: 'SIDENAV_COLOR', value});
const setTransparentNavbar = (dispatch, value) => dispatch({type: 'TRANSPARENT_NAVBAR', value});
const setFixedNavbar = (dispatch, value) => dispatch({type: 'FIXED_NAVBAR', value});
const setOpenConfigurator = (dispatch, value) => dispatch({type: 'OPEN_CONFIGURATOR', value});
const setDirection = (dispatch, value) => dispatch({type: 'DIRECTION', value});
const setLayout = (dispatch, value) => dispatch({type: 'LAYOUT', value});
const setDarkMode = (dispatch, value) => dispatch({type: 'DARKMODE', value});

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
};

// Utility function to get a value from local storage or use the default
const getValueFromLocalStorage = async (key, defaultValue) => {
  try {
    const value = await localforage.getItem(key);
    return value !== null ? value : defaultValue;
  } catch (error) {
    // console.error(`Error getting ${key} from local storage:`, error);
    return defaultValue;
  }
};

// Utility function to set a value in local storage
function setValueInLocalStorage(key, value) {
  return localforage
    .setItem(key, value)
    .then(() => {})
    .catch((error) => {
      // console.error(`Error setting ${key} in local storage:`, error);
    });
}
