/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import styles from '../Wizard.module.css';
import localforage from 'localforage';
import { v4 as uuid } from 'uuid'
import { getProfile, generateResumeKeywords, updateProfile, uploadResume } from '../../../profile';
import stateOptions from '../../../tongoComponents/Misc/states';
// @mui material components
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TitleIcon from '@mui/icons-material/Title';
import IconButton from '@mui/material/IconButton';
import ApartmentIcon from '@mui/icons-material/Apartment';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { styled } from '@mui/material/styles';
import xtype from 'xtypejs';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { getJob, deleteJob, archiveJob, generateJobDescription, generateCoverLetter } from '../../../jobs';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';


import StepLabel from '@mui/material/StepLabel';
// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';


import Stepper from '@mui/material/Stepper';
import TextField from '@mui/material/TextField';
import Step from '@mui/material/Step';
import Container from '@mui/material/Container';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDSnackbar from 'components/MDSnackbar';
import MDLoading from 'components/MDLoading';
import MDBadge from 'components/MDBadge';


import { useState, Fragment, useEffect } from 'react';



// Resume page components
import MDProgress from "components/MDProgress";
import Experience from '../components/Experience';
import Education from '../components/Education';
import CustomSection from '../components/CustomSection';
import Biography from '../components/Biography';
import ResumeTools from '../components/ResumeTools';
// Material Dashboard 2 React context
import { useMaterialUIController } from 'context';
import MDInput from 'components/MDInput';
import { func } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel'; null
import { fetcher, getUserId } from 'api';
import { MenuItem, Select } from '@mui/material';

import SchoolIcon from '@mui/icons-material/School';
const steps = [
  'Basic Info',
  'Experience',
  'Education',
  'Custom Sections',
];




function ResumeWizard({ keywords, loadedResume, resumeUpdated, reload }) {
  const [profile, setProfile] = useState({});
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [progress, setProgress] = useState(0);
  const [resumeError, setResumeError] = useState(false);
  const [messageSB, setMessageSB] = useState([]);
  const closeMessage = () => setMessageSB([]);

  const [resumeDate, setResumeDate] = useState(resumeUpdated);
  const [resumeKeywords, setResumeKeywords] = useState(keywords);

  const [loading, setLoading] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  // react-router-dom components

  const [resumeStatus, setResumeStatus] = useState('');
  const [resume, setResume] = useState({});
  const [fetched, setFetched] = useState(false);
  const [errorSB, setErrorSB] = useState([]);

  const navigate = useNavigate();

  //create an array of three json objects with the following keys: duration, title, company, noteworthyPoints, and description
  const [experience, setExperience] = useState([

    {
      company: "",
      location: "",
      position: [
        {
          title: "",
          startedMonthYear: "",
          endedMonthYear: "",
          currentJob: false,
          jobDescription: "",
          employmentDurationMonths: null,
          bulletPoints: [""],
          noteworthy: "",
          uuid: "",
        }
      ]
    }

  ]);
  const [positions, setPositions] = useState([]);


  const [education, setEducation] = useState([

    { institution: '', degree: [{ degreeName: "", fieldOfStudy: "", graduationMonthYear: "", inProgress: false, bulletPoints: [""], overallGpa: "" }] }/*additional information can contain things like honors and accolades,majors/minors/major gpa, etc*/
  ]);
  const [customSection, setCustomSection] = useState([

    { title: "", clearTimeoutontent: "" } /*additional information can contain things like honors and accolades,majors/minors/major gpa, etc*/

  ]);



  useEffect(() => {
    if (!fetched) {
      getProfile()
        .then((profile) => {
          setProfile(profile);
          setFetched(true);
        })
        .catch((error) => {
          if ([401, 404].includes(error.code)) {
            // Perform the redirect to the login screen
            //window.location.href = '/sign-in';
          } else {
            setErrorSB([{ title: 'Error retrieving your account!', message: `Please review your info and try again! (${error})` }]);
            // Handle other errors
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfile((profile) => ({
      ...profile,
      [name]: value,
    }));
    updateProfile(profile)
    

  };



  const blsAverageMonthsEmploymentDuration = 49




  const [completed, setCompleted] = useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };



  function getOrderIndexWithinCurrentJobs(job, positionsCopy) {
    let curIndex = 0
    //now go through the current array and get everything with a start and end date and then add it start with the ones that have "currentJob" first
    positionsCopy.forEach(element => {
      for (pos in tempCopy) {
        //if it gets here it is a current job so we should organize by start date
        //we will check to make sure that all of them are current jobs before incrementing
        let isCurrent = pos.currentJob;
        while (isCurrent) {
          if (element.startedMonthYear.isBefore(pos.startedMonthYear, 'month')) {
            isCurrent = pos.currentJob
            curIndex += 1
          }
        }


      }

    }

    )
    return curIndex;
  }

  function getPlacementIndex(job, positionsCopy) {
    let placeIndex = -1
    //now go through the current array and get everything with a start and end date and then add it start with the ones that have "currentJob" first
    let isBefore = false;
    let i = 0;
    while (isBefore && ii < positionsCopy.length) {
      for (i < positionsCopy.length; i++;) {

        //if it gets here it is a current job so we should organize by start date
        //we will check to make sure that all of them are current jobs before incrementing

        if (positionsCopy[i].endedMonthYear.isBefore(job.endedMonthYear, 'month')) {
          //the current job belongs after this element
          placeIndex = i
          isBefore = false
        } else if (positionsCopy[i].endedMonthYear.isSame(job.endedMonthYear, 'month')) {
          //if the ending month and year are the same organize by starting month and year
          if (positionsCopy[i].startedMonthYear.isBefore(job.startedMonthYear, 'month')) {
            placeIndex = i
            isBefore = false
          }
        }



      }
    }
    return placeIndex;
  }


  function calculateStartAndEndDates() {
    //these functions are not done efficiently or elegantly, should rewrite at some point but it shouldnt be too bad computationally because there are only 3-5 elements
    //that we are having to organize
    let newPositionsDict = {}
    let positionsArr = []
    const item = {
      uuid: "",
      company: "",
      location: "",
      position: "",
      title: "",
      startedMonthYear: "",
      endedMonthYear: "",
      currentJob: false,
      employmentDurationMonths: null,
      originalIndex: null,
      jobBeforeThis: false,
      jobAfterThis: false,
    }





    let experienceResumeSection = [...loadedResume.resumeParsed[2]["experience"]]
    console.log("experienceResumeSection: ", experienceResumeSection)
    experienceResumeSection.forEach(experienceCompany => {

      console.log("experienceCompany", experienceCompany)
      for (let i = 0; i < experienceCompany.position.length; i++) {
        let pos = experienceCompany.position[i]
        let newUUID = String(uuid())
        pos.uuid = newUUID
        console.log("pos", pos)
        console.log("pos.uuid!!!", pos.uuid)
        console.log("pos.employmentDurationMonthsAndYears.months", pos.employmentDurationMonthsAndYears.months)
        console.log("pos.employmentDurationMonthsAndYears.years", pos.employmentDurationMonthsAndYears.years)
        let employmentDurationMonths = 0
        console.log("employmentDurationMonths!!!", employmentDurationMonths)
        if (xtype.type(Number(pos.employmentDurationMonthsAndYears.months)) == 'number') {
          employmentDurationMonths = Number(pos.employmentDurationMonthsAndYears.months) + Number(employmentDurationMonths)
          console.log("employmentDurationMonths!!!", employmentDurationMonths)
        }
        if (xtype.type(Number(pos.employmentDurationMonthsAndYears.years)) == 'number') {
          employmentDurationMonths = (Number(pos.employmentDurationMonthsAndYears.years) * 12) + Number(employmentDurationMonths)
          console.log("employmentDurationMonths!!!", employmentDurationMonths)
        }
        console.log(" pos.title!!!", pos.title)
        console.log("employmentDurationMonths!!!", employmentDurationMonths)

        let newPosition = {
          uuid: newUUID,
          company: experienceCompany.company,
          location: experienceCompany.location,
          title: pos.title,
          startedMonthYear: pos.startedMonthYear,
          endedMonthYear: pos.endedMonthYear || "",
          currentJob: pos.currentJob || false,
          employmentDurationMonths: employmentDurationMonths,
          originalIndex: i,
          jobBeforeThis: pos.jobBeforeThis || "",
          jobAfterThis: pos.jobAfterThis || "",
        }
        console.log("new position  ", newPosition)
        console.log("pos ", pos)
        console.log("new position uuid: ", newPosition.uuid, pos.uuid, " :pos.uuid")
        console.log("newPositionsDict before", newPositionsDict)
        console.log("{...newPositionsDict,[newUUID]:{...newPosition}}:", { ...{ one: "1", two: "2" }, [newPosition.uuid]: { ...newPosition } })
        let temp = { ...newPositionsDict, [newPosition.uuid]: { ...newPosition } }
        console.log("temp", temp)
        newPositionsDict = temp
        console.log("temp after", temp)
        console.log("newPositionsDict after", newPositionsDict)
        console.log("positions before", positions)
        positionsArr = [...positionsArr, { ...newPosition }]
        setPositions(positionsArr)
        console.log("pos", pos)
        console.log("positions after", positions)
        console.log("positionsArr after", positionsArr)
      }


    }

    )




    //make a new array for the positions
    let newPositions = []
    //copy the positions array
    let positionsCopy = [...positionsArr]
    console.log("positionsArr", positionsArr)
    let remainingPositions = [...positionsArr]

    console.log("positionsCopy", positionsCopy)
    //now go through the current array and get everything with a start and end date and then add it start with the ones that have "currentJob" first
    positionsCopy.forEach(element => {
      console.log("#########################################################################positionsCopy foreach", positionsCopy)
      if (element.currentJob) {
        if (newPositions.length > 0) {

          let i = getOrderIndexWithinCurrentJobs(job, newPositions)
          newPositions.splice(i, 0, element)

        } else {
          newPositions.unshift(element)
        }
        console.log("positionsCopy currentjob", positionsCopy)
        console.log("newPositions currentjob", newPositions)
        //if we have the started Month and Year and the ended month and year
      } else if (element.startedMonthYear && element.endedMonthYear) {
        let placementIndex = getPlacementIndex(newPositions)
        if (placementIndex < 0) {
          newPositions.push(element)
        } else {
          newPositions.splice(placementIndex, 0, element)
        }
        console.log("positionsCopy not currentjob", positionsCopy)
        console.log("newPositions not currentjob", newPositions)
      }

    })
    //we want to loop through the whole array first and then get the remaining elements from the initial array
    const diff = positionsCopy.filter(x => !newPositions.includes(x));
    //the remaining elmements in the diff arrray are missing either start date end date or both
    //lets look for the ones with a start or end date and the duration
    if (diff.length > 0) {
      let durationElements = []
      diff.forEach(element => {
        if (element.employmentDurationMonths) {
          //check if the element has a duration
          durationElements.push(element)
        }
      })
      let durationElementsCalculatedStartEnd = []
      console.log("start or end and duration")
      durationElements.forEach(element => {
        console.log("durationElementsCalculatedStartEnd", durationElementsCalculatedStartEnd)
        console.log("diff", diff)
        if (element.startedMonthYear) {
          let startDate = dayjs(element.startedMonthYear, "MM-YYYY")
          element.endedMonthYear = startDate.add(element.employmentDurationMonths, "month").format("MM-YYYY")
          durationElementsCalculatedStartEnd.push(element)
        } else if (element.endedMonthYear) {
          let endDate = dayjs(element.endedMonthYear, "MM-YYYY")
          element.startedMonthYear = endDate.subtract(element.employmentDurationMonths, "month").format("MM-YYYY")
          durationElementsCalculatedStartEnd.push(element)
        }
        console.log("durationElementsCalculatedStartEnd", durationElementsCalculatedStartEnd)
        console.log("diff", diff)
        console.log(" foreach")
        console.log("positionsCopy not currentjob", positionsCopy)
        console.log("newPositions not currentjob", newPositions)
        console.log("durationElementsCalculatedStartEnd not currentjob", durationElementsCalculatedStartEnd)

      })
      if (durationElementsCalculatedStartEnd.length > 0) {
        durationElementsCalculatedStartEnd.forEach(element => {

          let placementIndex = getPlacementIndex(element, newPositions)
          if (placementIndex < 0) {
            newPositions.push(element)
          } else {
            newPositions.splice(placementIndex, 0, element)
          }


        })
      }
      const durationNoStartEndDiff = diff.filter(x => !newPositions.includes(x));
      const durationOrphans = []

      if (durationNoStartEndDiff.length > 0) {
        //this is any remaining elements with duration information
        durationNoStartEndDiff.forEach(element => {
          newPositions.forEach(newPositionsElement => {
            if (element.jobBeforeThis == newPositionsElement.uuid) {
              element.startedMonthYear = newPositionsElement.endedMonthYear
              element.endedMonthYear = element.startedMonthYear.subtract(element.employmentDurationMonths, "month").format("MM-YYYY")
              let placementIndex = getPlacementIndex(element, newPositions)
              if (placementIndex < 0) {
                newPositions.push(element)
              } else {
                newPositions.splice(placementIndex, 0, element)
              }
            } else if (element.jobAfterThis == newPositionsElement.uuid) {
              element.endedMonthYear = newPositionsElement.startedMonthYear
              element.startedMonthYear = element.endedMonthYear.subtract(element.employmentDurationMonths, "month").format("MM-YYYY")
              let placementIndex = getPlacementIndex(element, newPositions)
              if (placementIndex < 0) {
                newPositions.push(element)
              } else {
                newPositions.splice(placementIndex, 0, element)
              }
            } else {
              if (element.originalIndex != 0) {
                if (newPositionsElement.originalIndex == element.originalIndex - 1) {
                  element.endedMonthYear = newPositionsElement.startedMonthYear

                  let endDate = dayjs(element.endedMonthYear, "MM-YYYY")
                  element.startedMonthYear = endDate.subtract(element.employmentDurationMonths, "month").format("MM-YYYY")
                  let placementIndex = getPlacementIndex(element, newPositions)
                  if (placementIndex < 0) {
                    newPositions.push(element)
                  } else {
                    newPositions.splice(placementIndex, 0, element)
                  }
                }
              } else {
                //if we are here it means that the original element's index was 0 so we will assume that it went until this month
                let endDate = dayjs().format("MM-YYYY")
                element.startedMonthYear = endDate.subtract(element.employmentDurationMonths, "month").format("MM-YYYY")
                element.endedMonthYear = endDate
                let placementIndex = getPlacementIndex(element, newPositions)
                if (placementIndex < 0) {
                  newPositions.push(element)
                } else {
                  newPositions.splice(placementIndex, 0, element)
                }
              }
            }
          })
        }
        )

      }
      const positionOnly = positionsCopy.filter(x => !newPositions.includes(x));
      if (positionOnly.length > 0) {
        //we want this to go if there is nothing in durationNoStartEndDiff and also if there is and we finished processing it
        //at this point anything remaining only has relative positioning to go off of

        //we start with explicit before/after, and then if there is nothing else left, then we just use the original order indeces
        positionOnly.forEach(element => {
          newPositions.forEach(newPositionsElement => {
            if (element.originalIndex != 0) {
              if (newPositionsElement.originalIndex == element.originalIndex - 1) {
                element.endedMonthYear = newPositionsElement.startedMonthYear

                let endDate = dayjs(element.endedMonthYear, "MM-YYYY")
                element.startedMonthYear = endDate.subtract(element.employmentDurationMonths, "month").format("MM-YYYY")
                let placementIndex = getPlacementIndex(element, newPositions)
                if (placementIndex < 0) {
                  newPositions.push(element)
                } else {
                  newPositions.splice(placementIndex, 0, element)
                }
              }
            } else {
              //if we are here it means that the original element's index was 0 so we will assume that it went until this month
              let endDate = dayjs().format("MM-YYYY")
              element.startedMonthYear = endDate.subtract(element.employmentDurationMonths, "month").format("MM-YYYY")
              element.endedMonthYear = endDate
              let placementIndex = getPlacementIndex(element, newPositions)
              if (placementIndex < 0) {
                newPositions.push(element)
              } else {
                newPositions.splice(placementIndex, 0, element)
              }
            }
          })
        })



      }


    }
    //need to uptate the dictionary
    newPositions.forEach(item => {
      newPositionsDict[item.uuid].startedMonthYear = item.startedMonthYear
      newPositionsDict[item.uuid].endedMonthYear = item.endedMonthYear
    })
    let experienceResumeSectionCopy = [...experienceResumeSection]
    for (let i = 0; i < experienceResumeSection.length; i++) {
      console.log("experienceResumeSection ", experienceResumeSection)
      console.log("experienceResumeSection[i] ", experienceResumeSection[i])

      console.log("experienceResumeSection[i].position ", experienceResumeSection[i].position)
      for (let j = 0; j < experienceResumeSection[i].position.length; j++) {
        let pos = experienceResumeSection[i].position[j]
        console.log("pos ", pos)
        console.log("pos.uuid ", pos.uuid)
        let updated = newPositionsDict[pos.uuid]
        console.log("updated ", updated)
        console.log("newPositionsDict ", newPositionsDict)
        experienceResumeSectionCopy[i].position[j].startedMonthYear = updated.startedMonthYear
        experienceResumeSectionCopy[i].position[j].endedMonthYear = updated.endedMonthYear
        console.log("pos startedMonthYear", pos.startedMonthYear)
        console.log("pos.endedMonthYear ", pos.endedMonthYear)
        console.log("experienceResumeSectionCopy[i].position[j] end", experienceResumeSectionCopy[i].position[j])
      }
      console.log
      handleEditElement("experience", i, experienceResumeSectionCopy[i])

    }
  }

  const sectionIndexMap = {
    basicInfo: 0,
    education: 1,
    experience: 2,
    custom: 3
  };

  const handleEditElement = async (sectionName, index, updatedElement) => {

    const sectionIndex = sectionIndexMap[sectionName];
    console.log("loadedResume", loadedResume)
    const updatedResume = { ...loadedResume, ["parsedResume"]: updatedElement };
    console.log("sectionName, index, updatedElement: ", sectionName, ", ", index, ", ", updatedElement)
    console.log("loadedResume", loadedResume)
    console.log("updatedResume", updatedResume)
    console.log("sectionIndexMap", sectionIndexMap)
    console.log("updatedResume", updatedResume)
    console.log("sectionIndex", sectionIndex)
    console.log("sectionName", sectionName)
    // Get the section to modify
    //was this originally correct?const section = updatedResume.resumeParsed[0][sectionName];
    const section = updatedResume.resumeParsed[sectionIndex][sectionName];


    // Update the specific section in the copy
    updatedResume.resumeParsed[sectionIndex][sectionName][index] = updatedElement;

    // Update the résumé state with the copy
    setResume(updatedResume);

    await update(updatedResume);
  };

  const getResumeStatus = () => {
    const fetchStatus = () => {
      getProfile({ fields: { resumeStatus: true } })
        .then((s) => {
          setStatus(s.resumeStatus);
          if (s.resumeStatus === 'ok') {
            clearInterval(statusCheckInterval); // Clear the interval when status is 'ok'
            reload(true);
          }
        })
        .catch(() => { });
    };

    // Make the initial call to fetchStatus
    fetchStatus();

    let statusCheckInterval;

    // Set up the interval if the initial status is not 'ok'
    if (status !== 'ok') {
      statusCheckInterval = setInterval(fetchStatus, 5000); // Retry every 5 seconds
    }
  };


  useEffect(() => {
    setResumeDate(resumeUpdated);
    setResumeKeywords(keywords);
    if (status !== 'ok') getResumeStatus();
  }, [keywords, resumeUpdated]);

  function update(updatedResume) {
    updateProfile({ resumeParsed: updatedResume.resumeParsed })
      .then((result) => { })
      .catch((err) => {
        setErrorSB([{ title: 'Error saving your changes!', message: `Please review your info and try again! (${err})` }]);
      })
      .finally(() => { });
  }

  const handleOnSave = () => {
    setLoading('Saving keywords...');

    updateProfile({ resumeKeywords: resumeKeywords })
      .then((result) => {
        setResumeDate(new Date());
      })
      .catch((error) => {
        setMessageSB([
          {
            color: 'error',
            icon: 'error',
            title: `Error saving keywords`,
            message: error.message,
          },
        ]);
        reload(false);
      })
      .finally(() => {
        setLoading(false);
        setResumeError(false);
      });
  };


  const emptySections = {
    "experience": {
      "company": "", "location": "", "position": [{ "title": "", "jobDescription": "", "skills": [], "startedMonthYear": "", "endedMonthYear": "", "currentJob": false, "employmentDurationMonths": null, "employmentDurationMonthsAndYears": { "months": 0, "years": 0 }, "noteworthy": "" }]
    },
    "education": {
      "institution": "", "degree": [{ "degreeName": "", "degreeType": "", "graduationMonth": null, "graduationYear": null, "inProgress": false, "majors": null, "misc": "", "overallGpa": "" }]
    },
    "custom": {
      "title": "", "content": [""]

    }
  }


  const res = [
    [
      {
        skill: "",
      }
    ],
    [
      {
        institution: "",
        additionalInformation: "",
        degree: [
          {
            degreeName: "",
            graduationYear: null,
            graduationMonth: null,
            inProgress: false,
            additionalInformation: "",
            majors: null,
            overallGpa: "",
            misc: ""
          }]
      }]
    ,
    [
      {
        company: "",
        location: "",
        position: [
          {
            title: "",
            jobDescription: "",
            skills: [],
            startedMonthYear: "",
            endedMonthYear: "",
            currentJob: false,
            bulletPoints: [],
            employmentDurationMonths: null,
            noteworthy: ""
          }
        ]
      }
    ]
    ,
    {
      firstName: "",
      lastName: "",
      currentTitle: "",
      pronouns: {
        subject: "", object: "", possessive: ""
      },
      email: "",
      bioSummary: "",
      telephone: "",
      location: "",
      portfolioUrl: "",
      linkedinUrl: "",
      githubUrl: ""
    },





    [
      {
        title: "",
        content: ""
      }
    ],
    {
      languages: [
        {
          language: "",
          fluency: ""
        }
      ],
      patents: [
        {
          name: "",
          number: "",
          country: "",
          year: ""
        }
      ],

      certifications: [
        {
          name: "",
          date: ""
        }
      ],
      volunteering: [
        {
          organization: "",
          role: "",
          date: ""
        }
      ]
    }
  ]
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

  /*
  localforage.getItem('user').then((user) => {
    setProfile(user.basicInfo)
    {user.resumeParsed? setResume(user.resumeParsed):setResume(res)}
    console.log("profile is:*********************************** ", profile )
  }).catch((err) => {});
  */

  const ResumeWizardPages = ["biographical", "experience", "education", "customSection"]
  async function handleResumeExperieceSubmit(direction) {
    navigateResumeWizard(direction)
    //submit to LLM to flesh out the job descriptions
    //calculate the start and end dates of each job
  }

  function compileJobsForDescriptions() {
    let jobDescriptionArray = []
    let experienceResumeSection = [...loadedResume.resumeParsed[2]["experience"]]
    console.log("experienceResumeSection: ", experienceResumeSection)
    experienceResumeSection.forEach(experienceCompany => {
      for (let i = 0; i < experienceCompany.position.length; i++) {
        let position = experienceCompany.position[i]
        if (!position.jobDescription) {

          jobDescriptionArray.push({
            jobTitle: position.title,
            jobNoteworthy: position.noteworthy
          })
        }
      }
    })
    return jobDescriptionArray
  }

  function createResume() {
    //create a resume object
    loadedResume.resumeParsed[sectionIndexMap["experience"]]



    let compiledJobs = compileJobsForDescriptions()
    generateJobDescription(compiledJobs)
    calculateStartAndEndDates()

    
    navigate("/resume")
    /*const resume = {
      profile: profile,
      experience: experience,
      education: education,
      customSection: customSection
    }*/
    //send the resume object to the server
    console.log("resume is: ", resume)
  }
  function navigateResumeWizard(direction) {
    if (direction === 'next' && activeStep < ResumeWizardPages.length - 1) {
      setactiveStep(activeStep + 1);
    } else if (direction === 'prev' && activeStep > 0) {
      setactiveStep(activeStep - 1);
    }

  }




  async function addEntry(sectionName) {
    let emptySectionObject = emptySections[sectionName]
    console.log("^^^^^^^^^^^^^^^^^^^^^^^^^emptySectionObject is ", emptySectionObject)
    console.log("^^^^^^^^^^^^^^^^^^^^^^^^^resume is ", resume)
    console.log("^^^^^^^^^^^^^^^^^^^^^^^^^loadedResume is ", loadedResume)
    const newResume = { ...loadedResume };
    const section = newResume.resumeParsed[sectionIndexMap[sectionName]][sectionName];

    newResume.resumeParsed[sectionIndexMap[sectionName]][sectionName] = [...section, emptySectionObject];    // Update the résumé state with the copy
    setResume(newResume);
    await update(newResume);
  }





  return (
    <MDBox mb={0}>

      <Grid container spacing={1}>



        <Grid item xs={12}>
          <Card>
            <Grid spacing={1} container>
              <Grid item xs={12}>
                <MDBox sx={{ minWidth: 35 }} ml={2}>
                  <Box sx={{ width: '100%' }}>

                    <Box sx={{ width: '100%' }}>
                      <Stepper nonLinear activeStep={activeStep} alternativeLabel>
                        {steps.map((label, index) => (
                          <Step key={label} completed={completed[index]}>
                            <StepButton color="inherit" onClick={handleStep(index)}>
                              {label}
                            </StepButton>
                          </Step>
                        ))}
                      </Stepper>
                      <div>
                        {allStepsCompleted() ? (
                          <Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                              All steps completed - you&apos;re finished
                            </Typography>

                          </Fragment>
                        ) : (
                          <Fragment>

                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                              <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}

                                startIcon={<NavigateBeforeIcon />}
                              >
                                Back

                              </Button>
                              <Box sx={{ flex: '1 1 auto' }} />
                              <Button onClick={handleNext} sx={{ mr: 1 }} endIcon={<NavigateNextIcon />}>
                                Next
                              </Button>
                              {activeStep !== steps.length &&
                                (completed[activeStep] ? (
                                  <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                    Step {activeStep + 1} already completed
                                  </Typography>
                                ) : "" /*(
                                  <Button onClick={handleComplete}>
                                    {completedSteps() === totalSteps() - 1
                                      ? 'Finish'
                                      : 'Complete Step'}
                                  </Button>
                                )*/)}
                            </Box>
                          </Fragment>
                        )}
                      </div>
                    </Box>
                  </Box>
                  {/*<MDProgress value={(activeStep + 1) / 4 * 100} />*/}
                </MDBox>
              </Grid>

            </Grid>




            <MDBox mt={1} mb={2}>
              {/*map the experience array to create an Experience component for each object in the array*/}



              <MDBox mx={6} mt={1} mb={2}>


                <Container fixed maxWidth="sm" pl={10} sx={{ width: 700 }} >
                  <Stack spacing={2}>


                    <div class={styles.loader} sx={{ width: '100%' }} >


                    </div>

                    {resume.resumeParsed ? console.log("resume is: ", resume) : console.log("nah")}
                    {loadedResume ? console.log("loadedResume is: ", loadedResume) : console.log("nah")}
                    {activeStep === 0 ?
                      <Stack ml={10} spacing={3}>

                        <Stack sx={{ maxWidth: "900px" }} mt={5} direction="row" spacing={3}>

                          <MDInput

                            type="text"

                            variant="filled"
                            size="large"
                            label="First Name"
                            value={profile ? profile.firstName : ""}
                            name="firstName"
                            onChange={handleProfileChange}
                            fullWidth
                          />

                          <MDInput

                            type="text"
                            variant="filled"
                            sx={{
                              backgroundColor: "#F3F6F9"
                            }}

                            label="Last Name"
                            value={profile ? profile.lastName : ""}
                            name="lastName"
                            onChange={handleProfileChange}
                            fullWidth
                          />

                        </Stack>


                        <Stack sx={{ maxWidth: "900px" }} direction="row" spacing={3}>
                          <MDInput
                            type="email"
                            variant="filled"

                            label="Email"

                            value={profile ? profile.email : ""}
                            name="telephone"
                            onChange={handleProfileChange}
                            fullWidth
                          />

                          <MDInput
                            type="tel"
                            variant="filled"

                            label="Mobile"
                            inputProps={{
                              pattern: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
                              title: 'Please enter a valid telephone number (XXX-XXX-XXXX)',
                            }}
                            value={profile ? profile.telephone : ""}
                            name="telephone"
                            onChange={handleProfileChange}
                            fullWidth
                          />

                        </Stack>

                        <Stack direction="row" sx={{ maxWidth: "900px" }} spacing={3}>

                          <MDInput type="text" variant="filled" label="City" value={profile.city} name="city" onChange={handleProfileChange} fullWidth />
                          <FormControl size="small" fullWidth variant="filled">
                            <InputLabel id="demo-simple-select-label">State</InputLabel>

                            <Select

                              labelId="demo-simple-select-label"
                              name="state"
                              margin="none"
                              value={profile.state}

                              onChange={handleProfileChange}

                              select
                            >
                              {stateOptions.map((stateOption) => (
                                <MenuItem key={stateOption.abbreviation} value={stateOption.abbreviation}>
                                  {stateOption.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                        </Stack>



                      </Stack> : ""}
                              P{console.log("active step is "+activeStep)}
                    {activeStep === 1 && loadedResume ? (<MDBox><MDBox ml={10} mb={0}> <Typography variant="h2" sx={{ fontWeight: 500 }}>Experience</Typography></MDBox> {

                      loadedResume.resumeParsed[sectionIndexMap["experience"]].experience.map((exp, index, arr) => (
                        <MDBox><Experience key={index} arr={arr} index={index} experience={exp} handleEditElement={handleEditElement} />

                        </MDBox>))}</MDBox>) : ("")}



                    {activeStep === 2 && loadedResume ?

                      <MDBox>
                        <MDBox ml={10} mb={2}>
                          <Typography variant="h2" sx={{ fontWeight: 500 }}>Education</Typography>
                        </MDBox>

                        {
                          loadedResume.resumeParsed[sectionIndexMap["education"]].education.map((ed, index, arr) => (
                            <MDBox>


                              <Education
                                index={index}
                                education={ed}
                                key={index}
                                arr={arr}
                                handleEditElement={handleEditElement}
                              />

                            </MDBox>
                          ))
                        }

                      </MDBox> : ""}
                    {activeStep === 3 ?

                      <MDBox>
                        <MDBox ml={10} mb={2}>
                          <Typography variant="h2" sx={{ fontWeight: 500 }}>Custom Sections</Typography>
                        </MDBox>
                        <MDBox ml={10} mb={2}>

                          <Box>Here you can add sections like honors, certifications, a summary, skills, etc (optional)  </Box>

                        </MDBox>
                        {console.log("loadedResume.resumeParsed[sectionIndexMap['custom']]: ", loadedResume.resumeParsed[sectionIndexMap["custom"]])}
                        {


                          loadedResume.resumeParsed[sectionIndexMap["custom"]].custom.map((cs, index, arr) => (
                            <MDBox>


                              <CustomSection
                                index={index}
                                key={index}
                                customSection={cs}
                                arr={arr}

                                handleEditElement={handleEditElement}
                              />

                            </MDBox>
                          ))
                        }

                      </MDBox> : ""}

                    {activeStep === 1 ? <Box sx={{ width: "100%" }} mt={1}><Box ml={12} ><ApartmentIcon pt={4} fontSize={"large"} sx={{ transform: "translate(-35%,35%)" }}></ApartmentIcon> <IconButton onClick={() => addEntry("experience")} color="info" variant="outlined" size="large" mx={2} mt={3}><AddCircleOutlineIcon py={4} fontSize={"large"} ></AddCircleOutlineIcon></IconButton>  </Box> </Box> : ""}
                    {activeStep === 2 ? <Box sx={{ width: "100%" }} mt={1}><Box ml={12} ><AccountBalanceIcon pt={4} fontSize={"large"} sx={{ transform: "translate(-35%,35%)" }}></AccountBalanceIcon> <IconButton onClick={() => addEntry("education")} color="info" variant="outlined" size="large" mx={2} mt={3}><AddCircleOutlineIcon py={4} fontSize={"large"} ></AddCircleOutlineIcon></IconButton>  </Box> </Box> : ""}
                    {activeStep === 3 ? <Box sx={{ width: "100%" }} mt={1}><Box ml={12} ><TitleIcon pt={4} fontSize={"large"} sx={{ transform: "translate(-35%,35%)" }}></TitleIcon  > <IconButton onClick={() => addEntry("custom")} color="info" variant="outlined" size="large" mx={2} mt={3}><AddCircleOutlineIcon py={4} fontSize={"large"} ></AddCircleOutlineIcon></IconButton>  </Box> </Box> : ""}


                  </Stack>
                </Container>

                <div>

                  {allStepsCompleted() ? "" : (
                    <Fragment>

                      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                          color="info" variant="contained" size="large"

                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                          startIcon={<NavigateBeforeIcon />}
                        >
                          Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                            {/*<MDButton color="info" variant="contained" size="large" onClick={handleNext} sx={{ mr: 1 }}>*/}


                            {activeStep < 3 ? <Button color="info" variant="contained" size="large" onClick={handleComplete} sx={{ mr: 1 }} endIcon={<NavigateNextIcon />}> Next</Button> : 
                            <Grid item xs={3}> <Button onClick={() => createResume()} color="info" variant="contained" size="large" mx={2} mt={3}>Assemble My Resume!</Button></Grid>}
                              {activeStep !== steps.length && completed[activeStep] ? (<Typography variant="caption" sx={{ display: 'inline-block' }}>Step {activeStep + 1} already completed</Typography>) : ""}{/* (
                              <Button onClick={handleComplete}>
                                {completedSteps() === totalSteps() - 1
                                  ? <Grid container mt={1} mb={3} spacing={6}>
                                    <Grid item xs={3}> <MDButton onClick={() => createResume()} color="info" variant="contained" size="large" mx={2} mt={3}>Assemble My Resume!</MDButton>
                                    </Grid>
                                  </Grid>
                                  : 'Complete Step'}
                              </Button>
                              )

                              ) */}
                        </Box>
                    </Fragment>
                  )}
                </div>
              </MDBox>


            </MDBox>
            <Grid container mt={1} mb={3} spacing={6}>
              <Grid item xs={12}>

              </Grid>
            </Grid>
            {console.log("activeStep is: ", activeStep)}
            {/*activeStep > 0 ? <Grid item xs={3}> <MDButton onClick={() => navigateResumeWizard('prev')} color="info" variant="contained" size="large" mx={2} mt={3}>previous</MDButton></Grid> : ""}
              {activeStep < 3 ? <Grid item xs={3}> <MDButton onClick={() => navigateResumeWizard('next')} color="info" variant="contained" size="large" mx={2} mt={3}>next</MDButton></Grid>} : <Grid item xs={3}> <MDButton onClick={() => createResume()} color="info" variant="contained" size="large" mx={2} mt={3}>Assemble My Resume!</MDButton></Grid>*/}


          </Card>
        </Grid>
      </Grid>
    </MDBox >
  );
}

export default ResumeWizard;

ResumeWizard.propTypes = {
  keywords: PropTypes.array,
  resumeUpdated: PropTypes.string,
  reload: PropTypes.func,
};
