/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';

import SettingsTab from '../SettingsTab';
import PaymentTab from '../PaymentTab';

// Material Dashboard 2 React base styles
import breakpoints from 'assets/theme/base/breakpoints';

// Images
import backgroundImage from 'assets/images/bg-profile.jpeg';

function Header(profile) {
  const [tabsOrientation, setTabsOrientation] = useState('horizontal');

  // Retrieve tab key
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabKey = queryParams.get('tabKey');
  const parsedTabKey = parseInt(tabKey);

  // Check if parsedTabKey is NaN, and set the default value to 0 if it is
  const [tabValue, setTabValue] = useState(isNaN(parsedTabKey) ? 0 : parsedTabKey);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm ? setTabsOrientation('vertical') : setTabsOrientation('horizontal');
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener('resize', handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({functions: {rgba, linearGradient}, palette: {gradients}}) =>
            `${linearGradient(rgba(gradients.info.main, 0.6), rgba(gradients.info.state, 0.6))}, url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: '50%',
          overflow: 'hidden',
        }}
      />
      <Card
        sx={{
          position: 'relative',
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              src={`https://www.gravatar.com/avatar/${profile?.avatar ? profile.avatar : '3b3be63a4c2a439b013787725dfce802'}?d=identicon`}
              alt="profile-image"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {profile?.salutation && `${profile.salutation} `}
                {profile?.lastName && profile?.firstName ? `${profile.firstName} ${profile.lastName}` : ''}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {profile?.title ? profile.title : 'No title'}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ml: 'auto'}}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  value={0}
                  label="General"
                  icon={
                    <Icon fontSize="small" sx={{mt: -0.25}}>
                      settings
                    </Icon>
                  }
                />
                {/* <Tab
                  value={1}
                  label="Profile"
                  icon={
                    <Icon fontSize="small" sx={{mt: -0.25}}>
                      person
                    </Icon>
                  }
                /> */}
                <Tab
                  value={2}
                  label="Payment"
                  icon={
                    <Icon fontSize="small" sx={{mt: -0.25}}>
                      shopping_cart
                    </Icon>
                  }
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        {tabValue === 0 && <SettingsTab {...profile} />}
        {/* {tabValue === 1 && <ProfileTab {...profile} />} */}
        {tabValue === 2 && <PaymentTab {...profile} />}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  profile: {},
};

// Typechecking props for the Header
Header.propTypes = {
  profile: PropTypes.object,
};

export default Header;
