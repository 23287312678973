/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// react-router-dom components
import {Link, useNavigate} from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';

// Images
import bgImage from 'assets/images/bg-sign-up-cover.jpeg';

function Offline() {
  const navigate = useNavigate();
  {
    return (
      <BasicLayout image={bgImage}>
        <Card>
          <MDBox variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="success" mx={2} mt={-3} p={3} mb={1} textAlign="center">
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              It looks like our servers are offline
            </MDTypography>
          </MDBox>
          <MDBox pt={0} pb={3} px={3}>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Try again by&nbsp;
                <MDTypography component={Link} to="/sign-in" variant="button" color="info" fontWeight="medium" textGradient>
                  Signing in
                </MDTypography>
                &nbsp;or&nbsp;
                <MDTypography onClick={() => navigate(-1)} variant="button" color="info" fontWeight="medium" textGradient mr={2}>
                  Go back
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </Card>
      </BasicLayout>
    );
  }
}
export default Offline;
