/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// react-router-dom components
import {useParams, useNavigate} from 'react-router-dom';
import {createSearch, updateSearch, getSearch,  getJobDetails} from '../../../searches';
import {getProfile} from '../../../profile';

import React, {useState, useEffect} from 'react';
import Autocomplete from '@mui/material/Autocomplete';

import dayjs from 'dayjs';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDSnackbar from 'components/MDSnackbar';
import MDLoading from 'components/MDLoading';

// Material Dashboard 2 React example components
import DashboardLayout from 'tongoComponents/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'tongoComponents/Navbars/DashboardNavbar';
import stateOptions from 'tongoComponents/Misc/states';
import Footer from 'tongoComponents/Footer';

function EditJobSearch() {
  const queryParams = useParams();
  const navigate = useNavigate();

  const [jobSearch, setJobSearch] = useState({
    id: null,
    name: '',
    title: '',
    level: 0,
    keywords: null,
    jobLocation: {
      city: '',
      state: '',
      searchDistance: null,
    },
    jobType: 'full-time',
    startDate: dayjs(),
    endDate: dayjs().add(2, 'month'),
    notificationPeriod: 24,
  });

  const [loading, setLoading] = useState(false);

  const [messageSB, setMessageSB] = useState([]);
  const closeMessage = () => setMessageSB([]);

  const [generated, setGenerated] = useState(false);
  const [retrieve, setRetrieve] = useState(false);
  const handleFormSubmit = async (event) => {
    if (loading) return;

    event.preventDefault();

    if (jobSearch.keywords == '') {
      setMessageSB([
        {
          color: 'error',
          icon: 'error',
          title: 'Keyword is a required field',
          message: 'Please add a few keywords and try again!',
        },
      ]);
      return;
    }

    // const keywordsString = jobSearch.keywords.join(', ');

    try {
      setLoading(true);
      let successNavigate;

      // const updatedJobSearch = {
      //   ...jobSearch,
      //   keywords: keywordsString,
      // };

      if (jobSearch.id) {
        await updateSearch(jobSearch);
        successNavigate = `/search`;
      } else {
        let newJobSearch = await createSearch(jobSearch);
        successNavigate = `/search`;
      }

      setMessageSB([
        {
          color: 'success',
          icon: 'check',
          title: `Job search was ${jobSearch.id ? 'updated' : 'created'}!`,
          message: 'Redirecting to job search...',
        },
      ]);

      setTimeout(() => {
        navigate(successNavigate);
      }, 2000);
    } catch (error) {
      if (error.status === 401) {
        await profile.signoffProfile();
        navigate('/sign-in');
      }
      setMessageSB([
        {
          color: 'error',
          icon: 'error',
          title: `Error ${jobSearch.id ? 'updating' : 'creating'} job search!`,
          message: 'Please review your info and try again!',
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  //handle form editing for controlled inputs
  const handleChange = (event) => {
    const {name, value} = event.target;

    if (name.includes('jobLocation.')) {
      const nestedPropertyName = name.split('.')[1];
      setJobSearch((jobSearch) => ({
        ...jobSearch,
        jobLocation: {
          ...jobSearch.jobLocation,
          [nestedPropertyName]: value,
        },
      }));
    } else {
      setJobSearch((jobSearch) => ({
        ...jobSearch,
        [name]: value,
      }));
    }
  };

  const handleKeywordsChange = (event, newValue) => {
    // setResumeKeywords(newValue);
    setJobSearch((prevJobSearch) => ({
      ...prevJobSearch,
      keywords: newValue,
    }));
  };

  const handleDateChange = (event) => {
    const {name, value} = event.target;
    const isoValue = value.toISOString();

    if (name === 'startDate' || name === 'endDate') {
      setJobSearch((prevJobSearch) => ({
        ...prevJobSearch,
        startDate: name === 'startDate' ? isoValue : prevJobSearch.startDate,
        endDate: name === 'endDate' ? isoValue : prevJobSearch.endDate,
      }));
    }
  };

  useEffect(() => {
    if (!retrieve && queryParams?.searchId && !jobSearch?.id)
      getSearch(queryParams.searchId)
        .then((search) => {
          setJobSearch(search);
          setRetrieve(true);
        })
        .catch((error) => {
          // if (error.code === 401) {
          // Perform the redirect to the login screen
          // window.location.href = '/sign-in';
          // } else {
          console.error(error);
          // Handle other errors
          // }
        })
        .finally(() => {
          setLoading(false);
        });
    else {
      //retrieve defaults
      getProfile({fields: {city: true, state: true, searchDistance: true}})
        .then((results) => {
          setJobSearch((prevJobSearch) => ({
            ...prevJobSearch,
            jobLocation: {
              ...prevJobSearch.jobLocation,
              city: results.city || prevJobSearch.city,
              state: results.state || prevJobSearch.state,
              searchDistance: results.searchDistance || prevJobSearch.searchDistance,
            },
          }));
        })
        .catch(() => {})
        .finally(() => {});
    }
  }, []);

  const retrieveKeywords = () => {
    setLoading(true);
    getProfile({fields: {resumeKeywords: true}})
      .then((result) => {
        if (result?.length !== 0) {
          setGenerated(true);
          setJobSearch((jobSearch) => ({
            ...jobSearch,
            keywords: Array.isArray(jobSearch.keywords)
              ? [...jobSearch.keywords, ...Object.values(result.resumeKeywords)]
              : Object.values(result.resumeKeywords), // Initialize as an array if not already
          }));
        }
      })
      .catch((error) => {
        setMessageSB([
          {
            color: 'error',
            icon: 'error',
            title: 'Error retrieving résumé keywords',
            message: error.message,
          },
        ]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderMsg = (color, icon, title, message) => (
    <MDSnackbar
      color={color}
      icon={icon}
      title={title}
      content={message}
      dateTime=""
      open={Boolean(messageSB)}
      onClose={closeMessage}
      close={closeMessage}
      bgWhite
    />
  );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        {messageSB.length ? renderMsg(messageSB[0].color, messageSB[0].icon, messageSB[0].title, messageSB[0].message) : null}
        {/*<MDLoading text="Retrieving job search data..." open={loading} />*/}
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  {jobSearch?.id ? 'Modify Job Search' : 'Job Search'}
                </MDTypography>
                <MDTypography display="block" variant="button" color="white" my={1}>
                  {!jobSearch?.id ? <>Create a new job search</> : <>Edit this job search</>}
                </MDTypography>
                
              </MDBox>
              <FormGroup>
                <MDBox pt={3} pb={3} px={3}>
                  <MDBox component="form" role="form" onSubmit={handleFormSubmit}>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        label="Job search name"
                        variant="standard"
                        fullWidth
                        name="name"
                        required={true}
                        placeholder="My new job search"
                        aria-label="Job search name"
                        value={jobSearch?.name || ''}
                        onChange={handleChange}
                        autoComplete="off"
                        shrink={'true'}
                      />
                    </MDBox>
                    <MDBox mb={3}>
                      <MDInput
                        type="text"
                        label="Job position title"
                        variant="standard"
                        fullWidth
                        name="title"
                        required={false}
                        placeholder="Executive"
                        aria-label="Job search name"
                        value={jobSearch?.title || ''}
                        onChange={handleChange}
                        autoComplete="off"
                        shrink={'true'}
                      />
                    </MDBox>
                    <MDBox mb={3}>
                      <InputLabel id="position-label">Job position level</InputLabel>
                      <Select
                        variant="standard"
                        fullWidth
                        labelId="position-label"
                        label="Job position level"
                        aria-label="Job position level"
                        value={jobSearch?.level}
                        name="level"
                        onChange={handleChange}
                      >
                        <MenuItem key={0} value={0}>
                          Any
                        </MenuItem>
                        <MenuItem key={1} value={1}>
                          Entry level
                        </MenuItem>
                        <MenuItem key={2} value={2}>
                          Mid level
                        </MenuItem>
                        <MenuItem key={3} value={3}>
                          Senior level
                        </MenuItem>
                      </Select>
                    </MDBox>
                    <MDBox mb={4}>
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <Autocomplete
                          multiple
                          required
                          id="tags-standard"
                          size="normal"
                          options={jobSearch?.keywords || []}
                          value={jobSearch?.keywords || []}
                          renderInput={(params) => (
                            <MDInput {...params} variant="standard" label="Search keywords" placeholder="Keywords, press enter for each" fullWidth />
                          )}
                          limitTags={30}
                          freeSolo
                          onChange={handleKeywordsChange}
                        />
                      </MDBox>
                      <MDBox mt={2} mb={1} hidden={!jobSearch?.id || jobSearch?.keywords?.length === 0 ? false : true}>
                        {/* {!jobSearch?.keywords?.length === 0 ? ( */}
                        <MDButton
                          variant="gradient"
                          color="success"
                          size="small"
                          onClick={() => {
                            retrieveKeywords();
                          }}
                        >
                          Use keywords from my résumé
                        </MDButton>
                        {/* ) : null} */}
                      </MDBox>
                    </MDBox>
                    <MDBox mb={2} width="100%" display="flex" alignItems="center">
                      <MDInput
                        type="select"
                        label="Job location city"
                        variant="standard"
                        name="jobLocation.city"
                        required={true}
                        placeholder="Seattle"
                        aria-label="Job location"
                        value={jobSearch?.jobLocation.city || ''}
                        onChange={handleChange}
                        shrink={'true'}
                        autoComplete="off"
                      />
                      &nbsp;&nbsp;&nbsp;
                      <MDInput
                        select
                        variant="standard"
                        // type="select"
                        label="State"
                        aria-label="State"
                        value={jobSearch?.jobLocation.state || ''}
                        name="jobLocation.state"
                        onChange={handleChange}
                        shrink={'true'}
                        autoComplete="off"
                        style={{marginTop: 'auto', alignItems: 'left'}} // Align to the bottom
                      >
                        {stateOptions.map((stateOption) => (
                          <MenuItem key={stateOption.abbreviation} value={stateOption.abbreviation}>
                            {stateOption.name}
                          </MenuItem>
                        ))}
                      </MDInput>
                      &nbsp;&nbsp;&nbsp;
                      <MDInput
                        type="number"
                        label="Search radius (miles)"
                        variant="standard"
                        name="jobLocation.searchDistance"
                        required={true}
                        placeholder="20"
                        aria-label="Search radius"
                        value={jobSearch?.jobLocation.searchDistance || ''}
                        onChange={handleChange}
                        shrink={'true'}
                      />
                    </MDBox>
                    <MDBox mb={2}>
                      <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                        Job position type
                      </MDTypography>
                      <MDBox ml={3}>
                        <RadioGroup row aria-labelledby="job postion type" value={jobSearch?.jobType} name="jobType" onChange={handleChange}>
                          <FormControlLabel
                            value="full-time"
                            control={<Radio />}
                            fontWeight="regular"
                            color="text"
                            label={
                              <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                                Full-time
                              </MDTypography>
                            }
                          />
                          <FormControlLabel
                            value="part-time"
                            control={<Radio />}
                            fontWeight="regular"
                            color="text"
                            label={
                              <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                                part-time
                              </MDTypography>
                            }
                          />
                          <FormControlLabel
                            value="contract"
                            control={<Radio />}
                            fontWeight="regular"
                            color="text"
                            label={
                              <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                                Contract
                              </MDTypography>
                            }
                          />
                          <FormControlLabel
                            value="internship"
                            control={<Radio />}
                            fontWeight="regular"
                            color="text"
                            label={
                              <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                                Internship
                              </MDTypography>
                            }
                          />
                          <FormControlLabel
                            value="volunteer"
                            control={<Radio />}
                            fontWeight="regular"
                            color="text"
                            label={
                              <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                                Volunteer
                              </MDTypography>
                            }
                          />
                          <FormControlLabel
                            value="other"
                            control={<Radio />}
                            fontWeight="regular"
                            color="text"
                            label={
                              <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                                Other
                              </MDTypography>
                            }
                          />
                        </RadioGroup>
                      </MDBox>
                    </MDBox>
                    {/* <MDBox mb={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateRangePicker localeText={{ start: 'Start search on', end: 'End search on' }} />
                        </LocalizationProvider>
                      </MDBox> */}
                    <MDBox mb={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={dayjs(jobSearch?.startDate)}
                          label="Start search"
                          name="startDate"
                          required={false}
                          aria-label="Start search on"
                          onChange={handleDateChange}
                          slotProps={{textField: {variant: 'standard'}}}
                          input={(params) => <MDInput {...params} />}
                        />
                      </LocalizationProvider>
                      &nbsp;&nbsp;&nbsp;
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={dayjs(jobSearch?.endDate)}
                          label="End search on"
                          name="endDate"
                          required={false}
                          aria-label="End search on"
                          onChange={handleDateChange}
                          slotProps={{textField: {variant: 'standard'}}}
                          input={(params) => <MDInput {...params} />}
                        />
                      </LocalizationProvider>
                    </MDBox>
                    <MDBox mb={2}>
                      <MDTypography variant="button" fontWeight="regular" color="text" ml={0} lineHeight={1}>
                        Notification frequency
                        <br />
                        Searches are performed daily. You can be notified whenever matches are found:
                      </MDTypography>
                      <MDInput
                        select
                        variant="standard"
                        fullWidth
                        type="select"
                        // label="Notify me"
                        // aria-label="Notify me"
                        value={jobSearch?.notificationPeriod}
                        name="notificationPeriod"
                        onChange={handleChange}
                        shrink={'false'}
                        autoComplete="off"
                      >
                        <MenuItem key={24} value={24}>
                          Daily
                        </MenuItem>
                        <MenuItem key={48} value={48}>
                          Every 2 days
                        </MenuItem>
                        <MenuItem key={168} value={168}>
                          Weekly
                        </MenuItem>
                        <MenuItem key={336} value={336}>
                          Every 2 weeks
                        </MenuItem>
                      </MDInput>
                    </MDBox>
                    <MDBox display="flex" alignItems="left" mt={5}>
                      <MDBox mr={5}>
                        <MDButton type="submit" variant="gradient" color="info" disabled={loading}>
                          {jobSearch?.id ? 'Update job search' : 'Add job search'}
                        </MDButton>
                      </MDBox>
                      <MDBox>
                        {jobSearch?.id && (
                          <MDButton onClick={() => navigate(-1)} variant="gradient" color="info" ml={8}>
                            Cancel
                          </MDButton>
                        )}
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </FormGroup>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditJobSearch;
