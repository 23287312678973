/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// react-router-dom components
import {Link, useNavigate} from 'react-router-dom';
import {createProfile} from '../../../profile';
import {useState} from 'react';

// @mui material components
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import MDSnackbar from 'components/MDSnackbar';
import MDLoading from 'components/MDLoading';

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';

// Images
import bgImage from 'assets/images/bg-sign-up-cover.jpeg';

function SignUp() {
  const navigate = useNavigate();

  const [errorSB, setErrorSB] = useState([]);
  const [loading, setLoading] = useState(false);
  const closeError = () => setErrorSB([]);

  const [checked, setChecked] = useState(false);
  const handleCheck = () => setChecked(!checked);

  const handleFormSubmit = (event) => {
    event.preventDefault();

    // Retrieve the form data
    const firstName = event.target.elements.firstName.value;
    const lastName = event.target.elements.lastName.value;
    const email = event.target.elements.email.value.toLowerCase();
    const password = event.target.elements.password.value;
    const agreeTerms = event.target.elements.agreeTerms.checked;

    if (!agreeTerms) {
      setErrorSB([{title: 'Error creating your account!', message: 'You must agree to the terms and conditions before signing in!'}]);
      return;
    }
    // Create an object with the form data
    const formData = {
      firstName,
      lastName,
      email,
      password,
      agreeTerms,
    };

    setLoading(true);
    // Submit the form data to the backend server
    createProfile(formData)
      .then((profile) => {
        navigate('/resume');
      })
      .catch((error) => {
        setErrorSB([{title: 'Error creating your account!', message: error.message}]);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleChange = (event) => {
    if (event.target.value && event.target.value.length > 7) {
      setErrorSB([]);
    } else {
      setErrorSB([{title: 'Password error', message: 'Please make sure your password has 8 characters!'}]);
    }
  };

  const renderError = (title, message) => (
    <MDSnackbar color="error" icon="error" title={title} content={message} dateTime="" open={Boolean(errorSB)} close={closeError} bgWhite />
  );

  return (
    <BasicLayout image={bgImage}>
      {errorSB.length ? renderError(errorSB[0].title, errorSB[0].message) : null} <MDLoading open={loading} />
      <Card>
        <MDBox variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="success" mx={2} mt={-3} p={3} mb={1} textAlign="center">
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Automate your job search today!
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Sign up for a free account
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleFormSubmit}>
            <MDBox mb={2}>
              <MDInput type="text" label="First name" variant="standard" fullWidth name="firstName" required inputProps={{minLength: 2}} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="text" label="Last name" variant="standard" fullWidth name="lastName" required inputProps={{minLength: 2}} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="email" label="Email" variant="standard" fullWidth name="email" required />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                name="password"
                pattern=".{8,}"
                title="8 characters minimum"
                required
                inputProps={{minLength: 8}}
                onBlur={handleChange}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox name="agreeTerms" onClick={handleCheck} checked={checked} />
              <MDTypography variant="button" fontWeight="regular" color="text" sx={{cursor: 'pointer', userSelect: 'none', ml: -1}}>
                &nbsp;&nbsp;I agree to the&nbsp;
              </MDTypography>
              <MDTypography component="a" href="https://www.tongo.ai/tos" variant="button" fontWeight="bold" color="info" textGradient>
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" disabled={!checked} fullWidth>
                sign up
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?
                <MDTypography component={Link} to="/sign-in" variant="button" color="info" fontWeight="medium" textGradient>
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default SignUp;
