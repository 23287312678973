import { configureStore } from '@reduxjs/toolkit';

import { setupListeners } from '@reduxjs/toolkit/query';
import searchReducer from  './search/searchSlice';;
import userReducer from './user/userSlice';
import resumeReducer from './resume/resumeSlice';
import  {searchApi}  from '../services/search'
/*create store slices for searches, user information,  and resume */
// Search slice


export const  store = configureStore({
    reducer:{ 

        [searchApi.reducerPath]: searchApi.reducer,
        user:userReducer,
        resume:resumeReducer,
        
    },
    middleware: (getDefauldMiddleware)=>
        getDefauldMiddleware().concat(searchApi.middleware),
});

setupListeners(store.dispatch)
