/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

//import { useState } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MDTypography from 'components/MDTypography';

const MDLoading = ({open, text}) => {
  // const handleClose = () => {
  //   if (onClose) {
  //     onClose();
  //   }
  // };

  return (
    <>
      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress color="inherit" />
        <MDTypography color="white" position="absolute" top="50%" left="50%" style={{transform: 'translate(-50%, 50%)'}}>
          {text}
        </MDTypography>
      </Backdrop>
    </>
  );
};

// Setting default values for the props of MDAlert
MDLoading.defaultProps = {
  open: false,
  // onClose: {},
  text: 'Loading...',
};

// Typechecking props of the MDAlert
MDLoading.propTypes = {
  open: PropTypes.bool,
  // onClose: PropTypes.func,
  text: PropTypes.string,
};

export default MDLoading;
