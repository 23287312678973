import {fetcher, getUserId} from 'api';

// Common fetch function for user tasks
async function userTask(task, data, filter = null) {
  try {
    const userId = await getUserId();

    switch (task) {
      case 'create':
        return await fetcher(`users/`, 'POST', data);
      case 'get':
        const getUrl = `users`;
        return await fetcher(`${getUrl}${filter ? `?filter=${filter}` : ''}`, 'GET');
      case 'update':
        if (typeof data === 'object') return await fetcher(`users/${data.id}/`, 'PATCH', data);
        else throw new Error('update object is invalid');
      case 'delete':
        return await fetcher(`users/${data.id}/`, 'DELETE');
      case 'retrieve':
        return await fetcher(`users/${data.id}/`, 'GET');
      case 'passwordReset':
        return await fetcher(`/reset-password/init`, 'POST', data);
      default:
        throw new Error('Invalid task');
    }
  } catch (error) {
    if (error.code === 401) window.location.href = '/sign-in';
    return Promise.reject(error);
  }
}

// Exported functions for user tasks
export async function createUser(data) {
  if (!data) return;
  return await userTask('create', data);
}

export async function getUsers(filter) {
  try {
    const result = await userTask('get');
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getUserDetails(id, filter) {
  if (!id) throw new Error('Invalid id provided');
  try {
    const result = await userTask('retrieve', {id: id}, filter);
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateUser(data) {
  if (!data?.id) return;
  return await userTask('update', data);
}

export async function deleteUser(id) {
  if (!id) throw new Error('id is required');
  return await userTask('delete', {id: id});
}

export async function passwordReset(id) {
  if (!id) throw new Error('id is required');
  return await userTask('passwordReset', {id: id});
}

export async function archiveUser(id) {
  if (!id) throw new Error('id is required');
  let data = {id: id, roles: ['archived']};
  return await userTask('archive', {id: id});
}
