/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// react-router-dom components
import { useParams, useNavigate } from 'react-router-dom';
import { createSearch, updateSearch, getSearch, getJobDetails, deleteSearchResult, shortListSearchResult } from '../../../searches';
import { getProfile } from '../../../profile';

import React, { useState, useEffect, useRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';

import dayjs from 'dayjs';

import iconButton from 'assets/theme-dark/components/iconButton';

// @mui material components
import Link from '@mui/material/Link';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';

import MDLoading from 'components/MDLoading';

// Material Dashboard 2 React example components

import styles from './Swipe.module.css';
import { func } from 'prop-types';
function GetDate() {
  now: new Date().toISOString();
}



function JobCard({ index, icon, searchId, id, copy, companyName, reloadTrigger, companyLocation, jobLink, jobType, title,  name,  jobTitle, jobSalary, jobSnippet, source, text }) {

  const useToggle = (initialState) => {
    const [toggleValue, setToggleValue] = useState(initialState);
    const toggler = () => { setToggleValue(!toggleValue) };
    return [toggleValue, toggler]
  }
  const [toggle, setToggle] = useToggle();
  {/*if (loading) return;*/ }

  return (

    <Box className={index == 0 ? `${styles.lifocused}` : `${styles.linotfocused}`} index={index}>
      {/*<span class="material-icons">{icon}</span>*/}
      {/*console.log("props",JSON.stringify(props))*/}
      <CardContent   >
        {reloadTrigger ? "" : ""}
        <Typography variant="h5" component="div">
          {jobTitle}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" >
        <h5>{index == 0 && companyName? `at ${companyName}`:""}{index == 0 &&companyLocation&&companyName?" - ":""}{companyLocation?companyLocation:""}</h5>
        </Typography>
        {/*<Typography sx={{ mb: 1.5 }} color="text.secondary">
        {jobSalary ? <h3>Salary: {jobSalary}</h3> : ""}
  </Typography>*/}
        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
          {jobSalary ? <h3>Salary: {jobSalary}</h3> : ""}
        </Typography>






        {/*<button onClick={setToggle} variant="gradient" color="info" >{'show full description'}</button>
      !toggle && (     
           <p>"more"</p>
          )*/}
        {/*toggle && (
        <div>
          <p>{jobSnippet}</p>
          <h3 ahref={jobLink}>{jobLink}</h3>
        </div>
      )*/}
        {index == 0 ?
          <Typography variant="body2">
            {jobSnippet}
            <Typography variant="body2" color={"#015393"} mt={2} >

              <Link href={jobLink} color="inherit" rel="noopener noreferrer" target="_blank">
                see original posting
              </Link>


            </Typography>
          </Typography>
          : ""}
      </CardContent>

    </Box>
  )
}



function Swipe({ actionHandler, reloadTrigger, handleUpdateCarousel, carouselItems }) {
  const queryParams = useParams();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(true);
  const [jobArr, setJobArr] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [loading, setLoading] = useState(true);
  const [moveClass, setMoveClass] = useState('');


  const [messageSB, setMessageSB] = useState([]);
  const closeMessage = () => setMessageSB([]);

  const [generated, setGenerated] = useState(false);
  const [retrieve, setRetrieve] = useState(false);




  const [pollingInterval, setPollingInterval] = useState(5000);
  const [data, setData] = useState(null);

  const [error, setError] = useState(null);
  const [previousCard, setPreviousCard] = useState(-1)
  const [currentCard, setCurrentCard] = useState(0)
  const [nextCard, setNextCard] = useState(1)



  
   function getNextCardPosition(cardIndex,direction,stack_length){
    let newIndex=0;
    if(cardIndex==stack_length-1){
     
      //last card previous is last -1
      //next is the first card
      if(direction=="up"){
        newIndex=0
      }
      if(direction=="down"){
        newIndex=cardIndex-1
      }

    }
    else if(cardIndex==0){
     
      //previous card is last card this is first card
      //next is the first card
      if(direction=="up"){
        newIndex=cardIndex+1
      }
      if(direction=="down"){
        newIndex=stack_length-1
      }
    }

    else{
      if(direction=="up"){
        newIndex=cardIndex-1
      }else if(direction=="down"){
        newIndex=cardIndex+1
      }
    }
    return  newIndex
   }
  function handleMoveCardStack(direction,stack_length){

    let newPrevious = getNextCardPosition(previousCard,direction,stack_length)
    let newCurrent = getNextCardPosition(currentCard,direction,stack_length)
    let newNext = getNextCardPosition(nextCard,direction,stack_length)


      setPreviousCard(newPrevious)
      setCurrentCard(newCurrent)
      setNextCard(newNext)
 
  }

  const [jobSearch, setJobSearch] = useState({

    id: null,
    name: '',
    title: '',
    level: 0,
    keywords: null,
    jobLocation: {
      city: '',
      state: '',
      searchDistance: null,
    },
    jobType: 'full-time',
    startDate: dayjs(),
    endDate: dayjs().add(2, 'month'),
    notificationPeriod: 24,
  });




  useEffect(() => {
    document.documentElement.style.setProperty('--num', carouselItems.length);
  }, [carouselItems])

  const handleAnimationEnd = () => {
    if (moveClass === styles.prev) {
      shiftNext([...carouselItems]);
    } else if (moveClass === styles.rightswipe) {
      //console.log("[...carouselItems] ", [...carouselItems])
      //console.log("carouselItems", carouselItems)
      swipe([...carouselItems], 'remove');
    } else if (moveClass === styles.leftswipe) {
      swipe([...carouselItems], 'list');
    } else if (moveClass === 'next') {
      shiftPrev([...carouselItems]);
    }
    setMoveClass('')
  }

  const swipe = (copy, action) => {

    let index = 0
    let firstcard = copy.shift();
    //console.log("copy ", copy)
    //console.log("carouselItems ", carouselItems)
    //console.log("firstcard id ", firstcard)
    //console.log("firstcard id ", firstcard.id)
    if(firstcard.jobTitle.length<1){
    firstcard.jobTitle=firstcard.jobTitle
  }
    actionHandler(action, firstcard.search_id, firstcard.index,firstcard);
    //console.log(copy);
    handleUpdateCarousel(copy);

  }

  const shiftPrev = (copy) => {
    let lastcard = copy.pop();
    copy.splice(0, 0, lastcard);
    handleUpdateCarousel(copy);
  }

  const shiftNext = (copy) => {
    let firstcard = copy.shift();
    copy.splice(copy.length, 0, firstcard);
    handleUpdateCarousel(copy);
  }
  function smoothScroll(target) {
    const { top } = target.getBoundingClientRect()
    window.scrollTo({
      top: top + window.pageYOffset,
      behavior: "smooth"
    });
  }
  return (

    <Stack spacing={2}>

      {messageSB.length ? renderMsg(messageSB[0].color, messageSB[0].icon, messageSB[0].title, messageSB[0].message) : null}
      {/*<MDLoading text="Retrieving job search data..." open={loading} />*/}


      <Typography sx={{ ml: 3, mt: 1, mb: 2 }} variant="h4" component="div">
                  Search Results
                </Typography>

 

 
      <div className={styles.carouselwrapper}  >
                     
      <Stack direction="row" spacing={2}>  
                     
        <button onClick={() => setMoveClass(styles.rightswipe)} className={`${styles.leftswipe} ${styles.buttonno}`} >
          <span >
            <i className={`${'material-symbols-outlined'}  ${styles.md48} ${styles.leftswipe}`}>highlight_off</i>

          </span>
        </button>
        {/*
          <div className="ui">
            <button onClick={() => setMoveClass(styles.next)} className={styles.prev}>
              <span className="material-icons">expand_less</span>
            </button>
            <button onClick={() => setMoveClass(styles.prev)} className={styles.next}>
              <span className="material-icons">expand_more</span>
            </button>
          </div>
          */}
       
         <Stack spacing={2}>         
     
         <IconButton onClick={() =>  handleMoveCardStack("up",carouselItems?.length)} className={`${styles.upswipe} ${styles.buttonup}`}>
       
          <Link rel="manifest" href={`#card_${nextCard}`}  > <span className={`${'material-symbols-outlined'}  ${styles.md48} ${styles.leftswipe}`}>keyboard_arrow_up</span></Link>
         
        </IconButton>
       
        <ul onAnimationEnd={handleAnimationEnd} className={`${moveClass} ${styles.carousel}`}>
        <Stack spacing={2}>
          {/*{carouselItems.map((t, index) => */}
          {/*console.log("carouselItems in swipe:", carouselItems)*/}
          {carouselItems?.map((t, index) => {

            return <Card key={t.id + "_" + index}  pb={5} sx={{ minHeight: "120px!important" }}  id={"card_"+index} index={index} >

              {/*console.log("t in swipe:", t, "reloadTrigger: ",reloadTrigger)*/}
              <JobCard index={index} icon={t.icon} searchId={t.searchId} id={t.id} copy={t.copy} companyName={t.companyName} companyLocation={t.companyLocation} jobLink={t.jobLink} jobType={t.jobType}  jobTitle={t.jobTitle}  jobSalary={t.jobSalary} jobSnippet={t.jobSnippet} source={t.source} text={t.description} />
            </Card>

          })}
              </Stack>

        </ul>
        </Stack>
        <button onClick={() => setMoveClass(styles.leftswipe)} className={`${styles.rightswipe} ${styles.buttonyes}`}>
          <span className={`${'material-symbols-outlined'}  ${styles.md48} ${styles.leftswipe}`}>
            check_circle
          </span>
        </button>
        </Stack>
      </div>


    </Stack >



  )
}

export default Swipe
