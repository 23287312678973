/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

import React, {Fragment, useMemo, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// react-table components
import {useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy} from 'react-table';

// @mui material components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Icon from '@mui/material/Icon';
import Autocomplete from '@mui/material/Autocomplete';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Import the ExpandMoreIcon
import ExpandLessIcon from '@mui/icons-material/ExpandLess'; // Import the ExpandLessIcon
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDPagination from 'components/MDPagination';

// Material Dashboard 2 React example components
import DataTableHeadCell from 'tongoComponents/Tables/DataTable/DataTableHeadCell';
import DataTableBodyCell from 'tongoComponents/Tables/DataTable/DataTableBodyCell';

// Material Dashboard 2 React context
import {useMaterialUIController} from 'context';

function DataTableCollapsible({entriesPerPage, canSearch, showTotalEntries, table, pagination, isSorted, noEndBorder, actionHandler}) {
  const [controller] = useMaterialUIController();
  const {darkMode} = controller;

  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries ? entriesPerPage.entries.map((el) => el.toString()) : ['5', '10', '15', '20', '25'];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const [innerPage, setInnerPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [innerRows, setInnerRows] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setInnerPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setInnerPage(0);
  };

  const handleTask = (task, iRow, searchId, index) => {
    actionHandler(task, searchId, index).then(() => {
      //delete locallyindex
      const jobToDelete = iRow.find((result) => result.index === index);
      if (!jobToDelete) return;
      // Remove the job object from the array
      const indexOfJobToDelete = iRow.indexOf(jobToDelete);
      setInnerRows(iRow.splice(indexOfJobToDelete, 1));
    });
  };

  useEffect(() => {}, [innerRows]);

  const tableInstance = useTable({columns, data, initialState: {pageIndex: 0}}, useGlobalFilter, useSortBy, usePagination);

  // State to manage the collapsed status of rows
  const [collapsedRows, setCollapsedRows] = useState([]);

  // Function to toggle the collapsed status of a row
  const toggleRowCollapse = (rowIndex) => {
    setCollapsedRows((prevCollapsedRows) => {
      const newCollapsedRows = [...prevCollapsedRows];
      newCollapsedRows[rowIndex] = !newCollapsedRows[rowIndex];
      return newCollapsedRows;
    });
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: {pageIndex, pageSize, globalFilter},
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination item key={option} onClick={() => gotoPage(Number(option))} active={pageIndex === option}>
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({target: {value}}) => (value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value)));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({target: value}) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? 'desc' : 'asce';
    } else if (isSorted) {
      sortedValue = 'none';
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  return (
    <TableContainer sx={{boxShadow: 'none'}}>
      {entriesPerPage || canSearch ? (
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{width: '5rem'}}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          )}
          {canSearch && (
            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder="Search..."
                value={search}
                size="small"
                fullWidth
                onChange={({currentTarget}) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup, key) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
              {/* MDBOx for bounding the the collapse icon */}
              <MDBox component="th" width={'0%'} py={1.5} px={3}></MDBox>
              {headerGroup.headers.map((column, idx) => (
                <DataTableHeadCell
                  key={idx}
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : 'auto'}
                  align={column.align ? column.align : 'left'}
                  sorted={setSortedValue(column)}
                >
                  {column.render('Header')}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            const isRowCollapsed = collapsedRows[key];
            return (
              <React.Fragment key={key}>
                {/* Regular Row */}
                <TableRow style={{cursor: 'pointer'}} key={key} {...row.getRowProps()}>
                  <MDBox
                    component="td"
                    textAlign="left"
                    py={1.5}
                    pr={0}
                    px={3}
                    // pl={0}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      toggleRowCollapse(key);
                    }}
                    sx={{
                      borderBottom: rows.length - 1 !== key ? '0.0625rem solid  #f0f2f5' : 'none', // Use 'none' for the last row
                      borderTop: 0 == key ? `0.0625rem solid ${darkMode ? '#f0f2f566' : '#f0f2f5'}` : 'none', // Use this border for the 1st row
                      width: '1px',
                    }}
                  >
                    <MDBox color="text" sx={{verticalAlign: 'left'}}>
                      <IconButton color={darkMode ? 'white' : 'dark'} size="small">
                        {isRowCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                      </IconButton>
                    </MDBox>
                  </MDBox>
                  {row.cells.map((cell, idx) => (
                    <DataTableBodyCell
                      key={idx}
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : 'left'}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
                {/* Collapsible Content */}
                <TableRow>
                  <TableCell colSpan={innerColumns.length + 1}>
                    <Collapse in={isRowCollapsed} timeout="auto" unmountOnExit>
                      {/* Content to be collapsed */}

                      <MDBox mx={1} mt={1} py={30} px={2} pb={0} pt={0}>
                        {/* <MDTypography variant="body2" color="info">
                          Search Results
                        </MDTypography> */}
                        <TableContainer sx={{boxShadow: 'none'}}>
                          {row.original.innerRows[0]?.length > 0 ? (
                            <Table stickyHeader aria-label="result table">
                              <MDBox component="thead">
                                <TableRow>
                                  {innerColumns.map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{minWidth: column.minWidth}}>
                                      <MDTypography variant="caption" color="info" fontWeight="regular">
                                        {column.label}
                                      </MDTypography>
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </MDBox>

                              <TableBody>
                                {row.original.innerRows[0]?.slice(innerPage * rowsPerPage, innerPage * rowsPerPage + rowsPerPage).map((innerRow) => {
                                  return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={innerRow.index}>
                                      {innerColumns.map((column) => {
                                        const value = innerRow[column.id];
                                        return (
                                          <MDBox
                                            component="td"
                                            key={column.id}
                                            align={column.align}
                                            pr={0.1}
                                            sx={innerRows?.length - 1 !== innerRow.index ? 'border-bottom: 0.0625rem solid  #f0f2f5' : null}
                                          >
                                            {column.id === 'jobLink' && column.id !== 'index' ? (
                                              // Display a link to the job posting
                                              <Tooltip title="View original job posting" placement="top-start">
                                                <MDTypography display="block" variant="caption" component={Link} to={value} target="_blank">
                                                  <Icon>link</Icon>
                                                </MDTypography>
                                              </Tooltip>
                                            ) : column.id === 'index' ? (
                                              // Display icons for shortlisting and removing jobs
                                              <MDBox mx={1} mt={1} py={30} px={2} pb={0} pt={0}>
                                                <MDBox pb={1}>
                                                  <Tooltip title="Short list this job" placement="top-start">
                                                    <MDTypography display="block" variant="caption">
                                                      <Icon
                                                        fontSize="medium"
                                                        onClick={() => handleTask('list', row.original.innerRows[0], row?.original?.id, value)}
                                                      >
                                                        approval_delegation
                                                      </Icon>
                                                    </MDTypography>
                                                  </Tooltip>
                                                </MDBox>
                                                <MDBox pt={1}>
                                                  <Tooltip title="Remove this job from search results" placement="top-start">
                                                    <MDTypography display="block" variant="caption">
                                                      <Icon
                                                        fontSize="medium"
                                                        onClick={() => handleTask('remove', row.original.innerRows[0], row?.original?.id, value)}
                                                      >
                                                        delete
                                                      </Icon>
                                                    </MDTypography>
                                                  </Tooltip>
                                                </MDBox>
                                              </MDBox>
                                            ) : (
                                              // Display default content (formatted value)
                                              <MDTypography display="block" variant="caption">
                                                {column.format ? column.format(value) : value}
                                              </MDTypography>
                                            )}
                                          </MDBox>
                                        );
                                      })}
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          ) : (
                            <MDBox mx={0} mt={0} py={0} px={2} pb={0} pt={0}>
                              <MDTypography variant="caption" color="warning">
                                No job was found, try refreshing or editing your keywords and try again!
                              </MDTypography>
                            </MDBox>
                          )}
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={row.original.innerRows[0]?.length ?? 0}
                          rowsPerPage={rowsPerPage}
                          page={innerPage}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          sx={{
                            fontSize: '0.75rem', // Change the font size
                            color: '#344767', // Change the color
                            '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
                              fontSize: '.75rem',
                              color: '#344767',
                            },
                          }}
                        />
                      </MDBox>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{xs: 'column', sm: 'row'}}
        justifyContent="space-between"
        alignItems={{xs: 'flex-start', sm: 'center'}}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{xs: 3, sm: 0}}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Showing {entriesStart} to {entriesEnd} of {rows.length} entries
            </MDTypography>
          </MDBox>
        )}
        {pageOptions.length > 1 && (
          <MDPagination variant={pagination.variant ? pagination.variant : 'gradient'} color={pagination.color ? pagination.color : 'info'}>
            {canPreviousPage && (
              <MDPagination item onClick={() => previousPage()}>
                <Icon sx={{fontWeight: 'bold'}}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{type: 'number', min: 1, max: customizedPageOptions.length}}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </MDBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <MDPagination item onClick={() => nextPage()}>
                <Icon sx={{fontWeight: 'bold'}}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTableCollapsible.defaultProps = {
  entriesPerPage: {defaultValue: 10, entries: [5, 10, 15, 20, 25]},
  canSearch: false,
  showTotalEntries: true,
  pagination: {variant: 'gradient', color: 'info'},
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTableCollapsible.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(['contained', 'gradient']),
    color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark', 'light']),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  actionHandler: PropTypes.func.isRequired,
};

export default DataTableCollapsible;

const innerColumns = [
  {
    id: 'jobLink',
    label: '',
    align: 'left',
  },
  {id: 'jobTitle', label: 'Job Title', minWidth: 200, align: 'left', format: (value) => (value.length > 50 ? `${value.substring(0, 50)}...` : value)},
  {
    id: 'companyName',
    label: 'Employer',
    minWidth: 170,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'jobSnippet',
    label: 'Brief Description',
    minWidth: 300,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'jobSalary',
    label: 'Salary',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'index',
    label: '',
    align: 'center',
  },
];
