/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/
import localforage from 'localforage';

import {useState, useEffect} from 'react';

// react-router components
import {useLocation, useNavigate, Link} from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// import {getProfile} from '../../../profile';
import {getProfile, resendVerificationToken} from '../../../profile';

// @material-ui core components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import Icon from '@mui/material/Icon';
// import Link from '@mui/material/Link';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDAlert from 'components/MDAlert';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 React example components
import Breadcrumbs from 'tongoComponents/Breadcrumbs';
import NotificationItem from 'tongoComponents/Items/NotificationItem';

// Custom styles for DashboardNavbar
import {navbar, navbarContainer, navbarRow, navbarIconButton, navbarMobileMenu} from 'tongoComponents/Navbars/DashboardNavbar/styles';

// Material Dashboard 2 React context
import {useMaterialUIController, setTransparentNavbar, setMiniSidenav, setOpenConfigurator} from 'context';

function DashboardNavbar({absolute, light, isMini}) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode} = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split('/').slice(1);
  const navigate = useNavigate();

  const [hideResend, setHideResend] = useState(false);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = (e, r) => {
    //if (r && r == 'backdropClick')
    setOpenMenu(false);
  };

  // const [retrieve, setRetrieve] = useState(false);
  const [notifications, setNotifications] = useState([]);
  // const [profile, setProfile] = useState([]);

  const [user, setUser] = useState();

  useEffect(() => {
    //check to see if the user has uploaded/verified
    getProfile({fields: {emailVerified: true, resumeStatus: true, notifications: true}})
      .then((u) => {
        setNotifications(u.notifications);
        delete u.notifications;
        setUser(u);
        localforage.setItem('emailVerifed', u.emailVerifed).then(() => localforage.setItem('resumeStatus', u.resumeStatus).then());
      })
      .catch((e) => console.log);
  }, []);

  // useEffect(() => {
  //   // Check if we need to retrieve the search data
  //   if (!retrieve) {
  // getUserData({ fields: { notifications: true, avatar: true, firstName: true } });

  //   }
  // }, [retrieve]);

  // Render the notifications menu
  function renderNotification(notification) {
    const {title, icon, type, action} = notification;

    let onClick;

    if (type === 'url' && action) {
      // If type is 'url' and action is provided, it's a URL link
      onClick = () => navigate(action);
    } else if (type === 'modal') {
      // If type is 'modal' and action is null, it's a message
      onClick = () => showModal(message);
    }

    return <NotificationItem disableRipple icon={<Icon>{icon}</Icon>} onClick={onClick} title={title} />;
  }

  //when error alerts are displayed, recheck on close since the info comes from profile sign on

  const checkAlert = (check) => {
    switch (check) {
      case 'email':
        break;
      case 'resume':
        break;
      default:
        return;
    }
  };

  const handleResend = () => {
    void resendVerificationToken();
    setHideResend(true);
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      onBlur={handleCloseMenu}
      sx={{mt: 2}}
    >
      {notifications?.map((notification, index) => (
        <div key={index}>{renderNotification(notification)}</div>
      ))}
    </Menu>
  );

  //render Alerts
  const renderAlerts = () => (
    <>
      {user?.emailVerified ? null : (
        <MDAlert color="error" dismissible onClose={() => checkAlert('email')}>
          <MDBox sx={{flexDirection: 'column'}}>
            <MDTypography color="white" fontWeight="medium">
              Please check your email to verify your account!
            </MDTypography>
            <MDTypography hidden={hideResend} component={Link} to="#" onClick={handleResend} color="info" variant="h6" fontWeight="light">
              Resend email
            </MDTypography>
          </MDBox>
        </MDAlert>
      )}
      {user?.resumeStatus !== 'notStarted' ? null : (
        <MDAlert color="error" dismissible onClose={() => checkAlert('resume')}>
          <MDBox sx={{flexDirection: 'column'}}>
            <MDTypography color="white" fontWeight="medium">
              Please upload or create your résumé!
            </MDTypography>
            {route[0] === 'resume' ? (
              <MDTypography color="white" variant="h6" fontWeight="light">
                Click on the upload résumé below
              </MDTypography>
            ) : (
              <MDTypography component={Link} to="/resume" color="info" variant="h6" fontWeight="light">
                Go to upload tools
              </MDTypography>
            )}
          </MDBox>
        </MDAlert>
      )}
    </>
  );

  // Styles for the navbar icons
  const iconsStyle = ({palette: {dark, white, text}, functions: {rgba}}) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;
      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }
      return colorValue;
    },
  });

  return (
    <>
      <AppBar position={absolute ? 'absolute' : navbarType} color="inherit" sx={(theme) => navbar(theme, {transparentNavbar, absolute, light, darkMode})}>
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <MDBox color="inherit" mb={{xs: 1, md: 0}} sx={(theme) => navbarRow(theme, {isMini})}>
            <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          </MDBox>
          {isMini ? null : (
            <MDBox sx={(theme) => navbarRow(theme, {isMini})}>
              {/* <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox> */}
              <MDBox color={light ? 'white' : 'inherit'}>
                <Link to="/account">
                  <IconButton sx={navbarIconButton} size="small" disableRipple>
                    <Icon sx={iconsStyle}>account_circle</Icon>
                    {/* <MDAvatar
  src={`https://www.gravatar.com/avatar/${profile?.avatar ? profile.avatar : '3b3be63a4c2a439b013787725dfce802'}?d=identicon`}
  alt={profile?.firstName}
  size="xl"
  shadow="none"
  sx={navbarIconButton}
/>*/}
                  </IconButton>
                </Link>
                <IconButton size="small" disableRipple color="inherit" sx={navbarMobileMenu} onClick={handleMiniSidenav}>
                  <Icon sx={iconsStyle} fontSize="medium">
                    {miniSidenav ? 'menu_open' : 'menu'}
                  </Icon>
                </IconButton>
                <IconButton size="small" disableRipple color="inherit" sx={navbarIconButton} onClick={handleConfiguratorOpen}>
                  <Icon sx={iconsStyle}>settings</Icon>
                </IconButton>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                  aria-label={1}
                >
                  <Badge badgeContent={notifications?.length} color="secondary">
                    <Icon sx={iconsStyle}>notifications</Icon>
                  </Badge>
                  {renderMenu()}
                </IconButton>
              </MDBox>
            </MDBox>
          )}
        </Toolbar>
      </AppBar>
      {user ? renderAlerts() : null}
    </>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
