/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

import { useState } from 'react';
import { archiveJob, deleteJob, generateCoverLetter, updateJob } from '../../../../../jobs';
import { getProfile,updateProfile } from '../../../../../profile';


import Grid from '@mui/material/Grid';
// react-router-dom components
import { Link, useNavigate } from 'react-router-dom';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { useMaterialUIController } from 'context';
import MDLoading from 'components/MDLoading';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DownloadIcon from '@mui/icons-material/Download';
import NotesIcon from '@mui/icons-material/Notes';
import SaveIcon from '@mui/icons-material/Save';
import ListItemText from '@mui/material/ListItemText';
// Material Dashboard 2 React components+
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { saveAs } from "file-saver";

import { Packer } from "docx";
import MuiLink from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import { CLWordExport } from "../CLWordExport";
import { WordExport } from 'layouts/resume/components/WordExport';
// @mui material components
import Icon from '@mui/material/Icon';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

function JobInfo({ jobId, jobTitle, description, jobPostingUrl, useMainResume, customResume, companyName, city, state, coverLetterStatus, noGutter }) {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('Loading');
  const [db, setDb] = useState({});
  const closeMessage = () => setMessageSB([]);
  const [showMore, setShowMore] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [errorSB, setErrorSB] = useState([]);
  const closeError = () => setErrorSB([]);
  const handleClose = () => setOpenDialog(false);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openMenu = Boolean(anchorEl);
  const archive = (jobId) => {
    setLoading(true);
    archiveJob(jobId)
      .then((r) => {
        navigate(`/short-list/`);
      })
      .catch((error) => {
        setErrorSB([{ title: 'Error archiving job', message: 'Please try archiving again.' }]);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const remove = (jobId) => {
    console.log("removing job: ", jobId)
    setLoading(true);
    deleteJob(jobId)
      .then((r) => {
        console.log("removing job: ", jobId);
        setLoading(false);

      })
      .catch((error) => {
        setErrorSB([{ title: 'Error deleting job', message: 'Please try deleting again.' }]);
      })
      .finally(() => {
        handleClose();
        window.location.reload();

      });
  };

  const action = (task, jobId) => {
    switch (task) {
      case 'edit':
        setDb({
          message:
            'Please be aware that changing the job details, especially the job description, could make the cover letter no longer suitable. This might require creating new ones, which could lead to extra expenses.',
          label: 'Continue with editing?',
          title: 'Confirm editing',
          color: 'info',
          task: 'edit',
        });
        setOpenDialog(true);
        break;
      case 'archive':
        setDb({
          message: "Do you want to archive this? Archiving keeps the job but won't appear in the list unless the archive filter is set",
          label: 'Archive',
          title: 'Confirm archiving',
          color: 'info',
          task: 'archive',
        });
        setOpenDialog(true);
        break;
      case 'delete':
        setDb({
          message: 'Are you sure you want to delete? ',
          label: 'Delete',
          title: 'Confirm deletion',
          color: 'error',
          task: 'delete',
        });
        setOpenDialog(true);
        break;
      case 'apply':
        if (coverLetterStatus === 'edited' || coverLetterStatus === 'generated')
          setDb({
            message:
              'Certain job postings do not permit automatic application. Tongo can help you apply and manage job applications through email. This feature also allows you to manually track your application status.',
            label: 'Apply',
            title: 'Confirm application',
            color: 'info',
            task: 'apply',
          });
        else
          setDb({
            message: 'This short listed job is missing a cover letter. Please create one then apply for the job.',
            label: null,
            title: 'Missing cover letter',
            color: 'error',
            task: null,
          });
        setOpenDialog(true);
        break;
    }
  };

  const actionFunction = (task, jobId) => {
    switch (task) {
      case 'edit':
        navigate(`/short-list/${jobId}/edit`);
        break;
      case 'apply':
        navigate(`/short-list/${jobId}/apply`);
        break;
      case 'archive':
        handleClose;
        archive(jobId);
        break;
      case 'delete':
        handleClose;
        console.log("delete")
        remove(jobId);
        break;
    }
  };
  const handleOnSave = () => {
    const newCoverLetter = editorRef.current.getContent();
    setEditing(false);
    if (newCoverLetter !== cl) {
      setLoading(true);
      setLoadingMsg('Saving...');

      if (editorRef.current) {
        updateJob({ id: jobId, coverLetter: newCoverLetter, coverLetterStatus: 'edited' })
          .then((result) => {
            setCl(newCoverLetter);
          })
          .catch((error) => {
            setErrorSB([{ title: 'Error saving cover letter', message: error }]);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };


  function createUnformattedTextResume() {
    let experience_and_skills = ""


    function update(updatedResumeUnformatted) {

      updateProfile({ mainResume: updatedResumeUnformatted, mainResumeUnformatted: updatedResumeUnformatted })
        .then((result) => {
          setResumeDate(new Date.now());
        })
        .catch((error) => {
          setErrorSB([{ title: 'Error saving résumé', message: error }]);
        })
        .finally(() => {
          setLoading(false);
        });
        console.log('*****************finished update***************');
    }


    getProfile({
      fields: {
        resumeParsed: true,

      },
    })
      .then((resume) => {
       
        if (resume?.resumeParsed){
          console.log("resume parsed: ",resume?.resumeParsed)
          let experience = resume?.resumeParsed[2].experience//custom fields for now
          let skills = resume?.resumeParsed[3].custom//custom fields for now
          
          experience.forEach(company => {
                experience_and_skills = experience_and_skills + " \n" + company.company + " \n"
                company.position.forEach((position) => {
                      experience_and_skills = experience_and_skills + " \n" + position.title + " \n" + position.jobDescription + " \n" 


                 })


        })
        skills.forEach((item) => {
          experience_and_skills = experience_and_skills + " \n" + item.title + " \n"
          item.content.forEach((cont) => {
            experience_and_skills = experience_and_skills + ", " + cont
          })

        })
          }



          console.log('*****************updatedResume***************', experience_and_skills);
          update(experience_and_skills);



          })


    
  }



  const handleGenerateCL = () => {
    setLoadingMsg('Creating cover letter...');
    setLoading(true);
    createUnformattedTextResume()
    generateCoverLetter(jobId)
      .then((cl) => {
        setCl(cl.editorContent);
        editorRef.current.execCommand('mceSetContent', false, cl.coverLetter);
        coverLetterStatus = 'generated';
      })
      .catch((error) => {
        switch (error.code) {
          case 402:
            coverLetterStatus = 'Needs payment';
            setErrorSB([{ title: 'Error creating cover letter', message: 'Please add a payment method or add credit to your account' }]);
            break;
          case 422:
            coverLetterStatus = 'Missing résumé in profile';
            setErrorSB([{ title: 'Error creating cover letter', message: 'Please upload a résumé before creating a cover letter' }]);
            break;
          case 500:
            coverLetterStatus = 'Job description too long. Edit unneeded job description or info.';
            setErrorSB([{ title: 'Error creating cover letter', message: 'Please reduce the size of the job description' }]);
            break;
          default:
            coverLetterStatus = 'Error creating cover letter';
            setErrorSB([{ title: 'Error creating cover letter', message: error.message }]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };


  function generate() {

    //let skills = resume?.resumeParsed[3].customFields//custom fields for now
    const exp = new CLWordExport();
    console.log(coverLetter)
    console.log(cl)
    console.log("cover letter is: ", cl.editorContent)
    const cLArr = []
    const cLArrSplit = String(cl.editorContent).split("\n")



    console.log("cLArrSplit is: ", cLArrSplit)


    for (let i = 0; i < cLArrSplit.length; i++) {
      cLArr.push(cLArrSplit[i].replace("<p>", "").replace("</p>", "").replace("&nbsp;", ""))
      console.log("cLArr is: ", cLArr)
    }

    const doc = exp.create(cLArr);



    Packer.toBlob(doc).then(blob => {
      console.log(blob);
      saveAs(blob, "CoverLetter.docx");
      console.log("Cover Letter created successfully");
    });
    const expRes = new WordExport();

    console.log("####################### resume: ", resume)
    const docRes = expRes.create(resume);

    Packer.toBlob(docRes).then(blob => {
      console.log(blob);
      saveAs(blob, "Resume.docx");
      console.log("Resume created successfully");
    });
  }
  const style = {

    primaryTypographyProps: {
      fontSize: 14
    }
  };

  return (
    <MDBox id="job-info">
      <MDLoading text="Processing..." open={loading} onClose={() => { }} />

      {openDialog ? (
        <Dialog open={openDialog} onClose={handleClose} aria-labelledby="Alert confirmation" aria-describedby="Alert confirmation">
          <DialogTitle>{db.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{db.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton variant="gradient" color={db.color} onClick={() => actionFunction(db.task, jobId)}>
              {db.label ? db.label : ''}
            </MDButton>
            <MDButton variant="gradient" color="success" onClick={handleClose} autoFocus>
              Cancel
            </MDButton>
          </DialogActions>
        </Dialog>
      ) : null}
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>

          <MDBox width="100%" display="flex" flexDirection="column">
            <Grid container spacing={0}>
              <Grid item xs={9} alignItems="left">

                <Typography sx={{ ml: 3, mt: 1, mb: 1 }} variant="h2" component="div">
                  {jobTitle}
                </Typography>
              </Grid>

              <Grid item xs={2} alignItems="right" mx={0} >
                <Grid container md={12} spacing={0}>
                  <Grid item xs={1} alignItems="right" md={1} mx={2} >




                  </Grid>

                  <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>

                    {/*editing ? (
  <IconButton variant="text" color="success" onClick={handleOnSave}>
    <Icon>save</Icon>&nbsp; {loading ? 'saving' : ''}
  </IconButton>
) : (
  <IconButton variant="text" color={darkMode ? 'white' : 'dark'} onClick={handleOnEdit}>
    <Icon>edit</Icon>
  </IconButton>
)*/}
                    <IconButton variant="text" onClick={handleClick}>
                      <MenuIcon fontSize="large" />&nbsp;
                    </IconButton>
                  </MDBox>



                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={handleOnSave}>

                      <ListItemIcon>
                        <SaveIcon fontSize="medium" />
                      </ListItemIcon>
                      {loading ? <ListItemText>Saving...</ListItemText> : <ListItemText>Save</ListItemText>}
                    </MenuItem>


                    <Tooltip title="This feature requires payment" placement="left-start">
                      <MenuItem onClick={handleGenerateCL}><AutoFixHighIcon fontSize="medium" />&nbsp;Create cover letter</MenuItem>
                    </Tooltip>
                    <Tooltip title="Download cover letter in Word docx format" placement="left-start">



                      <MenuItem onClick={generate}> <DownloadIcon fontSize="medium" />&nbsp;  Export as Word Doc</MenuItem>

                    </Tooltip>
                    <Tooltip  position="left-start" title="Edit">

                      <MenuItem onClick={() => action('edit', jobId)}><Icon>picture_as_pdf</Icon> &nbsp;  {useMainResume ? 'Default resume' : 'Custom résumé'}
                      </MenuItem>

                    </Tooltip>




                    <Tooltip title="Edit" placement="left-start">

                      <MenuItem onClick={() => action('edit', jobId)}> <EditIcon fontSize="medium" />&nbsp;  Edit Job</MenuItem>

                    </Tooltip>
                    <Tooltip  position="left-start" title="Archive">

                      <MenuItem onClick={() => action('archive', jobId)}><ArchiveIcon fontSize="medium" />&nbsp;  Archive Job</MenuItem>


                    </Tooltip>

                    <Tooltip  position="left-start" title="Delete">

                      <MenuItem onClick={() => action('delete', jobId)}> <DeleteIcon fontSize="medium" />&nbsp;  Delete Job</MenuItem>

                    </Tooltip>

                    {/*<MuiLink href={jobPostingUrl} rel="noreferrer">
                      <MenuItem onClick={() => action('delete', jobId)}> <EditNoteIcon fontSize="medium" />&nbsp;     View original job post</MenuItem>

                  </MuiLink>*/}




                  </Menu>

                </Grid>
              </Grid>
            </Grid>

            {companyName ? (
              <MDBox mb={1} lineHeight={0}>


                <MDTypography sx={{ ml: 3, mt: 0, mb: -1 }} variant="h3" component="div" fontWeight="light">
                  {companyName}
                </MDTypography>

              </MDBox>
            ) : null}
            <MDBox mb={1} lineHeight={0}>

              <MDTypography sx={{ ml: 3, mt: 0, mb: 1 }} variant="h4" component="div" fontWeight="light" color="text">
                {city ? city : ''}
                {state ? ', ' + state : ''}

              </MDTypography>  </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: 'flex-start', sm: 'center' }}
              flexDirection={{ xs: 'column', sm: 'row' }}
              mb={2}
            >

            </MDBox>



            <MDBox mb={1} lineHeight={0}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography> Job Description</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {description}

                  </Typography>
                </AccordionDetails>
              </Accordion>

            </MDBox>
          </MDBox>
        </MDBox>

      </MDBox>
    </MDBox>
  );
}
// Setting default values for the props of JobInfo
JobInfo.defaultProps = {
  noGutter: false,
  companyName: '',
  city: '',
  state: '',
  coverLetterStatus: 'pending',
};

// Typechecking props of the JobInfo
JobInfo.propTypes = {
  jobId: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  jobPostingUrl: PropTypes.string.isRequired,
  useMainResume: PropTypes.bool.isRequired,
  customResume: PropTypes.string,
  companyName: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  coverLetterStatus: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default JobInfo;
