/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// react-router-dom components
import {Link} from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDAvatar from 'components/MDAvatar';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Material Dashboard 2 React base styles
import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';

function Biography({basicInfo}) {
  const {socialMediaColors} = colors;
  const {size} = typography;

  // Render the card social media icons
  const renderSocial = (social) => {
    social.map(({link, icon, color}) => (
      <MDBox
        key={color}
        component="a"
        href={addHttpsToURL(link)}
        target="_blank"
        rel="noreferrer"
        fontSize={size.lg}
        color={socialMediaColors[color].main}
        pr={1}
        pl={0.5}
        lineHeight={1}
      >
        {icon}
      </MDBox>
    ));
  };

  // const [socialMedia, setSocialMedia] = useState({
  //   facebook: basicInfo && social[0]?.link,
  //   twitter: social && social[1]?.link,
  //   instagram: social && social[2]?.link,
  //   linkedin: social && social[3]?.link,
  // });

  return (
    <Card id="basicInfo">
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Contact Information
        </MDTypography>
        <Tooltip title="Edit profile information" placement="top">
          <MDButton component={Link} to="/account" variant="text" color="info">
            <Icon sx={{fontWeight: 'bold'}}>edit</Icon>
            {/* &nbsp;edit in My Account */}
          </MDButton>
        </Tooltip>
      </MDBox>
      <Grid pt={2} px={2} container spacing={3} alignItems="center">
        <Grid item>
          <MDAvatar
            src={`https://www.gravatar.com/avatar/${basicInfo?.avatar ? basicInfo.avatar : '3b3be63a4c2a439b013787725dfce802'}?d=identicon`}
            alt="basicInfo-image"
            size="xl"
            shadow="sm"
          />
        </Grid>
        <Grid item>
          <MDBox height="100%" mb={0} mt={1} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {basicInfo?.salutation && !['MD', 'M.D.', 'PhD'].includes(basicInfo.salutation) ? `${basicInfo.salutation} ` : ''}
              {basicInfo?.lastName && basicInfo?.firstName ? `${basicInfo.firstName} ${basicInfo.lastName}` : ''}
              {basicInfo?.salutation && ['MD', 'M.D.', 'PhD'].includes(basicInfo.salutation) ? `, ${basicInfo.salutation}` : ''}
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="regular">
              {basicInfo?.city ? basicInfo.city : 'No city'}
              {basicInfo?.state ? ', ' + basicInfo.state : ' - No state'}
              <br />
              {basicInfo?.title ? basicInfo.title : ''}
              <br />
              {basicInfo?.telephone ? `Phone: ${basicInfo.telephone}` : ''}
              <br />
              {basicInfo?.email}
            </MDTypography>
            {/* <MDBox display="flex" py={1} pr={2}>
              <MDTypography variant="button" fontWeight="light" textTransform="capitalize">
                social: &nbsp;
              </MDTypography>
              {/* {basicInfo?.social ? {renderSocial} : ''} 
            </MDBox> */}
          </MDBox>
        </Grid>
        <Grid item>
          <MDBox height="100%" mb={1.5} mt={0} lineHeight={1}>
            <MDBox py={1} pr={2}>
              <MDTypography variant="button" fontWeight="light">
                {basicInfo?.bio ? basicInfo.bio : ''}
              </MDTypography>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </Card>
  );
}

export default Biography;

Biography.propTypes = {
  basicInfo: PropTypes.shape({
    salutation: PropTypes.string,
    avatar: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    middleName: PropTypes.string,
    title: PropTypes.string,
    bio: PropTypes.string,
    telephone: PropTypes.string,
    email: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    social: PropTypes.object,
  }),
};
