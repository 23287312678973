import { createSlice } from '@reduxjs/toolkit';
// User information slice

const userSlice = createSlice({
    name: 'user',
    initialState: {},
    reducers: {
        setUserInfo: (state, action) => {
            return action.payload;
        },
    },
});

export const { setUserInfo} = userSlice.actions;

export default userSlice.reducer;