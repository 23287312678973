/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// react-router-dom components
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import MDBox from 'components/MDBox';
import MDLoading from 'components/MDLoading';
import Backdrop from '@mui/material/Backdrop';
// Material Dashboard 2 React example components

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import styles from './searching.module.css';
import { styled } from '@mui/material/styles';
import { func } from 'prop-types';
function GetDate() {
  now: new Date().toISOString();
}


function Searching() {

  const style = {

    width: '100%',
    minWidth: 500,
    borderRadius: 2,
    border: 'none',//'1px solid',
    borderColor: 'divider',
    backgroundColor: 'background.paper',
    listStyleType: 'none',
    mt: 0,
    pt: 0,
    pb: 0
  };

  const Item = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  return (

    <Box sx={{ width: '100%' }}>
      <section class={styles.wrapper}>



      <Stack spacing={2}>
      <Item sx={style} ><p class={styles.animate}></p></Item>

          <div class={styles.loader} sx={{ width: '100%' }} >
          <Item>
          <Stack direction="row" spacing={2}>

                <Item><div class={`${styles.loading} ${styles.one}`}></div> </Item>
                <Item><div class={`${styles.loading} ${styles.two}`}></div> </Item>
                <Item><div class={`${styles.loading} ${styles.three}`}></div> </Item>
                <Item><div class={`${styles.loading} ${styles.four}`}></div> </Item>
                </Stack>
                </Item>

        
          </div>
          </Stack>

      </section>
    </Box>






  );
}

export default Searching;
